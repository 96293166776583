export default function LiveIcon() {
  return (
    <>
      <path
        d="M19.6037 1H1.74415C1.33317 1 1 1.36985 1 1.82609V15.0435C1 15.4997 1.33317 15.8696 1.74415 15.8696H19.6037C20.0147 15.8696 20.3478 15.4997 20.3478 15.0435V1.82609C20.3478 1.36985 20.0147 1 19.6037 1Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.18556 15.8696L7.69727 20.0001"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.1621 15.8696L13.6504 20.0001"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.20898 20H15.1387"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.95312 9.26081L9.92971 11.7391L15.1387 5.13037"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  );
}
