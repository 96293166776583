export default function CaIcon() {
  return (
    <svg
      width="23"
      height="28"
      viewBox="0 0 23 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.0337 28H22.6401C22.839 28 23 27.8433 23 27.65V0.35C23 0.156714 22.839 0 22.6401 0H1.0337C0.834787 0 0.673828 0.156714 0.673828 0.35V27.65C0.673828 27.8433 0.834787 28 1.0337 28ZM1.39357 0.7H22.2803V27.3H1.39357V0.7Z"
        fill="#03045E"
      />
      <path
        d="M11.8353 6.85254C7.78317 6.85254 4.48633 10.0588 4.48633 13.9999C4.48633 17.9409 7.78317 21.1472 11.8353 21.1472C15.8873 21.1472 19.1842 17.9409 19.1842 13.9999C19.1842 10.0588 15.8873 6.85254 11.8353 6.85254ZM13.4816 11.2062H10.1889C10.6524 9.49971 11.4294 8.31906 11.8353 7.78488C12.2411 8.31906 13.0181 9.49971 13.4816 11.2062ZM13.6474 11.9062C13.7749 12.5452 13.8536 13.2445 13.8536 13.9999C13.8536 14.7184 13.7802 15.3839 13.6636 15.9975H10.0069C9.89026 15.3839 9.81694 14.7184 9.81694 13.9999C9.81694 13.2445 9.8956 12.5452 10.0231 11.9062H13.6474ZM11.0839 7.59697C10.5981 8.27604 9.86904 9.50443 9.44172 11.2062H5.86998C6.83901 9.25731 8.78167 7.85103 11.0839 7.59697ZM5.20607 13.9999C5.20607 13.2663 5.33883 12.5637 5.57194 11.9062H9.29056C9.17194 12.5493 9.0972 13.2458 9.0972 13.9999C9.0972 14.7169 9.16621 15.3804 9.27456 15.9975H5.53574C5.32407 15.3678 5.20607 14.6974 5.20607 13.9999ZM5.82234 16.6975H9.41949C9.84249 18.4469 10.5874 19.7098 11.0821 20.4025C8.74288 20.1437 6.77415 18.6958 5.82234 16.6975ZM10.1626 16.6975H13.5079C13.0483 18.4565 12.2489 19.6719 11.8353 20.2169C11.4216 19.6719 10.6222 18.4565 10.1626 16.6975ZM12.5884 20.4025C13.0831 19.7098 13.828 18.4469 14.251 16.6975H17.8482C16.8964 18.6958 14.9276 20.1437 12.5884 20.4025ZM18.4644 13.9999C18.4644 14.6974 18.3464 15.3678 18.1348 15.9975H14.3959C14.5043 15.3804 14.5733 14.7169 14.5733 13.9999C14.5733 13.2458 14.4986 12.5493 14.38 11.9062H18.0986C18.3317 12.5637 18.4644 13.2663 18.4644 13.9999ZM14.2288 11.2062C13.8015 9.50443 13.0725 8.27602 12.5866 7.59697C14.8888 7.85103 16.8315 9.25731 17.8005 11.2062H14.2288Z"
        fill="#03045E"
      />
    </svg>
  );
}
