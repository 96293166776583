const formatTime = (time) => {
  const date = new Date(time * 1000);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');
  if (hours) {
    //if video have hours
    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds} `;
  } else return `${minutes}:${seconds}`;
};

class Controls {
  ref;
  isLive:boolean;
  player;

  constructor(ref, isLive) {
    this.ref = ref;
    this.isLive = isLive;
    this.player = ref?.current?.getInternalPlayer('hls');
    return this;
  }

  // play() {
  //   this.player.playVideo();
  // }
  // pause() {
  //   this.player.pauseVideo();
  // }
  restart() {
    // if (
    //   this?.ref?.current?.getDuration() - this?.ref?.current?.getCurrentTime() <
    //     2 &&
    //   !this.isLive
    // ) {
    //   this?.ref?.current?.seekTo(0);
    //   return;
    // }
  }

  startFromZero() {
    this?.ref?.current?.seekTo(0);
  }

  seekForward(sec) {
    this.restart();

    this?.ref?.current?.seekTo(this.ref.current.getCurrentTime() + 5);
  }

  seekBackward(sec) {
    this.restart();
    this?.ref?.current?.seekTo(this.ref.current.getCurrentTime() - 5);
  }

  seekTo(time) {
    const Time = this?.ref?.current?.getDuration() * (time / 100);
    this.restart();
    const cTime = Math.round(Time);
    this?.ref?.current?.seekTo(cTime);
  }

  continue(time) {
    this?.ref?.current?.seekTo(time);
  }
  getRawTime() {
    return this?.ref?.current?.getCurrentTime();
  }

  volumeUp() {}
  volumeDown() {}

  seekPercentage() {
    // this.restart();
    return Math.round(
      (this?.ref?.current?.getCurrentTime() /
        this?.ref?.current?.getDuration()) *
        100
    );
  }

  // goLive() {
  //   this.restart();
  //   return Math.round(
  //     (this?.ref?.current?.getCurrentTime() /
  //       this?.ref?.current?.getCurrentTime()) *
  //       100
  //   );
  // }
  goLive() {
    this?.ref?.current?.seekTo(this?.ref?.current?.getDuration());
  }

  getDuration() {
    const duration = this.isLive
      ? this?.ref?.current?.getCurrentTime()
      : this?.ref?.current?.getDuration();
    return formatTime(duration);
  }

  getCurrentTime() {
    const currentTime = this?.ref?.current?.getCurrentTime();
    return formatTime(currentTime);
  }
}

export class HlsControls {
  player;
  Pause;
  hls;
  isLive;
  resolution;
  isIos;
  cT;

  constructor(ref, hls,isLive) {
    this.player = ref;
    hls==null?(this.isIos=true):(this.hls = hls);
    this.isLive = isLive;
    return this;
  }

  pause() {
    this.player.pause();
  }

  play() {
    this.player.play();
  }

  get isPaused() {
    return this.Pause;
  }

  goLive() {
    this.player.currentTime= this.player.duration;
  }

  get Progress() {
    const { currentTime, duration } = this?.player;

    return Math.round((currentTime / duration) * 100);
  }

  getDuration() {
    return formatTime(this.player?.duration);
  }

  getCurrentTime() {
    return formatTime(this.player?.currentTime);
  }

  set Volume(vol){

    this.player.volume = vol

  }

  setResolution(resolutions,url,option){
   
    this.resolution = resolutions.map((r,i)=>{
      return {url: url.replace(/\/[^\/]*$/, `/${r}`),q:`${option[i].split('x')[1]}p`}
      url.replace(/\/[^\/]*$/, `/${r}`)
    });


  }

  get Resolution(){
    return this.resolution;
  }

  qualityChange(index){
    if(this.resolution[index]?.url){

      this.player.src = this.resolution[index]?.url;
      // this.player.src = "https://live-par-2-abr.livepush.io/vod/bigbuckbunny/index.m3u8"
      this.player.currentTime = this.player.currentTime;
      this.player.play();
    }

  }

  seekTo(value) {
    const Time = Math.round(this?.player?.duration * (value / 100));
    this.player.currentTime = Time;
  }

  seekForward(by=10) {
    this.player.currentTime = this.player.currentTime + by;
  }

  seekBackward(by=10) {
    this.player.currentTime = this.player.currentTime - by ;
  }

  get HlsLevels() {
    return this.hls?.levels||[];
  }

  mappedQuality(value) {
    return `${this?.HlsLevels[value]?.height}p`;
  }

  set rate(value:number){
    this.player.playbackRate =value
  }

  get rate(){
    return this.player.playbackRate
  }

  get currentQuality() {
 
    return this?.hls?.currentLevel === -1
      ? 'Auto'
      : `${this?.HlsLevels[this.hls?.currentLevel]?.height}p`;
  }

  setQuality(value) {}
}

export default Controls;
