import { Result } from "antd";
import { ResultStatusType } from "antd/es/result";
import React from "react";

const EmptyResult = ({
  title,
  status,
  showDes = true,
}: {
  title: string;
  status?: ResultStatusType;
  showDes?: boolean;
}) => {
  return (
    <div style={{ width: "100%" }}>
      <Result
        // style={{ margin: "auto" }}
        status={status}
        title={title}
        subTitle={
          showDes
            ? "Sorry, the data you are searching is not availale for now!"
            : null
        }
      />
    </div>
  );
};

export default EmptyResult;
