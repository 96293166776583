import React, { useEffect, useState } from "react";
import { Col, Card, Row, Form, Input, Button, Checkbox, Select } from "antd";
import { CITIES, STATES } from "./States";
import api from "../../config/api";
import showNotification from "../../utils/notify";
import { useForm } from "antd/es/form/Form";
import { useApi, useMount } from "../../hooks";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useStorage } from "../../hooks";

const AddressDetails = () => {
  const { data, load } = useApi<any>("user");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [form] = useForm();

  const [CSId, setCSId] = useState<number>();
  const [PSId, setPSId] = useState<number>();
  const [disableForm, setDisableForm] = useState();

  useMount(() => {
    load();
  });

  useMount(() => {
    const address = data?.address;
    if (address) {
      const { c_state_id, p_state_id } = address;
      setCSId(c_state_id);
      setPSId(p_state_id);
      form.setFieldsValue(address);
    }
  }, [data]);

  const handleSameAs = ({ target }: CheckboxChangeEvent) => {
    setDisableForm(target?.checked);
    if (target.checked) {
      const c_state_id = form.getFieldValue("c_state_id");
      setPSId(CSId);
      form.setFieldsValue({
        p_address: form.getFieldValue("c_address"),
        p_state_id: c_state_id,
        p_city_id: form.getFieldValue("c_city_id"),
        p_code: form.getFieldValue("c_code"),
      });
    }
  };

  const handleSubmit = async () => {
    try {
      setSubmitLoader(true);
      await api.post(`/user/address`, form.getFieldsValue());
      showNotification("success", "Address Updated Successfully", 3);
      setSubmitLoader(false);
    } catch (err) {
      setSubmitLoader(false);
    }
  };
  const filterOption = (input, option) => {
    return option.name.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
        <Card>
          <Form layout="vertical" form={form} style={{ width: "90%" }}>
            <Form.Item label="Current Address" name="c_address">
              <Input
                size="large"
                id="c_address"
                type="text"
                placeholder="House & Street"
              />
            </Form.Item>

            <Row justify={"space-between"}>
              <Col span={11}>
                <Form.Item label="State" name="c_state_id">
                  <Select
                    size="large"
                    id="c_state_id"
                    onSelect={(value) => {
                      setCSId(value);
                    }}
                    filterOption={filterOption}
                    fieldNames={{ label: "name", value: "id" }}
                    showSearch
                    options={STATES}
                  />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item label="City" name="c_city_id">
                  <Select
                    size="large"
                    id="c_city_id"
                    showSearch
                    filterOption={filterOption}
                    fieldNames={{ label: "name", value: "id" }}
                    options={CITIES.filter((city) => city.state_id === CSId)}
                  ></Select>
                </Form.Item>
              </Col>

              <Col span={11}>
                <Form.Item label="Pin Code" name="c_code">
                  <Input size="large" id="c_code" type="number" maxLength={6} />
                </Form.Item>
              </Col>
            </Row>
            <Checkbox onChange={handleSameAs}>Same as Current Address</Checkbox>

            <Form.Item label="Permanent Address" name="p_address">
              <Input
                size="large"
                id="p_address"
                // onChange={onChange}
                placeholder="House & Street"
                disabled={disableForm ? true : false}
              />
            </Form.Item>
            <Row justify={"space-between"}>
              <Col span={11}>
                <Form.Item label="State" name="p_state_id">
                  <Select
                    size="large"
                    onSelect={(value) => setPSId(value)}
                    id="p_state_id"
                    filterOption={filterOption}
                    fieldNames={{ label: "name", value: "id" }}
                    showSearch
                    options={STATES}
                    disabled={disableForm ? true : false}
                  />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item label="City" name="p_city_id">
                  <Select
                    size="large"
                    id="p_city_id"
                    fieldNames={{ label: "name", value: "id" }}
                    filterOption={filterOption}
                    showSearch
                    options={CITIES.filter((city) => city.state_id === PSId)}
                    disabled={disableForm ? true : false}
                  />
                </Form.Item>
              </Col>

              <Col span={11}>
                <Form.Item label="Pin Code" name="p_code">
                  <Input
                    size="large"
                    id="p_code"
                    type="number"
                    maxLength={6}
                    disabled={disableForm ? true : false}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button
                type="primary"
                onClick={handleSubmit}
                loading={submitLoader}
                disabled={submitLoader}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default AddressDetails;
