export default function Notification() {
  return (
    // <svg
    //   width="21"
    //   height="21"
    //   viewBox="0 0 21 21"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <g clip-path="url(#clip0_1437_4680)">
    //     <path
    //       d="M10.5 0.75C12.2067 0.75 13.8434 1.42797 15.0502 2.63477C16.257 3.84156 16.935 5.47833 16.935 7.185C16.935 14.34 19.545 15.75 20.25 15.75H0.75C1.47 15.75 4.065 14.325 4.065 7.185C4.065 5.47833 4.74297 3.84156 5.94977 2.63477C7.15656 1.42797 8.79333 0.75 10.5 0.75V0.75Z"
    //       stroke="#000001"
    //       stroke-linecap="round"
    //       stroke-linejoin="round"
    //     />
    //     <path
    //       d="M8.25 18.4951C8.37966 18.9932 8.6709 19.4341 9.07808 19.7489C9.48526 20.0636 9.98536 20.2343 10.5 20.2343C11.0146 20.2343 11.5147 20.0636 11.9219 19.7489C12.3291 19.4341 12.6203 18.9932 12.75 18.4951"
    //       stroke="#000001"
    //       stroke-linecap="round"
    //       stroke-linejoin="round"
    //     />
    //   </g>
    //   <defs>
    //     <clipPath id="clip0_1437_4680">
    //       <rect width="21" height="21" fill="white" />
    //     </clipPath>
    //   </defs>
    // </svg>
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9231 15H6.85719M12.9231 15H17.9781L16.5576 13.595C16.3669 13.4063 16.2156 13.1822 16.1124 12.9357C16.0092 12.6891 15.9561 12.4249 15.9561 12.158V9C15.9562 7.75894 15.5674 6.54834 14.8432 5.53489C14.119 4.52144 13.095 3.75496 11.9121 3.341V3C11.9121 2.46957 11.6991 1.96086 11.3199 1.58579C10.9407 1.21071 10.4264 1 9.89016 1C9.3539 1 8.8396 1.21071 8.4604 1.58579C8.08121 1.96086 7.86818 2.46957 7.86818 3V3.341C5.51258 4.165 3.82422 6.388 3.82422 9V12.159C3.82422 12.697 3.60787 13.214 3.22269 13.595L1.80225 15H6.85719H12.9231ZM12.9231 15V16C12.9231 16.7956 12.6036 17.5587 12.0348 18.1213C11.466 18.6839 10.6946 19 9.89016 19C9.08577 19 8.31432 18.6839 7.74553 18.1213C7.17673 17.5587 6.85719 16.7956 6.85719 16V15H12.9231Z"
        stroke="#8B8C8C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
