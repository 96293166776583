import React from "react";
import { Button, Col, Collapse, Image, List, Row, Tag } from "antd";
import FeedBackIcon from "../icons/FeedBackIcon";
import moment from "moment";
import {
  formatDateOnly,
  formatDateTimeAMPM,
  formatHourAndMinutes,
  newDateParse,
} from "../../helpers/help";
import { PlayCircleFilled } from "@ant-design/icons";
import { TYPE } from "../../interfaces/player.d";
import PdfIconGreen from "../icons/PdfIconGreen";
import PdfIconBlue from "../icons/PdfIconBlue";
import { Link } from "@tanstack/react-router";
import styles from "./player.module.css";
import { ILesson, IVideo } from "../../interfaces/course";
import RotateIcon from "../icons/RotateIcon";
import RightRotateIcon from "../icons/RightRotateIcon";
import AskDoubtCard from "../dashboard/AskDoubtCard";

interface IProps {
  lesson: ILesson;
  activeItem: IVideo;
  videoType: TYPE;
  setActiveItem: (item: IVideo) => void;
  pdfs: IVideo[];
  videos: IVideo[];
  liveClass: IVideo;
  setFeedBack: (modal: any) => void;
}

export default function Curriculum({
  lesson,
  activeItem,
  setActiveItem,
  videoType,
  setFeedBack,
  pdfs,
  videos,
  liveClass,
  cId,
}: IProps) {
  const ListHeader = () => {
    return (
      <div style={{ color: "white" }}>
        <b>{lesson?.name}</b>
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "2%",
            gap: "0.3rem",
          }}
        >
          <Row>
            <Tag className="activeNoOfVideos">
              {videos.length || 0}&nbsp;Videos
            </Tag>
            <Tag className="activeNoOfVideos">
              {pdfs.length || 0}&nbsp; Notes
            </Tag>
          </Row>
          <Row>
            {lesson?.is_feedback == 1 && (
              // <Button
              //   style={{ fontWeight: "600" }}
              //   size="small"
              //   onClick={() => {
              //     setFeedBack({
              //       name: lesson?.name,
              //       id: lesson?.id,
              //       type: "classes",
              //     });
              //   }}
              // >
              //   Feedback
              // </Button>

              <div
                onClick={() => {
                  setFeedBack({
                    name: lesson?.name,
                    id: lesson?.id,
                    type: "classes",
                  });
                }}
              >
                <FeedBackIcon />
              </div>
            )}
          </Row>
        </Row>
      </div>
    );
  };

  const VideoItem = ({ item, index }) => {
    return (
      <Row
        align={"middle"}
        className={
          activeItem?.id === item?.id
            ? "render-vd-list active-tab"
            : moment().isBefore(item?.published_at)
            ? "deactiveUpComingClass"
            : item?.is_live === 1
            ? "live-tab"
            : "render-vd-list"
        }
      > 
        <Col span={8}>
          {item?.type !== TYPE.pdf ? (
            <>
              <Image
                preview={false}
                src={item?.thumb ? item.thumb : "/kgs.jpg"}
                style={{ height: "100%", width: "100%" }}
                alt="img"
              />
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  background:
                    "linear-gradient(180deg, rgb(51 51 51) 0%, rgba(65, 65, 65, 0.00) 100%)",
                  top: "0%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PlayCircleFilled
                  className={
                    activeItem?.id === item?.id
                      ? "currentVideoPlay"
                      : item?.is_live === 1
                      ? "liveVideoPlay"
                      : "noVideoPlay"
                  }
                />
              </div>
            </>
          ) : (
            <i className="bx bx-file-blank fs-5"></i>
          )}
        </Col>
        <Col span={16}>
          <Col span={24} offset={2}>
            <b style={{ fontSize: "0.65rem" }}>{item?.name}</b>
          </Col>
          <Col span={24} offset={2}>
            <h5 style={{ color: "#2E3192", fontSize: "0.6rem" }}>
              {newDateParse(item?.published_at)}
              {item?.duration === 0
                ? ""
                : ` || ${formatHourAndMinutes(item?.duration)} Hrs`}
            </h5>
          </Col>
          <div style={{ justifyContent: "end", display: "flex" }}>
            {item.is_live === 1 && <Tag className="liveTag">Live</Tag>}
            {item?.published_at && moment().isBefore(item?.published_at) && (
              <Tag className="upcomingClass" style={{ fontSize: "0.65rem" }}>
                Upcoming Class
              </Tag>
            )}
          </div>
        </Col>
      </Row>
    );
  };

  const NoteItem = ({ item }) => {
    return (
      <Row
        align={"middle"}
        className={activeItem?.id === item?.id ? "active-tab" : "pdf-list"}
      >
        <Col span={2}>
          {activeItem?.id === item?.id ? <PdfIconGreen /> : <PdfIconBlue />}
        </Col>
        <Col span={22}>
          <b>{item?.name}</b>
        </Col>
        <Col span={2}></Col>
        <Col span={22}>
          <h5 style={{ color: "" }}>{formatDateOnly(item?.published_at)}</h5>
        </Col>
      </Row>
    );
  };

  const ListItem = ({ item, index, onClick }) => {
    return (
      <List.Item onClick={onClick}>
        <List.Item.Meta
          title={
            videoType === TYPE.pdf ? (
              <NoteItem item={item} />
            ) : (
              <VideoItem item={item} index={index} />
            )
          }
        />
      </List.Item>
    );
  };

  if (!lesson?.id) return;
  return (
    <div className={styles.sideBarHide}>
      <div>
        <div style={{ height: "85vh", padding: "5%" }}>
          {liveClass && videoType === TYPE.video && (
            <Link to={`/live/class?vvid=${liveClass.id}`}>
              <List.Item.Meta
                title={<VideoItem item={liveClass} index={1} />}
              />
            </Link>
          )}
          <Collapse
            expandIcon={({ isActive }) =>
              isActive ? <RotateIcon /> : <RightRotateIcon />
            }
            className="activeClass"
            expandIconPosition={"end"}
            defaultActiveKey={["1"]}
          >
            <Collapse.Panel
              key="1"
              header={<ListHeader />}
              style={{ padding: "0%" }}
            >
              <div
                style={{
                  height: liveClass ? "43vh" : "56vh",
                  padding: "0% 2% 0% 2%",
                }}
                className="courseVideoScrollbar"
              >
                <List
                  itemLayout="horizontal"
                  dataSource={videoType === TYPE.video ? videos : pdfs}
                  renderItem={(item: IVideo, index: number) => (
                    <ListItem
                      index={index}
                      item={item}
                      onClick={() => {
                        if (moment().isBefore(item?.published_at)) {
                          return;
                        }
                        setActiveItem(item);
                      }}
                    />
                  )}
                />
              </div>
            </Collapse.Panel>
          </Collapse>
        </div>
        <div
          style={{
            height: "4vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <AskDoubtCard url="/courses" lesson={lesson} cId={cId} />
        </div>
      </div>
    </div>
  );
}
