import { Col, Row } from "antd";
import React, { useState } from "react";
import UserIcon from "./icons/profile/UserIcon";
import PhoneIcon from "./icons/profile/PhoneIcon";
import { Link } from "@tanstack/react-router";
import Setting from "./icons/profile/Setting";
import ChangePasswordIcon from "./icons/profile/ChangePasswordIcon";
import CustomModal from "./common/CustomModal";
import Logout from "./icons/profile/Logout";
import { useAuthStore } from "../store/auth";
import { OrderIcon, DoubtIcon } from "./icons/sideBar";
import styled from "styled-components";

const ProfileContent = () => {
  const { user, logout } = useAuthStore();
  const [open, setOpen] = useState(false);
  const hideModal = () => {
    setOpen(false);
  };

  return (
    <>
      <div style={{ width: "20em", fontSize: "0.8em" }}>
        <Row align={"middle"} className="profileItem">
          <Col span={3}>
            <UserIcon />
          </Col>
          <Col span={19} offset={1}>
            <h3 style={{ marginBottom: "0px" }}>{user?.name}</h3>
          </Col>
        </Row>
        <hr className="divider" />
        <Row align={"middle"} className="profileItem">
          <Col span={3}>
            <PhoneIcon />
          </Col>
          <Col span={19} offset={1}>
            <h3 style={{ marginBottom: "0px" }}>+91 {user?.phone}</h3>
          </Col>
        </Row>
        <hr className="divider" />
        <Link to="/profile">
          <Row align={"middle"} className="profileItem">
            <Col span={3}>
              <Setting />
            </Col>
            <Col span={19} offset={1}>
              <h3 style={{ marginBottom: "0px" }}>Edit Profile</h3>
            </Col>
          </Row>
        </Link>
        <hr className="divider" />
        <Link to="/change-password">
          <Row align={"middle"} className="profileItem">
            <Col span={3}>
              <ChangePasswordIcon />
            </Col>
            <Col span={19} offset={1}>
              <h3 style={{ marginBottom: "0px" }}>Change Password</h3>
            </Col>
          </Row>
        </Link>
        <hr className="divider" />

        <Link to="/orders">
          <Row align={"middle"} className="profileItem">
            <Col span={3}>
              <OrderIcon />
            </Col>
            <Col span={19} offset={1}>
              <h3 style={{ marginBottom: "0px" }}> Orders</h3>
            </Col>
          </Row>
        </Link>
        <hr className="divider" />

        <Link to="/doubts">
          <Row align={"middle"} className="profileItem">
            <Col span={3}>
              <DoubtIcon />
            </Col>
            <Col span={19} offset={1}>
              <h3 style={{ marginBottom: "0px" }}>Doubts</h3>
            </Col>
          </Row>
        </Link>
        <hr className="divider" />

        <Row
          style={{ marginBottom: "0%" }}
          align={"middle"}
          onClick={() => setOpen(true)}
          className="profileItem"
        >
          <Col span={3}>
            <Logout />
          </Col>
          <Col span={19} offset={1}>
            <h3 style={{ marginBottom: "0%" }}>Logout</h3>
          </Col>
        </Row>
      </div>
      <CustomModal
        onDone={() => logout()}
        open={open}
        hideModal={hideModal}
        message={"Are You Sure You Want To Logout ?"}
        ConfirmationText={"Logout"}
      />
    </>
  );
};

export default ProfileContent;
