export enum TYPE {
  pdf = "pdf",
  video = "video",
}

export type IQuality ='Auto'| '360p'

export interface IRATE {
  name: "0.5x" | "1x" | "1.25x" | "1.5x" | "1.75x" | "2x";
  value: 0.5 | 1 | 1.25 | 1.5 | 1.75 | 2;
}
