import React, { useEffect, useState } from "react";
import { Button, Col, Row, Tag } from "antd";
import styled from "styled-components";
import useQuizStore from "../../../store/quizStore";
import { IQuestion } from "../../../interfaces/quiz";
import useMount from "../../../hooks/useMount";
import ChangeLanguage, { LANG } from "./LanguageConvert";
import { useTimerStore } from "../../../store/timer";
import { useMediaQuery } from "react-responsive";

interface IProps {
  item: IQuestion;
  setLanguage: React.Dispatch<React.SetStateAction<LANG>>;
  language: LANG;
  onCompleteTimer: () => void;
}

export const QuizHeader = ({
  item,
  setLanguage,
  language,
  onCompleteTimer,
}: IProps) => {
  const { updateBookmark, status, quiz } = useQuizStore((state) => state);
  const currentStatus = status[item.id];

  return (
    <Row
      style={{
        height: "10%",
        width: "100%",
      }}
    >
      <Col lg={12} xl={12} md={12} sm={8} xs={8}>
        <GrayButton
          style={{
            marginLeft: "1%",
          }}
        >
          <strong>
            Question: {"  "}
            {currentStatus.position}
          </strong>
        </GrayButton>
        <GrayButton onClick={() => updateBookmark(item?.id)}>
          <i
            className={
              currentStatus.bookmark
                ? "bx bxs-bookmarks fs-5"
                : "bx bx-bookmarks fs-5"
            }
            style={{ cursor: "pointer" }}
          ></i>
        </GrayButton>
      </Col>
      {quiz?.meta.is_timer && (
        <Time handleComplete={onCompleteTimer} initialTime={quiz.meta.timer} />
      )}
      <ChangeLanguage setLanguage={setLanguage} language={language} />
    </Row>

    // <Row style={{ height: "10%" }}>
    //   <Col
    //     xxl={8}
    //     xl={8}
    //     lg={8}
    //     md={8}
    //     sm={8}
    //     xs={8}
    //     style={{ background: "red" }}
    //   ></Col>
    //   <Col
    //     xxl={8}
    //     xl={8}
    //     lg={8}
    //     md={8}
    //     sm={8}
    //     xs={8}
    //     style={{ background: "yellow" }}
    //   ></Col>
    //   <Col
    //     xxl={8}
    //     xl={8}
    //     lg={8}
    //     md={8}
    //     sm={8}
    //     xs={8}
    //     style={{ background: "pink" }}
    //   ></Col>
    // </Row>
  );
};

type TTime = { hours?: number; minutes: number; seconds: number };
type TTimeProps = {
  initialTime: TTime;
  handleComplete: () => void;
};
export const Time = ({ initialTime, handleComplete }: TTimeProps) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const { timer, setInitialTime, updateTimer, resetTimer } = useTimerStore();
  useEffect(() => {
    if (!timer) {
      setInitialTime(initialTime);
    }
    const intervalId = setInterval(() => {
      const success = updateTimer();
      if (!success) {
        clearInterval(intervalId);
        handleComplete();
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const formatTime = (value) =>
    value < 10 && value !== "00" ? `0${value}` : value;

  return (
    <Col lg={6} xl={6} md={6} sm={12} xs={12}>
      {!isMobile && <p style={{ margin: 0 }}>Time Left</p>}
      <MinutMan>{formatTime(timer?.hours)}</MinutMan>:
      <MinutMan>{formatTime(timer?.minutes)}</MinutMan>:
      <MinutMan>{formatTime(timer?.seconds)}</MinutMan>
    </Col>
  );
};

const MinutMan = styled(Tag)`
  font-weight: bold;
  background-color: #7b39fd;
  color: white;
  margin-left: 5px;
`;
const GrayButton = styled(Tag)`
  background-color: #ebeeff;
  margin-left: 5px;
  border: none;
  font-size: 1em;
  padding: 1% 3% 1% 3%;
`;
