import React from 'react';

export default function HlsQuality({ controls,quality, setQuality, }) {
  const handleChange = (sel) => {
    controls.hls.loadLevel = sel;
    setQuality(controls.mappedQuality(sel));
  };
  const handleChangeIos = (i) => {
    controls.qualityChange(i);
    setQuality(controls.Resolution[i].q)
  };

  return (
    <ul>
      <li
        style={{ paddingTop: '0.3em', paddingBottom: '0.3em' }}
        onClick={() => setQuality(quality)}
      >
        <div style={{ marginRight: '0.8em' }}>{`<`}</div>
        <div style={{ fontSize: '0.9em' }}>Video Quality</div>
      </li>
      <li>
        <div
          style={{
            background: 'white',
            height: '0.01em',
            marginBottom: '0.2em',
            width: '100%',
          }}
        ></div>
      </li>

      {controls.isIos
        ? controls?.resolution?.map((resol, i) => (
            <li
              onClick={() => {
                handleChangeIos(i);
              }}
            >
              {resol.q}
            </li>
          ))
        : controls?.HlsLevels.map((q, i) => (
            <li
              onClick={() => {
                handleChange(i);
              }}
            >
              {q.height}p
            </li>
          ))}
    </ul>
  );
}
