export default function ChangePasswordIcon() {
  return (
    <svg
      width="21"
      height="15"
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5385 1H2.46154C1.65435 1 1 1.83147 1 2.85714V12.1429C1 13.1685 1.65435 14 2.46154 14H18.5385C19.3456 14 20 13.1685 20 12.1429V2.85714C20 1.83147 19.3456 1 18.5385 1Z"
        stroke="#000001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.38507 8.42868C5.78866 8.42868 6.11584 8.01294 6.11584 7.5001C6.11584 6.98727 5.78866 6.57153 5.38507 6.57153C4.98147 6.57153 4.6543 6.98727 4.6543 7.5001C4.6543 8.01294 4.98147 8.42868 5.38507 8.42868Z"
        stroke="#000001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.76886 8.42868C10.1724 8.42868 10.4996 8.01294 10.4996 7.5001C10.4996 6.98727 10.1724 6.57153 9.76886 6.57153C9.36526 6.57153 9.03809 6.98727 9.03809 7.5001C9.03809 8.01294 9.36526 8.42868 9.76886 8.42868Z"
        stroke="#000001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.1533 9.35718H16.3456"
        stroke="#000001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
