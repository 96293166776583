export default function StoreIcon() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4335 3.24069C18.2052 2.97237 17.9194 2.75658 17.5963 2.60862C17.2733 2.46067 16.921 2.38419 16.5644 2.38462H3.44273L3.40864 2.10562C3.33889 1.52569 3.05429 0.990992 2.60882 0.602893C2.16335 0.214793 1.58796 0.000271682 0.991753 0L0.811582 0C0.596337 0 0.389908 0.0837452 0.237707 0.232813C0.0855057 0.38188 0 0.584059 0 0.794872C0 1.00568 0.0855057 1.20786 0.237707 1.35693C0.389908 1.506 0.596337 1.58974 0.811582 1.58974H0.991753C1.19054 1.58977 1.3824 1.66125 1.53095 1.79062C1.67949 1.91999 1.7744 2.09826 1.79765 2.29162L2.91439 11.5916C3.03033 12.5588 3.5048 13.4506 4.24775 14.0979C4.99069 14.7451 5.9504 15.1026 6.94471 15.1026H15.4201C15.6353 15.1026 15.8417 15.0188 15.9939 14.8698C16.1461 14.7207 16.2316 14.5185 16.2316 14.3077C16.2316 14.0969 16.1461 13.8947 15.9939 13.7456C15.8417 13.5966 15.6353 13.5128 15.4201 13.5128H6.94471C6.44238 13.5114 5.95279 13.3579 5.54305 13.0733C5.13331 12.7887 4.82348 12.3869 4.65604 11.9231H14.3301C15.2815 11.9231 16.2027 11.5958 16.9327 10.9982C17.6627 10.4006 18.1551 9.57081 18.3239 8.65377L18.961 5.1929C19.0246 4.84952 19.0103 4.49664 18.9191 4.15928C18.828 3.82191 18.6622 3.50831 18.4335 3.24069ZM17.3679 4.91072L16.7299 8.37159C16.6286 8.92244 16.3327 9.42081 15.8939 9.77946C15.4552 10.1381 14.9016 10.3342 14.3301 10.3333H4.39796L3.63426 3.97436H16.5644C16.6836 3.97366 16.8015 3.9987 16.9097 4.04769C17.018 4.09668 17.1138 4.16842 17.1905 4.25782C17.2672 4.34721 17.3229 4.45205 17.3535 4.5649C17.3842 4.67774 17.3891 4.79581 17.3679 4.91072Z"
        fill="#2E3192"
      />
      <path
        d="M5.625 18.9999C6.52246 18.9999 7.25 18.291 7.25 17.4166C7.25 16.5421 6.52246 15.8333 5.625 15.8333C4.72754 15.8333 4 16.5421 4 17.4166C4 18.291 4.72754 18.9999 5.625 18.9999Z"
        fill="#2E3192"
      />
      <path
        d="M13.875 18.9999C14.7725 18.9999 15.5 18.291 15.5 17.4166C15.5 16.5421 14.7725 15.8333 13.875 15.8333C12.9775 15.8333 12.25 16.5421 12.25 17.4166C12.25 18.291 12.9775 18.9999 13.875 18.9999Z"
        fill="#2E3192"
      />
    </svg>
  );
}
