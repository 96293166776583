import React from "react";
import isMobile from "../utils/isMobile";

export default function useControllerVisible(url:string){
  const [isController, setIsController] = React.useState<boolean>(true);
  React.useEffect(() => {
    const videoContainer = document.getElementById("custom-video-container");
    var timer;
    if (isMobile()) {
      document.addEventListener("touchstart", () => {
        clearTimeout(timer);
        setIsController(true);
      });

      document.addEventListener("touchend", () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          setIsController(false);
        }, 2000);
      });
    } else {
      videoContainer?.addEventListener("mousemove", () => {
        setIsController(true);
        clearTimeout(timer);
        timer = setTimeout(() => {
          setIsController(false);
        }, 3000);
      });
    }

    return () => {
      videoContainer?.removeEventListener("mousemove",(el)=>{})
    };
  }, [url]);
return {isController,setIsController}
}