import React from "react";
import { Button, Table, Card, Row } from "antd";
import moment from "moment";
import { Link, useNavigate } from "@tanstack/react-router";
import { IQuizListing } from "../../../type";
import { HeaderContainer, TableHeader } from "../../component/GlobalCard";
import QuizRank from "./QuizRank";
import styled from "styled-components";

type IProps = {
  quizzes: IQuizListing[];
  quizzesType: string;
};

const QCurriculam = ({ quizzes, quizzesType }: IProps) => {
  const navigator = useNavigate({ from: "/" });
  const columns = [
    {
      title: <Title>Test Series</Title>,
      dataIndex: "title",
      key: "title",
      render: (_, { title, published_at }) => {
        return (
          <SubTitle>
            <h3
              style={{
                fontWeight: "600",
                marginBottom: "0%",
                // marginLeft: "2%",
              }}
            >
              {title}
            </h3>
            <p style={{ color: "#757575" }}>
              {`Available on ${moment(published_at).format(
                "Do MMM YYYY hh:mm A"
              )}`}
            </p>
          </SubTitle>
        );
      },
      width: 500,
    },
    {
      title: <Title>Actions</Title>,
      dataIndex: "start",
      key: "start",
      render: (_, { id, published_at }) => {
        const published_date = new Date(published_at);
        const current_date = new Date();
        const quiz_available_today = current_date >= published_date;

        return (
          <SubTitle>
            <Button
              style={{ backgroundColor: " #2E3192", color: "white" }}
              disabled={!quiz_available_today}
              onClick={() =>
                navigator({ to: `/quizzes/${quizzesType}/${id}/start` })
              }
            >
              Start now
            </Button>
          </SubTitle>
        );
      },
      width: 100,
    },

    {
      title: <Title>Actions</Title>,
      dataIndex: "start",
      key: "start",
      render: (_, { id, published_at }) => {
        const published_date = new Date(published_at);
        const current_date = new Date();
        const quiz_available_today = current_date >= published_date;
        return (
          <SubTitle>
            <QuizRank id={id} disabled={!quiz_available_today} />
          </SubTitle>
        );
      },
      width: 100,
    },
  ];
  return (
    <>
      {quizzes?.length && (
        <div
          style={{ width: "100%", overflow: "auto", backgroundColor: "white" }}
        >
          <Table
            dataSource={quizzes}
            columns={columns}
            rowKey={(_, _index) => _index!}
            scroll={{ y: 500, x: 800 }}
          />
        </div>
      )}
    </>
  );
};

const Title = styled.p`
  font-size: 1.2em;
  margin-bottom: 0%;
  padding: 1em 1em 1em 1em;
  font-weight: 600;
`;

const SubTitle = styled.p`
  font-size: 1em;
  margin-bottom: 0%;
  padding: 1em 1em 1em 1em;
  font-weight: 600;
`;

export default QCurriculam;
