import React from "react";
import { authorizeApi } from "./config/api";
import DashboardLayout from "./Layout";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import Login from "./pages/Login";
import { NavbarStatus } from "./contexts/navbarStatus";
import { StorageProvider } from "./hooks/useStorage";
import { useAuthStore } from "./store/auth";
import { useApi, useMount } from "./hooks";
import RegisterForm from "./pages/RegisterForm";
import ForgetPassword from "./pages/ForgetPassword";
import { useNotificationStore } from "./store/notificatonStore";
import { recommendStore } from "./store/recommendedCourseStore";
import { usePublicStore } from "./store/usePublicStore";

function App() {
  const searchParams = new URLSearchParams(window.location.search);
  const queryToken = searchParams.get("token");
  const { isLoading, token, user, setToken } = useAuthStore();
  const location = window.location;

  authorizeApi(token);
  useMount(() => {
    if (!queryToken) return;
    setToken(queryToken);
    if (searchParams.has("token")) {
      searchParams.delete("token");
      authorizeApi(queryToken);
      window.history.replaceState(null, "", window.location.pathname);
    }
  }, [queryToken]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 5 * 60,
      },
    },
  });

  const persister = createSyncStoragePersister({
    storage: window.localStorage,
  });

  return (
    <StorageProvider>
      {token && user ? (
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{ persister }}
        >
          <NavbarStatus>
            <DashboardLayout />
          </NavbarStatus>
        </PersistQueryClientProvider>
      ) : (
        <>
          {location.pathname === "/forget-password" ? (
            <ForgetPassword />
          ) : location.pathname !== "/register" ? (
            <Login />
          ) : (
            <RegisterForm />
          )}
        </>
      )}
    </StorageProvider>
  );
}

export default App;
