import React, { useState } from "react";
import { Layout, Image } from "antd";
import { Outlet } from "@tanstack/react-router";
import styled from "styled-components";
import SidebarMenu from "./component/Layout/SidebarMenu";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";
import DashboardNavbar from "./component/Layout/DashboardNavbar";
import { kgsLogo } from "./utils/constants";
import RightCollapse from "./component/icons/sideBar/RightCollapse";
import LeftCollapse from "./component/icons/sideBar/LeftCollapse";
import { liveClassesStore } from "./store/liveClassesStore";
import useApi from "./hooks/useApi";
import useMount from "./hooks/useMount";
import { ICourse } from "./interfaces/course";
import { MenuOutlined } from "@ant-design/icons";
import { usePublicStore } from "./store/usePublicStore";
import { useNotificationStore } from "./store/notificatonStore";
import { recommendStore } from "./store/recommendedCourseStore";
import { useNcertStore } from "./store/useNcertStore";

const { Content, Sider } = Layout;

const HIDE_ON_SCREEN = ["result", "quiz", "start", "play", "class", "checkout"];

function DashboardLayout() {
  const { setIsLoading, storePublicData } = usePublicStore();
  const { setNotification, setLoading } = useNotificationStore();
  const { setCourses, setRcIsLoading, setTestSeries } = recommendStore();
  const { storeNcertData } = useNcertStore();
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const [collapsed, setCollapsed] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const path = window?.location?.pathname;
  const url = path?.split("/");
  const contains = url.some((element) => {
    return HIDE_ON_SCREEN.includes(element);
  });

  const hideSideBar =
    (url.length >= 3 && contains) ||
    url.includes("checkout") ||
    url.includes("payment-status") ||
    isMobile;
  // const pathname = url.length >= 3;
  // const isHideSidebar =  isMobile;
  const hideMobileBar = url.length >= 3;
  const { liveStore, storeLiveData } = liveClassesStore((state: any) => state);
  const { data, load, isLoading } = useApi<{ today?: ICourse[] }>("lives");

  useEffect(() => {
    setDrawerOpen(false);
  }, [path]);

  useMount(() => {
    load();
  });
  useMount(() => {
    if (!data) return;
    storeLiveData(data?.today);
  }, [data?.today]);

  //

  const {
    data: publicDatum,
    isLoading: publicLoading,
    load: publicLoad,
  } = useApi<any[]>(`/public`);

  const {
    data: notificationData,
    isLoading: notificationLoading,
    load: notificationLoad,
  } = useApi<any[]>("notifications?type=drawer");

  const {
    data: rcData,
    isLoading: rcLoading,
    load: rcLoad,
  } = useApi<any>(`/courses`);

  const {
    data: rtData,
    isLoading: rtLoading,
    load: rtLoad,
  } = useApi<any>(`/test-series`);

  useMount(() => {
    publicLoad();
    notificationLoad();
    rcLoad();
    rtLoad();
    storeNcertData();
  }, []);

  useMount(() => {
    storePublicData(publicDatum);
    setIsLoading(publicLoading);
    setNotification(notificationData);
    setLoading(notificationLoading);
    setCourses(rcData?.data);
    setRcIsLoading(rcLoading || rtLoading);
    setTestSeries(rtData?.data);
  }, [
    publicDatum,
    publicLoading,
    notificationLoading,
    notificationData,
    rcData,
    rcLoading,
    rtData,
    rtLoading,
  ]);

  return (
    <LayoutContainer>
      {isMobile &&
        !hideMobileBar &&
        // <DashboardNavbar
        //   drawerOpen={drawerOpen}
        //   setDrawerOpen={() => setDrawerOpen(!drawerOpen)}
        // />
        null}
      {!hideSideBar && (
        <Sider
          collapsible
          trigger={null}
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          style={{ background: "#2E3192" }}
        >
          <a href="/home">
            <LogoContainer>
              <Image height={50} src={kgsLogo} preview={false} />
              {!collapsed && (
                <div>
                  <h4 style={{ marginBottom: 0, fontWeight: 700 }}>KGS</h4>
                </div>
              )}
            </LogoContainer>
          </a>
          <CollapseBar onClick={() => setCollapsed(!collapsed)}>
            {collapsed ? <RightCollapse /> : <LeftCollapse />}
          </CollapseBar>
          <SidebarMenu collapsed={collapsed} />
        </Sider>
      )}
      <Layout>
        <Content
          className="hideScrollbar"
          style={{
            backgroundColor: "#EDF4FF",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </LayoutContainer>
  );
}

const LogoContainer = styled.div`
  font-size: 25px;
  display: flex;
  align-items: center;
  padding: 0.1em;
  margin: 5px;
  border-radius: 10px;
  justify-content: center;
  color: White;
`;

const LayoutContainer = styled(Layout)`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

const CollapseBar = styled.div`
  position: absolute;
  bottom: 0.2em;
  width: 100%;
  cursor: pointer;
  text-align: center;
  background-color: #262872;
  border-radius: 0.5em;
  padding: 0.5em 0em 0.2em 0em;
`;
export default DashboardLayout;
