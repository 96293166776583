export default function CallingIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 0C3.35729 0 0 3.35729 0 7.5C0 11.6427 3.35729 15 7.5 15C11.6427 15 15 11.6427 15 7.5C15 3.35729 11.6427 0 7.5 0ZM8.62423 11.1345C5.37474 9.53285 4.52772 6.65298 4.40452 5.60575C4.28131 4.55852 6.09856 3.6345 6.09856 3.6345L7.23819 5.57495C7.23819 5.57495 6.00616 6.23717 6.17557 6.59138C7.08419 8.50103 8.25462 9.20945 8.25462 9.20945C8.63963 9.34805 9.48665 8.40863 9.48665 8.40863L11.1191 9.94867C11.1345 9.94867 9.76386 11.6889 8.62423 11.1345Z"
        fill="white"
      />
    </svg>
  );
}
