export default function UserIcon() {
  return (
    <svg
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 11.264C4.26179 11.264 0 15.4728 0 20.646V21H19V20.646C19 15.4705 14.7382 11.264 9.5 11.264ZM0.726415 20.2896C0.915094 15.6731 4.7783 11.9744 9.5 11.9744C14.2217 11.9744 18.0849 15.6731 18.2736 20.2896H0.726415ZM9.5 9.42391C12.1297 9.42391 14.2712 7.31134 14.2712 4.71196C14.2712 2.11491 12.1321 0 9.5 0C6.87028 0 4.72877 2.11258 4.72877 4.71196C4.72877 7.30901 6.87028 9.42391 9.5 9.42391ZM9.5 0.710404C11.7335 0.710404 13.5519 2.50621 13.5519 4.71196C13.5519 6.9177 11.7335 8.71351 9.5 8.71351C7.26651 8.71351 5.44811 6.9177 5.44811 4.71196C5.44811 2.50621 7.26651 0.710404 9.5 0.710404Z"
        fill="black"
      />
    </svg>
  );
}
