import { create } from "zustand";
import { persist } from 'zustand/middleware'
import api from "../config/api";

interface INcertStore{
    active:any;
    ncertData:any;
    isLoading:boolean;
    isError:any;
    setActive:any;
    className:any;
    setClassName:string
}

export const useNcertStore = create(persist<INcertStore>((set)=>({
    ncertData:[],
    active:{},
    className:{},
    setActive:(data)=> set(({active:data})),
    setClassName:(data)=>set(({className:data})),
    isLoading:true,
    isError:null,
    storeNcertData:async ()=>{
        const {data} = await api.get('standards')
        set(({isLoading:false,ncertData:data}))
    }
}), {
    name: "useNcertStore"
}))