export default function AccuracyIcon2() {
  return (
    <svg
      width="26"
      height="32"
      viewBox="0 0 26 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.25 4.26667V24.5333C3.25 25.6651 3.70608 26.7499 4.51967 27.5499C5.33217 28.3509 6.43392 28.8 7.58333 28.8H21.6667C21.6667 29.6491 21.3243 30.4629 20.7144 31.0624C20.1056 31.6629 19.279 32 18.4167 32C14.2653 32 7.40133 32 3.25 32C2.38767 32 1.56108 31.6629 0.95225 31.0624C0.342334 30.4629 0 29.6491 0 28.8V7.46667C0 5.6992 1.45492 4.26667 3.25 4.26667ZM4.33333 24.5333V3.2C4.33333 1.43253 5.78825 0 7.58333 0H18.4167C18.4167 0 18.4167 3.1264 18.4167 5.33333C18.4167 6.512 19.3863 7.46667 20.5833 7.46667H26V24.5333C26 25.3824 25.6577 26.1963 25.0478 26.7957C24.4389 27.3963 23.6123 27.7333 22.75 27.7333C18.5987 27.7333 11.7347 27.7333 7.58333 27.7333C6.721 27.7333 5.89442 27.3963 5.28558 26.7957C4.67567 26.1963 4.33333 25.3824 4.33333 24.5333ZM15.1667 13.8667C18.1567 13.8667 20.5833 16.256 20.5833 19.2C20.5833 22.144 18.1567 24.5333 15.1667 24.5333C12.1767 24.5333 9.75 22.144 9.75 19.2C9.75 16.256 12.1767 13.8667 15.1667 13.8667ZM16.9997 17.1477H16.9867C16.6833 17.1488 16.3822 17.2757 16.1687 17.52L14.7821 19.1136L14.1386 18.4256C13.7334 17.9925 13.0477 17.9648 12.6078 18.3637C12.168 18.7616 12.1398 19.4379 12.545 19.8709L14.014 21.4421C14.1191 21.5552 14.2469 21.6427 14.3878 21.7013C14.5221 21.7579 14.6673 21.7867 14.8157 21.7856H14.8298C15.1103 21.7813 15.3768 21.6693 15.574 21.4763C15.5946 21.456 17.8252 18.8949 17.8306 18.8885C17.9584 18.7349 18.0213 18.576 18.0516 18.4331C18.1296 18.0651 18.0072 17.6661 17.6962 17.4037C17.4947 17.2331 17.2467 17.1488 16.9997 17.1477ZM9.75 10.6667H15.1667C15.7647 10.6667 16.25 10.1888 16.25 9.6C16.25 9.0112 15.7647 8.53333 15.1667 8.53333H9.75C9.152 8.53333 8.66667 9.0112 8.66667 9.6C8.66667 10.1888 9.152 10.6667 9.75 10.6667ZM9.75 6.4H14.0833C14.6813 6.4 15.1667 5.92213 15.1667 5.33333C15.1667 4.74453 14.6813 4.26667 14.0833 4.26667H9.75C9.152 4.26667 8.66667 4.74453 8.66667 5.33333C8.66667 5.92213 9.152 6.4 9.75 6.4ZM19.5 0V5.33333C19.5 5.616 19.6138 5.888 19.8174 6.08747C20.02 6.288 20.2963 6.4 20.5833 6.4H26L19.5 0Z"
        fill="#253D86"
      />
    </svg>
  );
}
