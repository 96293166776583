export default function CourseIcon() {
  return (
    <>
      <g clip-path="url(#clip0_1437_8078)">
        <path
          d="M4.81752 0.678467H1.42467C1.0499 0.678467 0.746094 0.982274 0.746094 1.35704V17.6428C0.746094 18.0175 1.0499 18.3213 1.42467 18.3213H4.81752C5.19229 18.3213 5.49609 18.0175 5.49609 17.6428V1.35704C5.49609 0.982274 5.19229 0.678467 4.81752 0.678467Z"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.56752 3.39282H6.17467C5.7999 3.39282 5.49609 3.69663 5.49609 4.07139V17.6428C5.49609 18.0176 5.7999 18.3214 6.17467 18.3214H9.56752C9.94229 18.3214 10.2461 18.0176 10.2461 17.6428V4.07139C10.2461 3.69663 9.94229 3.39282 9.56752 3.39282Z"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.1097 3.02129L11.4766 3.68023C11.1131 3.77122 10.8921 4.13969 10.9831 4.50324L14.2778 17.6687C14.3688 18.0322 14.7373 18.2532 15.1008 18.1622L17.7339 17.5033C18.0975 17.4123 18.3184 17.0438 18.2274 16.6803L14.9327 3.51483C14.8417 3.15127 14.4733 2.93031 14.1097 3.02129Z"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M0.746094 13.5713H5.49609"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.49609 12.2141H10.2461"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.6387 14.9286L17.5472 13.9514"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1437_8078">
          <rect width="19" height="19" fill="white" />
        </clipPath>
      </defs>
    </>
  );
}
