import React from "react";
import { useState } from "react";
import { Modal } from "antd";

type ITitle = "View Answer" | "Attempt Answer";

type IProps = {
  open: boolean;
  hideModal: () => void;
  onDone: () => void;
  message?: string | React.JSX.Element;
  width?: string | number;
  style?: string;
  ConfirmationText: string;
  height?: string | number;
  title?: string;
  courseName?: string;
  type?: string;
  query?: string;
  asked?: string;
  reply?: string;
  className?: string;
};
const CustomModal = ({
  open,
  onDone,
  hideModal,
  message,
  style,
  width,
  ConfirmationText,
  height,
  title,
}: IProps) => {
  const [visibal, setVisibal] = useState(open);

  return (
    <Modal
      title={title}
      centered
      cancelButtonProps={{ style: { display: "none" } }}
      width={width}
      open={open}
      onOk={onDone}
      onCancel={() => hideModal()}
      okText={ConfirmationText}
      // okButtonProps={{ style: { display: "none" } }}
    >
      <div
        className="answer-writing-modal"
        style={{
          width: width && "100%",
          height: height || undefined,
        }}
      >
        {message}
      </div>
    </Modal>
  );
};

export default CustomModal;
