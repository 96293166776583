import React, { useEffect, useState } from "react";
import { AnswerWritingSlug, AnswerWritingTitle } from "../../helpers/help";
import { Card } from "antd";
import api from "../../config/api";
import { Navigate, useNavigate, useSearch } from "@tanstack/react-router";
import parse from "html-react-parser";
import PageContainer from "../../component/Layout/PageContainer";
import Loader from "../../component/loader";
import CustomModal from "../../component/common/CustomModal";
import { TestContainer } from "../quiz/AllQuiz";
import { BottomContaner, H3, Question, P } from "../../component/GlobalCard";
import AppMetaHeader from "../../component/AppMetaHeader";
import AttemptAnswerWriting from "./AttemptAnswerWriting";

const AnsWritingQue = () => {
  const [subjective, setSubjective] = React.useState([]);
  const [subjectiveQue, setSubjectiveQue] = React.useState([]);
  const [isModelOpen, setModelOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState();

  const openModal = (content) => {
    setModelOpen(true);
    setContent(content);
  };

  const { id } = useSearch({
    from: "/answer-writing/question",
  });

  useEffect(() => {
    const fetchAnswerWriting = async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`subjectives/${id}`);

        setSubjective(data);
        setSubjectiveQue(data?.questions);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchAnswerWriting();
  }, [id]);

  const navigate = useNavigate({ from: "/" });

  return (
    <div>
      <AppMetaHeader currentPageTitle="Answer Writing" />
      <PageContainer
        currentPageTitle={subjective?.name}
        loading={loading}
        transparent={true}
        title={`${AnswerWritingTitle(subjective?.batch_id)}`}
        url={`/answer-writing/${AnswerWritingSlug(
          subjective?.batch_id
        )}?batch=${subjective?.batch_id}`}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            {subjectiveQue.map((item: any, index) => (
              <>
                <Card hoverable style={{ marginBottom: "1%" }}>
                  <BottomContaner>English</BottomContaner>
                  <BottomContaner>Hindi</BottomContaner>
                  <br />
                  <div style={{ paddingTop: "1rem" }}>
                    <H3>Question {index + 1}</H3>
                  </div>
                  <TestContainer>{parse(item?.q)}</TestContainer>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <BottomContaner
                      style={{
                        borderRadius: "4px",
                        background: "#E2F1FF",
                        color: "#113D66",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        openModal(
                          <AttemptAnswerWriting
                            id={item?.id}
                            que={parse(item?.q)}
                          />
                        );
                      }}
                    >
                      Attempt Answer Writing
                    </BottomContaner>

                    {item?.a && (
                      <BottomContaner
                        onClick={() => openModal(parse(item?.a))}
                        style={{
                          borderRadius: "4px",
                          background: "#E2F1FF",
                          color: "#113D66",
                          border: "none",
                          cursor: "pointer",
                        }}
                      >
                        View Answer
                      </BottomContaner>
                    )}

                    <BottomContaner
                      onClick={() =>
                        navigate({
                          to: `/answer-writing/responses?id=${id}&qId=${item?.id}`,
                        })
                      }
                      style={{
                        borderRadius: "4px",
                        background: "#E2F1FF",
                        color: "#113D66",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      View Other Responses
                    </BottomContaner>
                  </div>

                  <CustomModal
                    height="80vh"
                    ConfirmationText="Close"
                    onDone={() => setModelOpen(false)}
                    open={isModelOpen}
                    width={"80%"}
                    style={"modal-wrapper-answer"}
                    hideModal={() => setModelOpen(false)}
                    message={content}
                  />
                </Card>
              </>
            ))}
          </>
        )}
      </PageContainer>
    </div>
  );
};

export default AnsWritingQue;
