import React, { useEffect, useState } from "react";
import api from "../../config/api";
import { Col, Row, Tabs, Button } from "antd";
import { Link, useSearch } from "@tanstack/react-router";
import CourseCard from "../../component/Card";
import { AnswerWritingThumb, AnswerWritingTitle } from "../../helpers/help";
import Loader from "../../component/loader";
import PageContainer from "../../component/Layout/PageContainer";
import PaginationComp from "../../utils/PaginationComp";
import AppMetaHeader from "../../component/AppMetaHeader";
import Shimmer from "../../component/Shimmer";

const AnsWritingType = () => {
  const [answerWriting, setAnswerWriting] = React.useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = React.useState(false);
  const [total, setTotal] = useState();
  const [perPage, setPerPage] = useState();

  const { batch } = useSearch({
    from: "/answer-writing/$slug",
  });

  useEffect(() => {
    const fetchAnswerWriting = async () => {
      try {
        setLoading(true);
        const { data } = await api.get(
          `subjectives?type=mains&batch=${batch}&page=${page}`
        );
        setTotal(data?.total);
        setPerPage(data?.per_page);
        setAnswerWriting(data?.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchAnswerWriting();
  }, [page]);

  return (
    <div>
      <AppMetaHeader currentPageTitle="Answer Writing" />
      <PageContainer
        currentPageTitle={`${AnswerWritingTitle(batch)}`}
        // loading={loading}
        transparent={true}
        title="All Answer Writing"
        url="/submission"
      >
        <Shimmer type="test-series" loading={loading} count={50}>
          <>
            <Row gutter={[16, 16]}>
              {answerWriting?.length &&
                answerWriting?.map((d: any) => (
                  <Col key={d?.id} xs={24} sm={12} md={8} lg={6} xl={6}>
                    <Link to={`/answer-writing/question?id=${d?.id}`}>
                      <CourseCard
                        title={d?.name}
                        image={`${AnswerWritingThumb(batch)}`}
                        published={d?.published_at}
                      />
                    </Link>
                  </Col>
                ))}
            </Row>
            <br />

            <PaginationComp
              page={page}
              total={total}
              perPage={perPage}
              onChange={(page) => setPage(page)}
            />
          </>
        </Shimmer>
      </PageContainer>
    </div>
  );
};

export default AnsWritingType;
