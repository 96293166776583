import { useRouter } from "@tanstack/react-router";
import React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import CourseBack from "./icons/CourseBack";

const BackButton = () => {
  const router = useRouter();
  const onBack = () => router.history.back();

  return (
    <div onClick={onBack} style={{ cursor: "pointer" }}>
      <CourseBack />
    </div>
  );
};

export default BackButton;
