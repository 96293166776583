import React, { useEffect, useState } from "react";
import { useApi, useMount } from "../../hooks";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { GENDER, GENDER2 } from "../../utils/constants/data";
import api from "../../config/api";
import { useAuthStore } from "../../store/auth";
import moment from "moment";
import BubleSelect from "../../component/BubleSelect";
import { useMediaQuery } from "react-responsive";
const dateFormat = "DD-MM-YYYY";

const AdmitCard = ({ id, catId, center }) => {
  const { user } = useAuthStore();
  const [selected, setSelected] = useState();
  const [rollNo, setRollNo] = useState();
  const [loading, setLoading] = useState(false);

  const getRollNo = async () => {
    if (catId == 1) return;
    const { data } = await api.get(`test-series/${id}/rollno`);
    setRollNo(data);
  };

  useEffect(() => {
    getRollNo();
  }, []);

  const [form] = Form.useForm();

  function disabledDate(current) {
    return current && current > moment().endOf("day");
  }

  const onFinish = async (values) => {
    setLoading(true);
    try {
      // const { data } = await api.get(`/test-series/${id}/admit-card`, {
      const { data } = await api.get(
        `https://admin.khanglobalstudies.com/api/test-series/${id}/admit-card`,
        {
          params: {
            name: values.name,
            center: values.center,
            user_id: user?.id,
          },
        }
      );
      setLoading(false);
      const url = `https://admin.khanglobalstudies.com/api/test-series/${id}/admit-card?user_id=${user.id}`;
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col xl={18} lg={18} md={24} sm={24} xs={24}>
        <Form
          layout="vertical"
          name="update-profile"
          initialValues={{
            remember: true,
          }}
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            name="name"
            label={"Name"}
            rules={[
              {
                required: true,
                message: "Please input your Name!",
              },
            ]}
            initialValue={user?.name}
          >
            <Input
              // prefix={<UserOutlined />}
              placeholder="Name"
              type="text"
              size="large"
            />
          </Form.Item>

          <Form.Item
            name="phone"
            label={"Phone Number"}
            rules={[
              {
                required: false,
                message: "Please input your Phone!",
              },
            ]}
            initialValue={user?.phone}
          >
            <Input
              // prefix={<UserOutlined />}
              placeholder="Phone"
              disabled={true}
              // onInput={(e: BaseSyntheticEvent) =>
              //   (e.target.value = e.target.value.slice(0, 10))
              // }
              type="number"
              size="large"
            />
          </Form.Item>

          {/* <Form.Item
            name="dob"
            label="Date of Birth"
            initialValue={
              user?.profile?.dob ? moment(user.profile.dob, "DD-MM-YYYY") : null
            }
            rules={[
              {
                required: true,
                message: "Please Select DOB!",
              },
            ]}
          >
            <DatePicker
              size="large"
              style={{ width: "100%" }}
              showToday={false}
              disabledDate={disabledDate}
              format={dateFormat}
              placeholder="DD-MM-YYYY"
            />
          </Form.Item> */}

          <Form.Item
            label="Offline Center"
            rules={[
              {
                required: true,
                message: "Please Select Center!",
              },
            ]}
          >
            {center?.map((item) => (
              <Button
                size="large"
                disabled={rollNo?.center ? true : false}
                onClick={() => {
                  setSelected(item?.name);
                  form.setFieldsValue({ center: item?.name });
                }}
                style={{
                  margin: "1%",
                  border: "none",
                  color:
                    selected === item?.name
                      ? "white"
                      : rollNo?.center?.toLowerCase() ===
                        item?.name?.toLowerCase()
                      ? "white"
                      : "black",
                  backgroundColor:
                    selected === item?.name
                      ? "#2E3192"
                      : rollNo?.center?.toLowerCase() ===
                        item?.name?.toLowerCase()
                      ? "#2E3192"
                      : "#F3F4F6",
                  marginRight: "2%",
                }}
                key={item?.id}
              >
                {item?.name}
              </Button>
            ))}
          </Form.Item>

          <Form.Item name="center" hidden>
            <Input type="hidden" />
          </Form.Item>

          <Form.Item
            name="email"
            label={"Email"}
            rules={[
              {
                required: true,
                message: "Please input your Email!",
              },
            ]}
            initialValue={user?.email}
          >
            <Input
              disabled={true}
              // prefix={<UserOutlined />}
              placeholder="Email"
              type="text"
              size="large"
            />
          </Form.Item>

          <Form.Item
            name="gender"
            label="Select Gender"
            rules={[
              {
                required: true,
                message: "Please input your Gender!",
              },
            ]}
            initialValue={user?.profile?.gender?.toLowerCase()}
          >
            <Select
              // disabled={true}
              style={{ width: "100%" }}
              placeholder="Please select your gender"
              defaultValue={[]}
              options={GENDER2}
              size="large"
            />
          </Form.Item>

          <Form.Item>
            <Button
              loading={loading}
              disabled={loading}
              // type="primary"
              htmlType="submit"
              size="large"
              style={{ backgroundColor: "#2E3192", color: "white" }}
            >
              {(rollNo?.center && "Download Admit Card") ||
                "Save & Download Admit Card"}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default AdmitCard;
