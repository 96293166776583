import React from "react";
import { Button, Result } from "antd";
import CloudIcon from "./icons/CloudIcon";
import { loadScript } from "pdfjs-dist";

export default function EmptyWrapper({ load }) {
  // {
  //   // isEmpty,
  //   // children,
  //   // title,
  //   // showDes = false,
  //   // status = 404,
  // }
  // if (isEmpty)
  return (
    // <Result
    //   style={{ margin: "auto" }}
    //   status={status}
    //   title={title}
    //   subTitle={
    //     showDes
    //       ? "Sorry, the data you are searching is not availale for now!"
    //       : null
    //   }
    // />
    <div
      style={{
        margin: "auto",
        width: "100%",
        height: "70vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <CloudIcon />
        <Button
          size="large"
          style={{
            backgroundColor: "#2E3192",
            display: "flex",
            alignItems: "center",
            color: "white",
            fontWeight: "550",
            marginLeft: "8%",
          }}
          onClick={() => load()}
        >
          <svg
            width="19"
            height="21"
            viewBox="0 0 19 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.4732 10.8467C16.9537 10.8467 16.5287 11.2717 16.5287 11.7912C16.5287 15.8289 13.2466 19.111 9.20885 19.111C5.17112 19.111 1.88899 15.8289 1.88899 11.7912C1.88899 8.29651 4.34469 5.36857 7.6032 4.63659L6.54064 6.00611C6.21007 6.40752 6.30452 7.02144 6.70593 7.3284C6.87122 7.47008 7.08373 7.51731 7.29624 7.51731C7.57959 7.51731 7.86294 7.39924 8.05184 7.16312L10.4131 4.11712C10.72 3.7157 10.6728 3.14901 10.2714 2.81843L7.24901 0.221065C6.8476 -0.109509 6.25729 -0.0622836 5.90311 0.315515C5.57253 0.716926 5.61976 1.30724 5.99756 1.63781L7.29624 2.7476C3.14045 3.64487 0 7.35202 0 11.7912C0 16.8678 4.13217 21 9.20885 21C14.2855 21 18.4177 16.8678 18.4177 11.7912C18.4177 11.2717 17.9927 10.8467 17.4732 10.8467Z"
              fill="white"
            />
          </svg>
          <pre> </pre>
          Refresh for Live Classes
        </Button>
      </div>
    </div>
  );
  // return children;
}
