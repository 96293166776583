export default function CallButton() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 0C4.70021 0 0 4.70021 0 10.5C0 16.2998 4.70021 21 10.5 21C16.2998 21 21 16.2998 21 10.5C21 4.70021 16.2998 0 10.5 0ZM12.0739 15.5883C7.52464 13.346 6.33881 9.31417 6.16633 7.84805C5.99384 6.38193 8.53799 5.0883 8.53799 5.0883L10.1335 7.80493C10.1335 7.80493 8.40863 8.73203 8.64579 9.22793C9.91787 11.9014 11.5565 12.8932 11.5565 12.8932C12.0955 13.0873 13.2813 11.7721 13.2813 11.7721L15.5667 13.9281C15.5883 13.9281 13.6694 16.3645 12.0739 15.5883Z"
        fill="#8186FF"
      />
    </svg>
  );
}
