export default function BookSVG() {
  return (
    <svg
      width="86"
      height="66"
      viewBox="0 0 86 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.5095 33.8041L22.6736 16.5822L0.567383 29.3438L30.4033 46.5657L52.5095 33.8041Z"
        fill="#58A9EE"
      />
      <path
        d="M29.8359 39.7318L0.010376 22.5058V30.805L29.8047 48.0269L29.8359 39.7318Z"
        fill="#3B8ED5"
      />
      <path
        d="M52.5095 26.6452L22.6736 9.41913L0 22.5112L29.8359 39.731L52.5095 26.6452Z"
        fill="#58A9EE"
      />
      <path
        d="M49.9073 28.915L49.9218 33.8492L29.9689 45.3741L29.9897 40.4045L49.9073 28.915Z"
        fill="#F1F1F1"
      />
      <path
        d="M52.5178 33.8028V34.9085L29.8047 48.0234L29.8421 46.8824L52.5178 33.8028Z"
        fill="#3B8ED4"
      />
      <path
        d="M52.5094 26.6454V27.855L30.6069 40.5044L30.6443 39.2573L52.5094 26.6454Z"
        fill="#3B8ED4"
      />
      <path
        d="M30.9021 39.1219L30.9146 47.3775L29.8047 48.0197L29.8359 39.7247L30.9021 39.1219Z"
        fill="#3B8ED4"
      />
      <path
        d="M52.5095 24.388L22.6736 7.16199L0.567383 19.9256L30.4033 37.1454L52.5095 24.388Z"
        fill="#FF70C2"
      />
      <path
        d="M29.8359 30.3116L0.010376 13.0856V21.3847L29.8047 38.6066L29.8359 30.3116Z"
        fill="#FF70C2"
      />
      <path
        d="M52.5095 17.226L22.6736 0L0 13.092L29.8359 30.3118L52.5095 17.226Z"
        fill="#FF8ECF"
      />
      <path
        d="M49.9073 19.4958L49.9218 24.4321L29.9689 35.9549L29.9897 30.9854L49.9073 19.4958Z"
        fill="#F1F1F1"
      />
      <path
        d="M52.5178 24.386V25.4917L29.8047 38.6066L29.8421 37.4656L52.5178 24.386Z"
        fill="#FF70C2"
      />
      <path
        d="M52.5094 17.2252V18.4369L30.6069 31.0841L30.6443 29.8371L52.5094 17.2252Z"
        fill="#FF70C2"
      />
      <path
        d="M30.9021 29.7028L30.9146 37.9604L29.8047 38.6006L29.8359 30.3056L30.9021 29.7028Z"
        fill="#FF70C2"
      />
      <path
        d="M61.8063 4.05133V38.5034L39.698 51.2629V16.8129L61.8063 4.05133Z"
        fill="#FF968D"
      />
      <path
        d="M40.8765 19.8657L35.6658 16.879L34.9904 16.4882L40.8765 19.8657Z"
        fill="#E2B779"
      />
      <path
        d="M45.3389 20.7241V55.1678L38.1496 51.0234L38.135 16.6025L45.3389 20.7241Z"
        fill="#E77D74"
      />
      <path
        d="M68.0083 7.63171V42.0838L45.3347 55.1779V20.7279L68.0083 7.63171Z"
        fill="#FF968D"
      />
      <path
        d="M64.7411 8.74953L60.4824 6.26996L40.5294 17.7886L44.8442 20.2557L64.7411 8.74953Z"
        fill="#F1F1F1"
      />
      <path
        d="M61.8125 4.04255L60.8564 3.48761L38.1412 16.6004L39.1472 17.1387L61.8125 4.04255Z"
        fill="#E77D74"
      />
      <path
        d="M68.0083 7.63186L66.9691 7.02496L45.0541 19.6701L46.1494 20.2604L68.0083 7.63186Z"
        fill="#E77D74"
      />
      <path
        d="M46.399 20.1046L39.2554 15.9664L38.1455 16.6066L45.3431 20.7281L46.399 20.1046Z"
        fill="#E77D74"
      />
      <path
        d="M70.6106 9.12256V43.5726L48.5044 56.3341V21.8862L70.6106 9.12256Z"
        fill="#A10AFF"
      />
      <path
        d="M49.6745 24.9354L44.4785 21.957L43.803 21.5662L49.6745 24.9354Z"
        fill="#E2B779"
      />
      <path
        d="M54.1432 25.7953V60.237L46.9539 56.0947L46.9393 21.6738L54.1432 25.7953Z"
        fill="#C077FF"
      />
      <path
        d="M76.8125 12.7029V47.1529L54.1389 60.2471V25.7991L76.8125 12.7029Z"
        fill="#6A17FF"
      />
      <path
        d="M73.5453 13.8208L69.2783 11.3412L49.3253 22.8599L53.6401 25.3249L73.5453 13.8208Z"
        fill="#F1F1F1"
      />
      <path
        d="M70.6168 9.11378L69.6607 8.55884L46.9456 21.6717L47.9515 22.21L70.6168 9.11378Z"
        fill="#58A9EE"
      />
      <path
        d="M76.8126 12.7029L75.7734 12.0939L53.8584 24.7412L54.9537 25.3294L76.8126 12.7029Z"
        fill="#58A9EE"
      />
      <path
        d="M55.2032 25.1758L48.0596 21.0376L46.9497 21.6778L54.1473 25.7972L55.2032 25.1758Z"
        fill="#58A9EE"
      />
      <path
        d="M79.7993 14.4303V48.8803L57.6931 61.6419V27.1939L79.7993 14.4303Z"
        fill="#FFCF2D"
      />
      <path
        d="M63.3319 31.102V65.5437L56.1426 61.4014L56.1281 26.9805L63.3319 31.102Z"
        fill="#CD9E00"
      />
      <path
        d="M86.0014 18.0073V52.4615L63.3278 65.5556V31.1077L86.0014 18.0073Z"
        fill="#FFCF2D"
      />
      <path
        d="M82.734 19.1297L78.467 16.6501L58.514 28.1688L62.8289 30.6338L82.734 19.1297Z"
        fill="#F1F1F1"
      />
      <path
        d="M79.8118 14.4215L78.8557 13.8666L56.1343 26.9794L57.1402 27.5177L79.8118 14.4215Z"
        fill="#CD9E00"
      />
      <path
        d="M86.0013 18.0073L84.9621 17.3983L63.0554 30.0456L64.1528 30.6338L86.0013 18.0073Z"
        fill="#CD9E00"
      />
      <path
        d="M64.3919 30.4779L57.2483 26.3398L56.1384 26.9799L63.336 31.1015L64.3919 30.4779Z"
        fill="#CD9E00"
      />
    </svg>
  );
}
