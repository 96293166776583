import { Avatar, Button, Card, Collapse, Row } from "antd";
import React, { useState } from "react";
import EmptyResult from "../../component/Empty";
import { IComments } from "../../../type";
import api from "../../config/api";
import { UserOutlined } from "@ant-design/icons";
import Meta from "antd/es/card/Meta";
import Loader from "../../component/loader";
const RenderComments = ({
  comments,
  answerId,
}: {
  comments: IComments;
  answerId: number;
}) => {
  const totalPage = comments.last_page;
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(comments.current_page);
  const [resData, setResData] = useState(comments?.data);

  const loadComments = async () => {
    if (page >= totalPage) return;
    setLoadingMore(true);
    try {
      setPage(page + 1);
      const { data } = await api.get(
        `answers/${answerId}/comments?page=${page + 1}`
      );
      setResData([...resData, ...data.data]);
      setLoadingMore(false);
    } catch (error) {
      setLoadingMore(false);
    }
  };

  return (
    <>
      {resData?.map((item2) => (
        <Card style={{ marginBottom: "2%" }}>
          <Meta
            avatar={
              <Avatar
                src={
                  <div
                    style={{
                      backgroundColor: "#FFEAEA",
                      textAlign: "center",
                      color: "black",
                      fontWeight: "600",
                    }}
                  >
                    {item2?.reply_user?.charAt(0)?.toUpperCase()}
                  </div>
                }
                size={45}
              />
            }
            title={item2?.reply_user}
            description={item2?.reply}
          />
        </Card>
      ))}
      {page < totalPage && (
        <Button
          disabled={loadingMore}
          onClick={loadComments}
          loading={loadingMore}
          style={{ float: "right" }}
        >
          Load More Comments
        </Button>
      )}
    </>
  );
};

export default RenderComments;
