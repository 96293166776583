import { useMediaQuery } from "react-responsive"
import useSize from "./size"

export default function ({ queries }) {
    const sizes = queries.map((query) => {
        return useMediaQuery({
            query,
        })
    })
    return sizes
}