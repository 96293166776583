import { Button, Col, Form, Input, Row, Select, Typography } from "antd";
import React, { BaseSyntheticEvent, useEffect, useRef, useState } from "react";
import api, { authorizeApi } from "../config/api";
import { Link } from "@tanstack/react-router";
import { useAuthStore } from "../store/auth";
import HomeScreenSlider from "./HomeScreenSlider";
import showNotification from "../utils/notify";
import LogoContainer from "./LogoContainer";
import { State, City } from "country-state-city";

const RegisterForm = () => {
  const [loading, setLoading] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [message, setMessage] = useState();
  const [userValue, setUserValue] = useState<any>();
  const [resendOTP, setResendOTP] = useState(false);
  const [otpMessage, setOtpMessage] = useState(false);
  const [stateID, setStateID] = useState<string>("DL");
  const [allCity, setAllCity] = useState<string>();

  const [otp, setOTP] = useState(["", "", "", ""]);
  const inputsRef = useRef([]);

  const ALLSTATES = State.getStatesOfCountry("IN");

  useEffect(() => {
    setAllCity(City.getCitiesOfState("IN", stateID));
  }, [stateID]);

  const { login } = useAuthStore();

  const onFinish = async (values: any) => {
    setUserValue(values);

    try {
      setLoading(true);
      const { data } = await api.post<any, any>("/register", values);

      if (data?.r_type === "verify_phone") {
        setIsVerify(true);
        setLoading(false);
        setMessage(data?.message);
      }
      getOtpTimer(60);
      setOtpMessage(true);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleOTPChange = (index, value) => {
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);

    if (value !== "" && index < otp.length - 1) {
      inputsRef.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      const newOtp = [...otp];
      newOtp[index - 1] = "";
      setOTP(newOtp);
      inputsRef.current[index - 1].focus();
    } else if (
      e.key === "Delete" &&
      index < otp.length - 1 &&
      otp[index] === ""
    ) {
      const newOtp = [...otp];
      newOtp[index + 1] = "";
      setOTP(newOtp);
      inputsRef.current[index + 1].focus();
    } else if (e.key === "ArrowLeft" && index > 0) {
      inputsRef.current[index - 1].focus();
    } else if (e.key === "ArrowRight" && index < otp.length - 1) {
      inputsRef.current[index + 1].focus();
    }
  };

  const onVerify = async (newValues: string) => {
    const verifyPayload = {
      phone: newValues?.phone,
      otp: otp.join(""),
    };

    if (otp.join("").length < 4) {
      showNotification("error", "Please fill all the fields", 3);
    } else {
      try {
        setLoading(true);
        const { data } = await api.post(`/verify-phone`, verifyPayload);
        if (data?.status === true) {
          handleLogin(userValue);
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const handleLogin = async (loginValues: any) => {
    delete loginValues.c_password;
    delete loginValues.email;
    delete loginValues.name;

    try {
      setLoading(true);
      const { data } = await api.post(`/login`, loginValues);

      if (data?.token) {
        window.location.pathname = "/home";
        login({ user: data.user, token: data.token });
        authorizeApi(data.token);
        setLoading(false);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getOtpTimer = (timer) => {
    const interval = setInterval(() => {
      timer = timer - 1;
      const ele = document.getElementById("timer");
      if (ele) {
        ele.innerText = timer;
      }
      if (timer == 0) {
        setResendOTP(true);
        setOtpMessage(false);
        clearInterval(interval);
      }
    }, 1000);
  };

  const handleResendOTP = async (otpValues) => {
    const otpPayload = { phone: otpValues?.phone, type: "vphone" };

    try {
      setLoading(true);
      const { data } = await api.post(`/generate-otp`, otpPayload);
      getOtpTimer(60);
      setLoading(false);

      if (data?.status === true) {
        setOtpMessage(true);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const filterOption = (input, option) => {
    return option.name.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <div className="user-form-wrapper">
      <Row align="middle" justify="center" style={{ height: "100%" }}>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={0}
          xs={0}
          style={{ backgroundColor: "#EEF6FF" }}
          className=" wh-100"
        >
          <HomeScreenSlider />
        </Col>
        {isVerify ? (
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={24}
            xs={24}
            className="bg-white wh-100"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="responsive-card-width">
              <Typography.Title level={4}>Phone Verification</Typography.Title>
              <Typography.Title level={5}>
                {message}. Enter 4 digit OTP that you have received.
              </Typography.Title>
              <Form
                name="verify_form"
                initialValues={{
                  remember: true,
                }}
                size="large"
                onFinish={onVerify}
                layout="vertical"
                style={{ height: "100%" }}
              >
                <Form.Item
                  hidden
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Phone!",
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Enter Valid Phone Number",
                    },
                  ]}
                  label="Phone Number"
                  initialValue={userValue?.phone}
                >
                  <Input
                    disabled={true}
                    maxLength={10}
                    onInput={(e: BaseSyntheticEvent) =>
                      (e.target.value = e.target.value.slice(0, 10))
                    }
                    placeholder="Phone"
                    type="number"
                  />
                </Form.Item>

                <Form.Item
                  name="otp"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter OTP in required field!",
                    },
                  ]}
                >
                  <div style={{ textAlign: "center" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {otp.map((digit, index) => (
                        <Input
                          key={index}
                          ref={(el) => (inputsRef.current[index] = el)}
                          style={{
                            width: "3em",
                            height: "3em",
                            marginRight: "1.5em",
                            textAlign: "center",
                          }}
                          maxLength={1}
                          value={digit}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const numericValue = inputValue.replace(/\D/g, "");
                            handleOTPChange(index, numericValue);
                          }}
                          onKeyDown={(e) => handleKeyDown(index, e)}
                        />
                      ))}
                    </div>
                  </div>
                </Form.Item>

                {otpMessage && (
                  <p
                    style={{
                      color: "red",
                      textAlign: "center",
                      fontSize: "1.2em",
                    }}
                  >
                    <span id="timer"></span>
                  </p>
                )}

                <Form.Item>
                  <Button
                    loading={loading}
                    disabled={loading}
                    type="primary"
                    htmlType="submit"
                    className="user-form-button"
                    size="large"
                  >
                    Verify
                  </Button>
                </Form.Item>
              </Form>

              {resendOTP && (
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "1.2em",
                    color: "#7B8389",
                  }}
                >
                  If you didn’t receive OTP!{" "}
                  <span
                    style={{
                      color: "#F2451C",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleResendOTP(userValue);
                      setResendOTP(false);
                    }}
                  >
                    Resend
                  </span>
                </p>
              )}
            </div>
          </Col>
        ) : (
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={24}
            xs={24}
            className="bg-white wh-100"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="responsive-card-width">
              <LogoContainer />
              <Typography.Title level={4}>Registration here</Typography.Title>
              <Form size="large" layout="vertical" onFinish={onFinish}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your full name!",
                    },
                  ]}
                  style={{ marginBottom: "0%" }}
                >
                  <Input placeholder="Please enter your name" />
                </Form.Item>

                <Row>
                  <Col span={11}>
                    <Form.Item
                      name="email"
                      label="Email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your email!",
                        },
                        {
                          pattern:
                            /^[\w-.]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/,
                          message: "Enter valid email.",
                        },
                      ]}
                      style={{ marginBottom: "0%" }}
                    >
                      <Input placeholder="Please enter your email" />
                    </Form.Item>
                  </Col>
                  <Col span={12} offset={1}>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Phone!",
                        },
                        {
                          pattern: /^[0-9]{10}$/,
                          message: "Enter Valid Phone Number",
                        },
                      ]}
                      label="Phone Number"
                      style={{ marginBottom: "0%" }}
                    >
                      <Input
                        maxLength={10}
                        onInput={(e: BaseSyntheticEvent) =>
                          (e.target.value = e.target.value.slice(0, 10))
                        }
                        placeholder="Phone"
                        type="number"
                        onKeyDown={(e) => {
                          if (
                            e.key === "e" ||
                            e.key === "ArrowUp" ||
                            e.key === "ArrowDown"
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={11}>
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                        {
                          pattern:
                            /^(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                          message:
                            "Password must be 8 characters including one uppercase,lowercase,special character and alphanumeric characters.",
                        },
                      ]}
                      style={{ marginBottom: "0%" }}
                    >
                      <Input.Password placeholder="Please enter your new password" />
                    </Form.Item>
                  </Col>
                  <Col span={12} offset={1}>
                    <Form.Item
                      name="c_password"
                      label="Confirm New Password"
                      dependencies={["password"]}
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The password that you entered does not match!"
                              )
                            );
                          },
                        }),
                      ]}
                      style={{ marginBottom: "0%" }}
                    >
                      <Input.Password placeholder="Please enter your new password again" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={11}>
                    <Form.Item label="State" name="state">
                      <Select
                        size="large"
                        onSelect={(value, option) => {
                          setStateID(option?.isoCode);
                        }}
                        id="state"
                        filterOption={filterOption}
                        fieldNames={{ label: "name", value: "name" }}
                        showSearch
                        options={ALLSTATES}
                        placeholder="Select State"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} offset={1}>
                    <Form.Item label="City" name="city">
                      <Select
                        size="large"
                        id="city"
                        fieldNames={{ label: "name", value: "name" }}
                        filterOption={filterOption}
                        showSearch
                        options={allCity}
                        placeholder="Select City"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item>
                  <Button
                    loading={loading}
                    disabled={loading}
                    type="primary"
                    htmlType="submit"
                    size="large"
                    className="user-form-button"
                  >
                    Register
                  </Button>
                </Form.Item>
              </Form>
              <div className="text-center">
                Have an Account? <Link to="/">Log In</Link>
              </div>

              <div className="text-center">
                By clicking Sign Up, you agree to our{" "}
                <a
                  href="https://khanglobalstudies.com/term-and-condition"
                  rel="noreferrer"
                  target="_blank"
                >
                  Terms & Conditions and Privacy Policy.
                </a>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default RegisterForm;
