export default function SubmissionCountIcon() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.69577 10.4351H1.82613C1.24982 10.4351 0.782623 9.96792 0.782623 9.39159V3.13056C0.782623 2.55424 1.24982 2.08705 1.82613 2.08705H4.69577C5.3358 2.08705 5.90404 2.39437 6.26103 2.86949C6.61801 2.39437 7.18625 2.08705 7.82629 2.08705H10.6959C11.2723 2.08705 11.7394 2.55424 11.7394 3.13056V9.39159C11.7394 9.96792 11.2723 10.4351 10.6959 10.4351H7.82629C7.3613 10.4351 6.95945 10.7054 6.76937 11.0975C6.67039 11.3016 6.48783 11.4786 6.26103 11.4786C6.03422 11.4786 5.85166 11.3016 5.75268 11.0975C5.56261 10.7054 5.16076 10.4351 4.69577 10.4351ZM1.56525 3.13056C1.56525 2.98648 1.68205 2.86968 1.82613 2.86968H4.69577C5.3441 2.86968 5.86971 3.39527 5.86971 4.04362V10.0436C5.54273 9.79804 5.13624 9.65247 4.69577 9.65247H1.82613C1.68205 9.65247 1.56525 9.53565 1.56525 9.39159V3.13056ZM7.82629 2.86968C7.17796 2.86968 6.65234 3.39527 6.65234 4.04362V10.0436C6.97932 9.79804 7.38582 9.65247 7.82629 9.65247H10.6959C10.84 9.65247 10.9568 9.53565 10.9568 9.39159V3.13056C10.9568 2.98648 10.84 2.86968 10.6959 2.86968H7.82629Z"
        fill="black"
      />
    </svg>
  );
}
