export default function DoubtIcon() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 20C15.7467 20 20 15.7467 20 10.5C20 5.25329 15.7467 1 10.5 1C5.25329 1 1 5.25329 1 10.5C1 15.7467 5.25329 20 10.5 20Z"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.30762 8.30779C8.30762 7.87419 8.43619 7.45033 8.67709 7.08981C8.91798 6.72928 9.26037 6.44829 9.66097 6.28236C10.0616 6.11643 10.5024 6.07301 10.9276 6.1576C11.3529 6.24219 11.7435 6.45099 12.0501 6.75759C12.3567 7.06419 12.5655 7.45482 12.6501 7.88009C12.7347 8.30536 12.6913 8.74616 12.5254 9.14675C12.3594 9.54734 12.0784 9.88973 11.7179 10.1306C11.3574 10.3715 10.9335 10.5001 10.4999 10.5001V11.9616"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5002 15.6153C10.2984 15.6153 10.1348 15.4517 10.1348 15.2499C10.1348 15.0481 10.2984 14.8845 10.5002 14.8845"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5 15.6153C10.7018 15.6153 10.8654 15.4517 10.8654 15.2499C10.8654 15.0481 10.7018 14.8845 10.5 14.8845"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
