import React from "react";
import { useMediaQuery } from "react-responsive";

const DataCard = ({ title, value, valueStyle, prefix, icon2 }) => {
  const isSmallScreen = useMediaQuery({
    query: "(max-width: 425px)",
  });

  return (
    <div>
      <div
        style={{
          width: "40px",
          height: "40px",
          backgroundColor: "white",
          margin: isSmallScreen ? "auto" : "",
          borderRadius: "100%",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {prefix}
      </div>
      <p
        style={{
          marginTop: isSmallScreen ? "5%" : "3%",
          fontWeight: "500",
          textAlign: isSmallScreen ? "center" : "start",
          fontSize: isSmallScreen ? "1em" : "1.2em",
        }}
      >
        {title}
      </p>
      <div
        style={{
          width: "60px",
          height: "35px",
          backgroundColor: "white",
          borderRadius: "5px",
          display: "flex",
          justifyContent: "space-between",
          padding: 13,
          alignItems: "center",
          fontSize: "1.2em",
        }}
      >
        {icon2}
        {value}
      </div>
    </div>
  );
};

export default DataCard;
