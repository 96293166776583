import React from "react";
import EmptyResult from "../../component/Empty";
import CustomCard from "../../component/GlobalCard";
import { Col, Row, Tag } from "antd";
import { Link } from "@tanstack/react-router";
import Shimmer from "../../component/Shimmer";
import CourseTag from "./CourseTag";
import { useMediaQuery } from "react-responsive";

const RecentlyAdded = ({ loading, data }) => {
  const isSmallScreen = useMediaQuery({
    query: "(max-width: 426px)",
  });

  const isMediumScreen = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const isLargeScreen = useMediaQuery({
    query: "(max-width: 1025px)",
  });

  const isExtraLageScreen = useMediaQuery({
    query: "(max-width: 1441px)",
  });
  const clickHandler = () => {
    return;
  };
  return (
    <div
      style={{
        marginTop: isSmallScreen
          ? "4em"
          : isMediumScreen
          ? "4em"
          : isLargeScreen
          ? "4em"
          : isExtraLageScreen
          ? "4em"
          : "4em",
      }}
    >
      <Row gutter={[16, 8]}>
        {data?.length ? (
          data?.map((elem) => {
            return (
              <Col
                key={elem?.id}
                xs={24}
                sm={12}
                md={8}
                lg={6}
                xl={6}
                style={{ marginTop: 10 }}
              >
                <Link to={`/courses/${elem.slug}/play`}>
                  <CustomCard
                    title={elem.title}
                    type="cover"
                    cover={{
                      url: elem.image.small as string | "/kgs.jpg",
                      height: "100%",
                      width: "100%",
                    }}
                    desc={
                      <>
                        <CourseTag cat_id={elem?.category_id} />
                      </>
                    }
                  />
                </Link>
              </Col>
            );
          })
        ) : (
          <EmptyResult
            title={
              "Either You haven't purchased any courses or there are no recently added course."
            }
            showDes={false}
            status={"404"}
          />
        )}
      </Row>
    </div>
  );
};

export default RecentlyAdded;
