export default function Logout() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1538 15.6154V18.5385C14.1538 18.9261 13.9999 19.2978 13.7258 19.5719C13.4517 19.846 13.0799 20 12.6923 20H2.46154C2.07391 20 1.70217 19.846 1.42807 19.5719C1.15398 19.2978 1 18.9261 1 18.5385V2.46154C1 2.07391 1.15398 1.70217 1.42807 1.42807C1.70217 1.15398 2.07391 1 2.46154 1H12.6923C13.0799 1 13.4517 1.15398 13.7258 1.42807C13.9999 1.70217 14.1538 2.07391 14.1538 2.46154V5.38462"
        stroke="#000001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.76953 10.5H20.0003"
        stroke="#000001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.0771 7.5769L20.0002 10.5L17.0771 13.4231"
        stroke="#000001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
