import { Card, Col, Collapse, Row } from "antd";
import React from "react";

const FAQ = ({ testSeries }) => {
  return (
    <Row>
      <Col xl={18} lg={18} md={24} sm={24} xs={24}>
        {testSeries?.details?.faqs?.length > 0 ? (
          <>
            {testSeries?.details?.faqs?.map((item) => (
              <Card
                style={{
                  marginBottom: "2%",
                  boxShadow:
                    "0px 12px 24px 0px #14142B0A, 0px -2px 4px 0px #14142B05, 0px 3px 14px 0px #4A3AFF08",
                  border: "none",
                  borderRadius: "1em",
                }}
                size="small"
              >
                <Collapse
                  ghost
                  expandIconPosition={"end"}
                  items={[
                    {
                      key: item.id,
                      label: <b>{item?.q}</b>,
                      children: item?.a,
                    },
                  ]}
                />
              </Card>
            ))}
          </>
        ) : (
          <Card style={{ fontWeight: "bold" }}>
            FAQs not available right now!
          </Card>
        )}
      </Col>
    </Row>
  );
};

export default FAQ;
