import React from "react";
import { Row, Card, Col, Tag } from "antd";
import { useApi, useMount } from "../../hooks";
import styled from "styled-components";
import { LinkButton } from "./AskDoubtCard";
import Shimmer from "../Shimmer";
import { usePublicStore } from "../../store/usePublicStore";
import { recommendStore } from "../../store/recommendedCourseStore";
interface ICourse {
  img: string;
  title: string;
  data: any[];
}
const RecommendedCourse = () => {
  const { courses, rcIsLoading } = recommendStore();

  return (
    <>
      <h3 style={{ marginBottom: "0px" }}>Recommended Courses</h3>
      <Card
        size="small"
        className="video-content-container"
        style={{ height: "50vh" }}
      >
        <Shimmer loading={rcIsLoading} count={5}>
          {courses?.slice(0, 5).map((item) => (
            <a
              href={`https://khanglobalstudies.com/courses/${item?.slug}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Card className="recommended-card" size="small">
                <Row align="middle">
                  <Col lg={6} xl={6} md={9} sm={8} xs={24}>
                    <img
                      alt="thumbnail"
                      src={item?.image?.medium || "/kgs.jpg"}
                      width={"100%"}
                      style={{ borderRadius: "0.6em" }}
                    />
                  </Col>
                  <Col lg={14} xl={14} md={11} sm={10} xs={24} offset={1}>
                    <RecommededTitle>{item?.title}</RecommededTitle>
                    <RecommededSubtitle>
                      Live + Recorded | Hindi / English
                    </RecommededSubtitle>
                  </Col>
                  <Col lg={3} xl={3} md={3} sm={4} xs={0}>
                    <TagButton>View</TagButton>
                  </Col>
                </Row>
              </Card>
            </a>
          ))}
        </Shimmer>
      </Card>
    </>
  );
};

const RecommededTitle = styled.div`
  font-weight: 600;
  margin-bottom: 0;
`;
const RecommededSubtitle = styled.div`
  font-weight: 300;
  color: #9e9e9e;
  margin-bottom: 0;
  font-size: 12px;
`;

const TagButton = styled(Tag)`
  color: #272835;
  font-weight: 500;
  border: none;
  font-size: 0.8em;
  background: #e8eff6;
  padding: 0em 1.2em 0em 1.2em;
`;

export default RecommendedCourse;
