import React, { useEffect, useState } from "react";
import api from "../../config/api";
import EmptyResult from "../../component/Empty";
import parse from "html-react-parser";
import { Card, Drawer, Table, Tag } from "antd";
import { IAnswerSubmission } from "../../../type";
import { IMySubmission } from "../../../type";
import CustomModal from "../../component/common/CustomModal";
import { styled } from "styled-components";
import MyWriting from "./MyWriting";
import { SkeletonTable } from "../../component/Shimmer";
import { onlyDateParse } from "../../helpers/help";
import { useMount } from "../../hooks";
import { useMediaQuery } from "react-responsive";

const AnswerWritingSubmissions = () => {
  const [submission, setSubmission] = useState<IAnswerSubmission[]>([]);
  const [filteredSubmission, setFilteredSubmission] = useState<
    IAnswerSubmission[]
  >([]);

  const [loading, setLoading] = useState(true);
  const [modalAnswer, setModalAnswer] = useState("");
  const [isModelOpen, setModelOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState();
  const [perPage, setPerPage] = useState();
  const [myAnswer, setMyAnswer] = useState<IMySubmission[]>([]);
  const [selectedDate, setSelectedDate] = useState();
  const [filter, setFilter] = useState<string | null>(null);

  const [open, setOpen] = useState(false);
  const [resource, setResource] = useState();

  useMount(() => {
    if (!filter) return;
    const filteredData = submission?.filter(
      (item) => onlyDateParse(item.created_at) === filter
    );
    setFilteredSubmission(filteredData);
  }, [filter]);

  useEffect(() => {
    const fetchSubmission = async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`/user/submissions?page=${page}`);
        setTotal(data?.total);
        setPerPage(data?.per_page);
        setSubmission(data?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchSubmission();
  }, [page]);

  const openModal = (answer: string) => {
    setModelOpen(true);
    setModalAnswer(answer);
  };

  const columns = [
    {
      title: <TableTitle>Attempted Answer Writing</TableTitle>,
      dataIndex: "0",
      key: "0",
      render: (_, { question }) => {
        {
          return (
            <TableSubTitle>
              Question <br />
              {parse(question)}
            </TableSubTitle>
          );
        }
      },
      width: 600,
    },
    {
      title: <TableTitle>Attempt Date</TableTitle>,
      dataIndex: "0",
      key: "0",
      render: (_, { created_at }) => {
        {
          return <TableSubTitle>{onlyDateParse(created_at)}</TableSubTitle>;
        }
      },
      width: 150,
    },
    {
      title: <TableTitle>Action</TableTitle>,
      dataIndex: "1",
      key: "1",
      render: (_, { question_id }) => {
        {
          return (
            <>
              <TitleTag
                onClick={() => {
                  showDrawer(<MyWriting id={question_id} />);
                }}
              >
                View my submission
              </TitleTag>
            </>
          );
        }
      },
      width: 150,
    },
    {
      title: <TableTitle>Action</TableTitle>,
      dataIndex: "2",
      key: "2",
      render: (_, { answer }) => {
        {
          return (
            <span>
              {answer ? (
                <TitleTag onClick={() => openModal(parse(answer))}>
                  View Answer
                </TitleTag>
              ) : (
                <DisabledTitleTag>View Answer</DisabledTitleTag>
              )}
            </span>
          );
        }
      },
      width: 120,
    },
  ];

  const showDrawer = (item) => {
    setOpen(true);
    setResource(item);
  };

  const onClose = () => {
    setOpen(false);
  };

  const isSmallScreen = useMediaQuery({
    query: "(max-width: 426px)",
  });

  const isMediumScreen = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const isLargeScreen = useMediaQuery({
    query: "(max-width: 1025px)",
  });

  const isExtraLageScreen = useMediaQuery({
    query: "(max-width: 1441px)",
  });

  return (
    <div
      style={{
        marginTop: isSmallScreen
          ? "4em"
          : isMediumScreen
          ? "4em"
          : isLargeScreen
          ? "4em"
          : isExtraLageScreen
          ? "4em"
          : "4em",
      }}
    >
      {loading ||
      (filter && filteredSubmission.length) ||
      submission?.length ? (
        <>
          <Card>
            <SkeletonTable loading={loading}>
              <Table
                columns={columns}
                dataSource={filter ? filteredSubmission : submission}
                scroll={{ x: 700, y: 500 }}
                pagination={{
                  total: total,
                  current: page,
                  pageSize: perPage,
                  showSizeChanger: false,
                  onChange: (page) => setPage(page),
                }}
              />
            </SkeletonTable>
          </Card>
        </>
      ) : (
        <EmptyResult
          title={"No Submission Yet!"}
          showDes={false}
          status={"404"}
        />
      )}
      <CustomModal
        title={<TitleView>{"View Answer"}</TitleView>}
        className={"modal-modal"}
        height="50vh"
        ConfirmationText={"Close"}
        onDone={() => setModelOpen(false)}
        open={isModelOpen}
        width={"80%"}
        style={"modal-wrapper-answer"}
        hideModal={() => setModelOpen(false)}
        message={modalAnswer}
      />

      <Drawer title="View Answer" onClose={onClose} open={open} width="70%">
        {resource}
      </Drawer>
    </div>
  );
};

const TitleView = styled.div`
  color: #2e3192;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 0.625rem;
`;

const TitleTag = styled(Tag)`
  background-color: #e2f1ff;
  color: #113d66;
  border: none;
  padding: 0.6em;
  border-radius: 0.8em;
  font-weight: 500;
  font-size: 1em;
  cursor: pointer;
`;

const TableTitle = styled.p`
  font-weight: 600;
  margin-bottom: 0%;
  padding: 0.8em;
  font-size: 1.2em;
`;

const TableSubTitle = styled.p`
  font-weight: 500;
  margin-bottom: 0%;
  padding: 0.5em 1em 0.5em 1em;
  font-size: 1em;
`;

const DisabledTitleTag = styled(Tag)`
  background-color: #ededed;
  color: #434343;
  border: none;
  padding: 0.6em;
  border-radius: 0.8em;
  font-weight: 500;
  font-size: 1em;
  cursor: not-allowed;
`;

export default AnswerWritingSubmissions;
