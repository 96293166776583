import React, { useEffect } from "react";
import EmptyResult from "../../component/Empty";
import CustomCard from "../../component/GlobalCard";
import { Link } from "@tanstack/react-router";
import { Col, Row, Tag } from "antd";
import CourseTag from "./CourseTag";
import { useMediaQuery } from "react-responsive";
import Shimmer from "../../component/Shimmer";

const AllCourse = ({ loading, data }) => {
  const isSmallScreen = useMediaQuery({
    query: "(max-width: 426px)",
  });

  const isMediumScreen = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const isLargeScreen = useMediaQuery({
    query: "(max-width: 1025px)",
  });

  const isExtraLageScreen = useMediaQuery({
    query: "(max-width: 1441px)",
  });

  return (
    <div
      style={{
        marginTop: isSmallScreen
          ? "4em"
          : isMediumScreen
          ? "4em"
          : isLargeScreen
          ? "4em"
          : isExtraLageScreen
          ? "4em"
          : "4em",
      }}
    >
      <Shimmer type="horizantal" loading={loading} count={4}>
        <Row
          gutter={[16, 8]}
          style={{
            marginTop: isSmallScreen
              ? "4em"
              : isMediumScreen
              ? "4em"
              : isLargeScreen
              ? "4em"
              : isExtraLageScreen
              ? "4em"
              : "4em",
          }}
        >
          {data?.length ? (
            data?.map((elem) => {
              return (
                <Col
                  key={elem?.id}
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                  style={{ marginTop: 10 }}
                >
                  <Link to={`/courses/${elem.slug}/play`}>
                    <CustomCard
                      title={elem.title}
                      type="cover"
                      cover={{
                        url: (elem?.image?.small as string) || "/kgs.jpg",
                        height: "100%",
                        width: "100%",
                      }}
                      desc={
                        <>
                          <CourseTag cat_id={elem?.category_id} />
                        </>
                      }
                    />
                  </Link>
                </Col>
              );
            })
          ) : (
            <EmptyResult
              title={"You have not purchase any Course!"}
              showDes={false}
              status={"404"}
            />
          )}
        </Row>
      </Shimmer>
    </div>
  );
};
export default AllCourse;
