export default function StoreIcon2() {
  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.6885 3.92294C22.4122 3.59814 22.0662 3.33691 21.6752 3.15781C21.2841 2.9787 20.8576 2.88612 20.426 2.88664H4.54184L4.50058 2.5489C4.41614 1.84689 4.07163 1.19962 3.53238 0.729817C2.99312 0.260012 2.2966 0.000328878 1.57487 0L1.35677 0C1.09621 0 0.846322 0.101376 0.662079 0.281826C0.477836 0.462276 0.374329 0.707018 0.374329 0.962213C0.374329 1.21741 0.477836 1.46215 0.662079 1.6426C0.846322 1.82305 1.09621 1.92443 1.35677 1.92443H1.57487C1.8155 1.92446 2.04776 2.01098 2.22758 2.16759C2.4074 2.3242 2.52228 2.54 2.55044 2.77406L3.90228 14.032C4.04262 15.2028 4.61698 16.2824 5.51634 17.0658C6.41569 17.8493 7.57745 18.2821 8.78108 18.2821H19.0407C19.3013 18.2821 19.5512 18.1807 19.7354 18.0002C19.9196 17.8198 20.0232 17.575 20.0232 17.3198C20.0232 17.0646 19.9196 16.8199 19.7354 16.6395C19.5512 16.459 19.3013 16.3576 19.0407 16.3576H8.78108C8.173 16.356 7.58034 16.1701 7.08434 15.8256C6.58834 15.4811 6.21328 14.9947 6.01059 14.4332H17.7213C18.873 14.4333 19.9881 14.037 20.8718 13.3136C21.7555 12.5902 22.3516 11.5857 22.5559 10.4756L23.3271 6.28614C23.4041 5.87047 23.3868 5.4433 23.2764 5.03491C23.1661 4.62652 22.9654 4.2469 22.6885 3.92294ZM21.3986 5.94455L20.6264 10.134C20.5037 10.8008 20.1454 11.4041 19.6143 11.8383C19.0832 12.2725 18.4131 12.5098 17.7213 12.5088H5.69818L4.7737 4.81107H20.426C20.5703 4.81022 20.713 4.84053 20.844 4.89984C20.975 4.95914 21.0911 5.04599 21.1839 5.1542C21.2768 5.26241 21.3442 5.38933 21.3813 5.52593C21.4184 5.66253 21.4243 5.80546 21.3986 5.94455Z"
        fill="#2E3192"
      />
      <path
        d="M7.18352 23C8.26992 23 9.15062 22.1418 9.15062 21.0833C9.15062 20.0247 8.26992 19.1666 7.18352 19.1666C6.09711 19.1666 5.21641 20.0247 5.21641 21.0833C5.21641 22.1418 6.09711 23 7.18352 23Z"
        fill="#2E3192"
      />
      <path
        d="M17.1704 23C18.2568 23 19.1375 22.1418 19.1375 21.0833C19.1375 20.0247 18.2568 19.1666 17.1704 19.1666C16.084 19.1666 15.2033 20.0247 15.2033 21.0833C15.2033 22.1418 16.084 23 17.1704 23Z"
        fill="#2E3192"
      />
    </svg>
  );
}
