import { Button, Card } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import CustomModal from "../common/CustomModal";
import DoubtCard from "../../pages/doubts/DoubtCard";
const AskDoubtCard = ({ lesson, cId, url }) => {
  const [isModelOpen, setModelOpen] = useState(false);
  const [resource, setResource] = useState(null);

  const openModal = (content) => {
    setModelOpen(true);
    setResource(content);
  };

  const hideModal = () => {
    setModelOpen(false);
    setResource(null);
  };

  return (
    <>
      <DoubtCards className="doubtCard">
        <p style={{ marginBottom: "2%" }}>
          Happy to Solve Your Academic Problems!
        </p>
        <LinkButton
          onClick={() => {
            openModal(<DoubtCard lesson={lesson} cId={cId} url={url}/>);
          }}
          style={{ color: "#4925E9" }}
        >
          Ask Doubts
        </LinkButton>
      </DoubtCards>
      <CustomModal
        height="44em"
        ConfirmationText="Close"
        onDone={hideModal}
        open={isModelOpen}
        width={"75%"}
        hideModal={hideModal}
        message={resource}
      />
    </>
  );
};

const DoubtCards = styled(Card)`
  background: linear-gradient(107.91deg, #eaabf0 7.37%, #4623e9 95.19%);
  color: white;
  border-radius: 20px;
  text-align: center;
  font-weight: 600;
`;

export const LinkButton = styled(Button)`
  border-radius: 20px;
  color: #272835;
  font-weight: 600;
  border: none;
  font-size: 1em;
  background: #ffffff;
`;
export default AskDoubtCard;
