import { Card } from "antd";
import React, { useState } from "react";
import { BottomContaner } from "../../component/GlobalCard";
import { TestContainer } from "../quiz/AllQuiz";
import parse from "html-react-parser";
import AttemptAnswerWriting from "./AttemptAnswerWriting";
import CustomModal from "../../component/common/CustomModal";

const ResponseQue = ({ item, index }) => {
  const [isModelOpen, setModelOpen] = useState(false);
  const [content, setContent] = useState();
  const openModal = (content) => {
    setModelOpen(true);
    setContent(content);
  };
  return (
    <>
      <Card hoverable style={{ marginBottom: "1%" }} key={index}>
        <BottomContaner>English</BottomContaner>
        <BottomContaner>Hindi</BottomContaner>
        <br />
        <br />
        <TestContainer>{parse(item?.q)}</TestContainer>

        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <BottomContaner
            style={{
              borderRadius: "4px",
              background: "#E2F1FF",
              color: "#113D66",
              border: "none",
              cursor: "pointer",
            }}
            onClick={() => {
              openModal(
                <AttemptAnswerWriting id={item?.id} que={parse(item?.q)} />
              );
            }}
          >
            Attempt Answer Writing
          </BottomContaner>

          {item?.a && (
            <BottomContaner
              onClick={() => openModal(parse(item?.a))}
              style={{
                borderRadius: "4px",
                background: "#E2F1FF",
                color: "#113D66",
                border: "none",
                cursor: "pointer",
              }}
            >
              View Answer
            </BottomContaner>
          )}
        </div>
      </Card>

      <CustomModal
        height="80vh"
        ConfirmationText="Close"
        onDone={() => setModelOpen(false)}
        open={isModelOpen}
        width={"80%"}
        style={"modal-wrapper-answer"}
        hideModal={() => setModelOpen(false)}
        message={content}
      />
    </>
  );
};

export default ResponseQue;
