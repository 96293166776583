export default function () {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.8646 7.72495C17.952 7.50018 18 7.25569 18 7C18 5.89543 17.1046 5 16 5C14.8954 5 14 5.89543 14 7C14 7.25569 14.048 7.50018 14.1354 7.72495L8.14684 13.3613C7.82214 13.1336 7.42667 13 7 13C5.89543 13 5 13.8954 5 15C5 16.1046 5.89543 17 7 17C8.10457 17 9 16.1046 9 15C9 14.8797 8.98937 14.7618 8.96901 14.6473L15.1635 8.81721C15.418 8.93455 15.7014 9 16 9C16.2986 9 16.582 8.93455 16.8365 8.81721L22.2948 13.9544C22.1078 14.2586 22 14.6167 22 15C22 16.1046 22.8954 17 24 17C25.1046 17 26 16.1046 26 15C26 13.8954 25.1046 13 24 13C23.8376 13 23.6798 13.0193 23.5287 13.0559L17.8646 7.72495Z"
        fill="#FF9053"
      />
      <path
        d="M13.4594 22.948C10.712 19.4169 11.389 17.5699 16 14.5C21.0363 17.922 21.0066 19.7677 18.5 23L13.4594 22.948Z"
        fill="#FFD5BE"
      />
      <path
        d="M13.5 23C10.7055 19.4349 11.3665 17.5849 16 14.5C21.0363 17.922 21.0066 19.7677 18.5 23L13.4594 22.948"
        stroke="#FFD5BE"
        stroke-linecap="round"
      />
      <path
        d="M13 23H19V26C19 26.5523 18.5523 27 18 27H14C13.4477 27 13 26.5523 13 26V23Z"
        fill="#FF9053"
      />
    </svg>
  );
}
