export const BATCHES = [
  {
    id: 6,
    label: "UPSC",
    value: "upsc",
  },
  {
    id: 44,
    label: "State PSC",
    value: "state-psc",
  },
  {
    id: 46,
    label: "Khan Sir Courses",
    value: "khan-sir-courses",
  },
  {
    id: 7,
    label: "SSC",
    value: "ssc",
  },
  {
    id: 22,
    label: "Defence",
    value: "defence",
  },
  {
    id: 36,
    label: "Police",
    value: "police",
  },
  {
    id: 47,
    label: "UP Exams",
    value: "up-exams",
  },
  {
    id: 48,
    label: "Bihar Exams",
    value: "bihar-exams",
  },
  {
    id: 49,
    label: "MP Exams",
    value: "mp-exams",
  },
  {
    id: 45,
    label: "Teaching Exams",
    value: "teaching-exams",
  },
  {
    id: 50,
    label: "Banking Exams",
    value: "banking-exams",
  },
  {
    id: 42,
    label: "English Foundation",
    value: "english-foundation",
  },
  {
    id: 35,
    label: "NCERT",
    value: "ncert",
  },
  {
    id: 39,
    label: "GK GS Foundation",
    value: "gk-gs-foundation",
  },
  {
    id: 40,
    label: "Maths Foundation",
    value: "maths-foundation",
  },
  {
    id: 41,
    label: "Reasoning Foundation",
    value: "reasoning-foundation",
  },
  {
    id: 37,
    label: "Map Foundation",
    value: "map-foundation",
  },
  {
    id: 43,
    label: "Science Foundation",
    value: "science-foundation",
  },
];

export const GENDER = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Others",
    value: "others",
  },
];

export const GENDER2 = [
  {
    label: "Male",
    value: "ma" === "ma" ? "ma" : "m",
  },
  {
    label: "Female",
    value: "fe" === "fe" ? "fe" : "f",
  },
  {
    label: "Others",
    value: "ot" === "ot" ? "ot" : "o",
  },
];


export const EDUCATION = [
  {
    label: "10th",
    value: "10th",
  },
  {
    label: "12th",
    value: "12th",
  },
  {
    label: "Graduation",
    value: "graduation",
  },
  {
    label: "Post Graduation",
    value: "post-graduation",
  },
];
