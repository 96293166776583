import { Divider, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import QuizSubmissionInfo from "../submission/QuizSubmissionInfo";
import AllQuiz from "./AllQuiz";
import PageContainer from "../../component/Layout/PageContainer";
import AppMetaHeader from "../../component/AppMetaHeader";
import AppSwitch from "../../component/AppSwitch";
import { useMediaQuery } from "react-responsive";
import useSize from "../../hooks/useSize";

const TestSeriesListing: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [activeKey, setActiveKey] = useState("1");
  const [isChecked, setIsChecked] = useState(false);
  const [xl, sm] = useSize({
    queries: ["(max-width: 1200px)", "(max-width: 768px)"],
  });

  useEffect(() => {
    document.title = "Test Series | Khan Global Studies";
  }, []);

  const handleTabChange = (key) => {
    setActiveKey(key);
    setIsClicked(!isClicked);
  };

  // const isLargeScreen = useMediaQuery({
  //   query: "(max-width: 1075px)",
  // });
  const items = [
    {
      key: "1",
      label: (
        <span className={!isClicked ? "tabs_1" : "tabs_2"}>
          All Test Series
        </span>
      ),
      children: (
        <div
          style={{
            width: "100%",
            height: "70vh",
            overflowY: "scroll",
          }}
          className="video-content-container"
        >
          <AllQuiz isChecked={isChecked} />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <span className={isClicked ? "tabs_1" : "tabs_2"}>
          Attempted Test Series
        </span>
      ),
      children: (
        <div
          style={{
            width: "100%",
            height: "70vh",
            overflowY: "scroll",
          }}
          className="video-content-container"
        >
          <QuizSubmissionInfo />
        </div>
      ),
    },
  ];

  const getCurrentPageTitle = () => {
    const activeItem = items.find((item) => item.key === activeKey);
    return `${activeItem ? activeItem.label.props.children : ""}`;
  };

  return (
    <>
      <AppMetaHeader currentPageTitle="My Test Series" />
      <PageContainer
        currentPageTitle={getCurrentPageTitle()}
        loading={loading}
        transparent={true}
      >
        <Tabs
          // tabBarStyle={{
          //   width: "500px",
          // }}
          // tabBarStyle={
          //   activeKey === "1"
          //     ? {
          //         width: "500px",
          //         position: "fixed",
          //         zIndex: "1",
          //         backgroundColor: "#EDF4FF",
          //         paddingBottom: "0.5%",
          //       }
          //     : {
          //         width: "100%",
          //         position: "fixed",
          //         zIndex: "1",
          //         backgroundColor: "#EDF4FF",
          //         paddingBottom: "0.5%",
          //       }
          // }
          tabBarExtraContent={
            <div
              style={{
                marginRight: xl ? "0%" : "580px",
              }}
            >
              {activeKey === "1" && (
                <AppSwitch
                  value={isChecked}
                  onValueChange={(e) => {
                    setIsChecked(e.target.checked);
                  }}
                  title="Select by Online / Offline"
                  onLabel="Online"
                  offLabel="Offline"
                />
              )}
            </div>
          }
          items={items}
          defaultActiveKey="1"
          activeKey={activeKey}
          onChange={handleTabChange}
          size="small"
        />
      </PageContainer>
    </>
  );
};

export default TestSeriesListing;
