export default function FeedBackIcon() {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="22" height="22" rx="4.5" stroke="white" />
      <path
        d="M8.8225 15.4842L11.5 13.8842L14.1775 15.5053L13.4762 12.4737L15.835 10.4526L12.7325 10.1789L11.5 7.31579L10.2675 10.1579L7.165 10.4316L9.52375 12.4737L8.8225 15.4842ZM6.25125 19L7.6325 13.0842L3 9.10526L9.12 8.57895L11.5 3L13.88 8.57895L20 9.10526L15.3675 13.0842L16.7488 19L11.5 15.8632L6.25125 19Z"
        fill="white"
      />
    </svg>
  );
}
