import { useRef, useState } from "react";
import useMount from "./useMount";


export default function useDebounce(func,args,ms=500){
    const timerRef = useRef<NodeJS.Timeout|null>(null)
    useMount(()=>{
        if(timerRef.current) clearTimeout(timerRef.current)
        timerRef.current = setTimeout(()=>func(args),ms)
    },[args])
}