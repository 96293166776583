import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Controls from "../../utils/controls";
import ControlBar from "./controlBar";
import { IQuality, IRATE } from "../../interfaces/player";
import { useMediaQuery } from "react-responsive";

interface IProps {
  hd: string;
  sd: string;
  isLive: boolean;
  poster: boolean;
  thumb: string;
  visible: boolean;
  isFullScreen: boolean;
  setIsFullScreen: (screen: boolean) => void;
}

export default function YoutubeWrapper({
  hd,
  sd,
  isLive,
  poster,
  thumb,
  visible,
  isFullScreen,
  setIsFullScreen,
}: IProps) {
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const [playing, setPlaying] = React.useState(true);
  const vidRef = React.useRef<ReactPlayer>(null);
  const [zoom] = React.useState(false);

  const [quality, setQuality] = React.useState<IQuality>("360p");
  const [playbackRate, setPlaybackRate] = React.useState<IRATE>({
    name: "1x",
    value: 1,
  });
  const [loading, setLoading] = React.useState(false);
  const [controls, setControls] = React.useState<Controls | null>(null);
  const [volume, setVolume] = React.useState(75);
  const [playerWidth, setPlayerWidth] = React.useState<number>();
  let resize_ob;

  React.useEffect(() => {
    if (vidRef !== null) {
      const controls = new Controls(vidRef, isLive);
      setControls(controls);
    }
  }, [isLive]);

  React.useEffect(() => {
    setPlaying(true);
  }, [sd]);

  const progressHandler = (progress) => {
    const timeline = document.getElementById("timeline");
    timeline?.style?.setProperty("--preview-progress", progress.loaded);
    timeline?.style?.setProperty("--progress-position", progress.played);
  };

  const resizehandler = () => {
    resize_ob = new ResizeObserver(function (entries) {
      let rect = entries[0].contentRect;
      let width = rect.width;
      let height = rect.height;
      if (setPlayerWidth) {
        setPlayerWidth(Math.ceil((16 / 9) * height));
      }
    });
    resize_ob.observe(document.querySelector(".custom-player-wrapper"));
  };
  React.useEffect(() => {
    resizehandler();
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: isMobile
          ? isFullScreen
            ? "100vh"
            : "45vh"
          : isFullScreen
          ? "100vh"
          : "80vh",
        position: "relative",
        overflow: "hidden",
      }}
      onContextMenu={(e) => e.preventDefault()}
    >
      <ReactPlayer
        onEnded={() => {
          controls?.startFromZero();
          setPlaying(true);
        }}
        onStart={() => {}}
        onContextMenu={(e) => e.preventDefault()}
        id="player"
        ref={vidRef}
        url={quality === "360p" ? sd : hd}
        width={playerWidth}
        height="400%"
        poster={thumb}
        playsinline
        playing={playing}
        onBuffer={() => setLoading(true)}
        onBufferEnd={() => setLoading(false)}
        config={{
          youtube: {
            playerVars: { autoplay: true },
          },
        }}
        volume={volume / 100}
        volumeChange={(vol) => {
          setVolume(vol / 100);
        }}
        playbackRate={playbackRate.value}
        muted={volume <= 0}
        onProgress={progressHandler}
      />
      {controls && sd && (
        <ControlBar
          quality={quality}
          setQuality={(quality) => setQuality(quality)}
          volume={volume}
          setVolume={setVolume}
          isLoading={loading}
          isPlaying={playing}
          onPlay={() => setPlaying((playing) => !playing)}
          controls={controls}
          isFullScreen={isFullScreen}
          setFullScreen={() => setIsFullScreen(!isFullScreen)}
          isVisible={visible}
          playbackRate={playbackRate}
          setPlaybackRate={setPlaybackRate}
          type="yt"
        />
      )}
    </div>
  );
}
