import { Col, Row } from "antd";
import React from "react";
import parse from "html-react-parser";

const OverView = ({ testSeries }) => {
  return (
    <Row>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        {testSeries?.details?.overview ? (
          <span> {parse(testSeries?.details?.overview)}</span>
        ) : (
          <span style={{ fontWeight: "bold" }}>
            Overview not available right now!
          </span>
        )}
      </Col>
    </Row>
  );
};

export default OverView;
