import React, { useState } from "react";
import AppMetaHeader from "../../component/AppMetaHeader";
import PageContainer from "../../component/Layout/PageContainer";
import { useApi, useMount } from "../../hooks";
import { useSearch } from "@tanstack/react-router";
import { Card } from "antd";
import PaginationComp from "../../utils/PaginationComp";
import Loader from "../../component/loader";
import UserResponse from "./UserResponse";
import ResponseQue from "./ResponseQue";

const Response = () => {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState();
  const [perPage, setPerPage] = useState();
  const [isModelOpen, setModelOpen] = useState(false);

  const { id, qId } = useSearch({
    from: "/answer-writing/responses",
  });

  const { data, load, isLoading } = useApi(`subjectives/${id}`);

  const {
    data: contentData,
    load: contentLoad,
    isLoading: contentisLoading,
  } = useApi(`subjectives-questions/${qId}?page=${page}`);

  useMount(() => {
    load({ page });
    contentLoad();
  }, [page]);

  useMount(() => {
    setTotal(contentData?.replies?.total);
    setPerPage(contentData?.replies?.per_page);
  }, [contentData]);

  const currentQue = data?.questions?.filter((item) => item.id === qId);

  return (
    <>
      <AppMetaHeader currentPageTitle="Answer Writing Responses" />
      <PageContainer
        currentPageTitle="Answer Writing Responses"
        title={data?.name}
        url={`/answer-writing/question?id=${id}`}
      >
        {isLoading ? (
          <Loader />
        ) : (
          currentQue?.map((item: any, index) => (
            <>
              <ResponseQue item={item} index={index} />

              <p
                style={{
                  fontSize: "1.5em",
                  fontWeight: "600",
                  marginBottom: "1%",
                }}
              >
                {contentData?.replies?.total} Responses
              </p>

              <Card>
                {contentisLoading ? (
                  <Loader />
                ) : (
                  <UserResponse contentData={contentData} />
                )}

                <PaginationComp
                  page={page}
                  total={total}
                  perPage={perPage}
                  onChange={(page) => setPage(page)}
                />
              </Card>
            </>
          ))
        )}
      </PageContainer>
    </>
  );
};

export default Response;
