import { Avatar, Card } from "antd";
import Meta from "antd/es/card/Meta";
import React from "react";
import styled from "styled-components";

const ScoreCard = ({ icon, score, title }) => {
  return (
    <Card
      style={{
        boxShadow: "0px 2px 6px 0px #0D0A2C14",
        borderRadius: "1em",
        marginBottom: "5%",
      }}
    >
      <Meta avatar={<Avatar size="large" shape="square" src={icon} />} />
      <div style={{ display: "flex", marginTop: "10%" }}>
        <Title>{title}</Title>
        <Score>{score}</Score>
      </div>
    </Card>
  );
};

const Title = styled.span`
  color: #6e6e6e;
  font-size: 1.2em;
  margin-right: 5%;
`;

const Score = styled.p`
  font-size: 1.3em;
  font-weight: 550;
  margin-bottom: 0%;
`;

export default ScoreCard;
