export default function EnrollIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4" y="4" width="20" height="20" rx="4.69578" fill="#EDEDFB" />
      <path
        d="M4 8.69578C4 6.10237 6.10237 4 8.69578 4H19.3042C21.8976 4 24 6.10237 24 8.69578V10H4V8.69578Z"
        fill="#353297"
      />
      <path
        d="M11 14C11 14 8 15.9231 8 16.3077C8 16.6923 11 19 11 19"
        stroke="#353297"
        stroke-width="0.782629"
        stroke-linecap="round"
      />
      <path
        d="M17 14C17 14 20 15.9231 20 16.3077C20 16.6923 17 19 17 19"
        stroke="#353297"
        stroke-width="0.782629"
        stroke-linecap="round"
      />
    </svg>
  );
}
