import { Carousel, Typography } from "antd";
import React from "react";

const HomeScreenSlider = () => {
  const contentStyle: React.CSSProperties = {
    height: "90%",
    color: "#fff",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };
  return (
    <div>
      <Carousel autoplay>
        <div style={{ backgroundColor: "#EEF6FF" }} className=" wh-100">
          <div style={contentStyle}>
            <img
              src="/login-img_1.png"
              alt="Image"
              style={{ width: "60%", height: "70%" }}
            />
            <Typography.Title level={3} style={{ color: "black" }}>
              Login to access the courses and materials
            </Typography.Title>
            <span style={{ color: "black", marginBottom: "40px" }}>
              Create account and access of the courses, test and <br />
              study materials
            </span>
          </div>
        </div>
        <div>
          <div style={contentStyle}>
            <img
              src="/login-img_2.png"
              alt="Image"
              style={{ width: "45%", height: "60%" }}
            />
            <h1 style={{ color: "black" }}>
              Login to access the courses and materials
            </h1>
            <span style={{ color: "black", marginBottom: "40px" }}>
              Create account and access of the courses, test and <br /> study
              materials
            </span>
          </div>
        </div>
        <div>
          <div style={contentStyle}>
            <img
              src="/login-img_3.png"
              alt="Image"
              style={{ width: "60%", height: "65%" }}
            />
            <h1 style={{ color: "black" }}>
              Login to access the courses and materials
            </h1>
            <span style={{ color: "black", marginBottom: "40px" }}>
              Create account and access of the courses, test and <br /> study
              materials
            </span>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default HomeScreenSlider;
