export const PracticeData = [
  {
    name: "Daily Current Affairs Quiz",
    slug: "daily-current-affairs-quiz",
    batch: "",
    type: 3,
    image: "https://khanglobalstudies.com/icons/quiz/m1.png",
    color: "#FFF0F0",
  },
  {
    name: " GK/GS Quiz",
    slug: "daily-gkgs-quiz",
    batch: "",
    type: 4,
    image: "https://khanglobalstudies.com/icons/quiz/m2.png",
    color: "#F4F3FF",
  },
  {
    name: " UPSC Quiz",
    slug: "daily-upsc-quiz",
    batch: "",
    type: 7,
    image: "https://khanglobalstudies.com/icons/quiz/m3.png",
    color: "#EFFFFD",
  },
  {
    name: " BPSC Quiz",
    slug: "daily-bpsc-quiz",
    batch: "",
    type: 5,
    image: "https://khanglobalstudies.com/icons/quiz/m4.png",
    color: "#FFFEE9",
  },
  {
    name: " MP-PSC Quiz",
    slug: "daily-mppsc-quiz",
    batch: "",
    type: 6,
    image: "https://khanglobalstudies.com/icons/quiz/m1.png",
    color: "#FFF0F0",
  },
  {
    name: " CSAT Quiz",
    slug: "daily-csat-quiz",
    batch: "",
    type: 8,
    image: "https://khanglobalstudies.com/icons/quiz/m2.png",
    color: "#F4F3FF",
  },
  {
    name: " SSC CHSL Quiz",
    slug: "daily-ssc-chsl-quiz",
    batch: "",
    type: 9,
    image: "https://khanglobalstudies.com/icons/quiz/m3.png",
    color: "#EFFFFD",
  },
  {
    name: " SSC MTS Quiz",
    slug: "daily-ssc-mts-quiz",
    batch: "",
    type: 10,
    image: "https://khanglobalstudies.com/icons/quiz/m4.png",
    color: "#FFFEE9",
  },
  {
    name: "IAF | Navy | ICG Quiz",
    slug: "daily-iaf-mts-quiz",
    batch: "",
    type: 11,
    image: "https://khanglobalstudies.com/icons/quiz/m1.png",
    color: "#FFF0F0",
  },
  {
    name: "English Foundation Quiz",
    slug: "english-foundation-quiz",
    batch: "",
    type: 12,
    image: "https://khanglobalstudies.com/icons/quiz/m2.png",
    color: "#F4F3FF",
  },
  {
    name: "TEJAS QUIZ 51",
    slug: "tejas-51",
    batch: "",
    type: 13,
    image: "https://khanglobalstudies.com/icons/quiz/m3.png",
    color: "#EFFFFD",
  },
  {
    name: "NCERT Geography Quiz",
    slug: "ncert-geography-quiz",
    batch: "",
    type: 14,
    image: "https://khanglobalstudies.com/icons/quiz/m4.png",
    color: "#FFFEE9",
  },
  {
    name: "SSC CGL Quiz",
    slug: "ssc-cgl-quiz",
    batch: "",
    type: 15,
    image: "https://khanglobalstudies.com/icons/quiz/m1.png",
    color: "#FFF0F0",
  },
];
