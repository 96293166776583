import React, { useState } from "react";
import AppMetaHeader from "../component/AppMetaHeader";
import PageContainer from "../component/Layout/PageContainer";
import { Row, Col, Select, Button } from "antd";
import CustomCard from "../component/GlobalCard";
import Shimmer from "../component/Shimmer";
import EmptyResult from "../component/Empty";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { recommendStore } from "../store/recommendedCourseStore";

const FILTER = [
  { id: 1, title: "Live Courses" },
  { id: 2, title: "Live+Recorded Courses" },
  { id: 3, title: "Recorded Courses" },
];

const CATEGORIES = [
  { id: 11, title: "All Courses" },
  { id: 12, title: "Online Courses" },
  { id: 13, title: "Offline Courses" },
  { id: 14, title: "Upcoming Courses" },
];

interface IMyCourse {
  id: number;
  slug: string;
  title: string;
  image: {
    small: string;
    medium: string;
    thumb: string;
  };
  is_recent: boolean;
  is_expire_soon: boolean;
}

function isOnline(categoryId: number) {
  const online = [1, 2, 3, 6, 7];
  return online.includes(categoryId);
}
function isOffline(categoryId: number) {
  const offline = [4, 5];
  return offline.includes(categoryId);
}

const RecommendCourseListing = () => {
  const { courses, rcIsLoading } = recommendStore();
  const [categoryId, setCategoryId] = useState(11);
  const [filterID, setFilterID] = useState<number>();
  const [online, offline, upcoming]: IMyCourse[][] = [[], [], [], []];
  enum Category {
    ALL_COURSES = 11,
    ONLINE_COURSES = 12,
    OFFLINE_COURSES = 13,
    UPCOMING_COURSES = 14,
  }
  const isSmallScreen = useMediaQuery({
    query: "(max-width: 1084px)",
  });

  courses?.forEach((datum) => {
    isOnline(datum.category_id) && online.push(datum);
    isOffline(datum.category_id) && offline.push(datum);
  });

  function getData() {
    function match(course) {
      return course.category_id === filterID;
    }
    if (categoryId === Category.ALL_COURSES) {
      return filterID ? courses.filter(match) : courses;
    }
    if (categoryId === Category.OFFLINE_COURSES) {
      return filterID ? offline.filter(match) : offline;
    }
    if (categoryId === Category.ONLINE_COURSES) {
      return filterID ? online.filter(match) : online;
    }
    return upcoming.filter(match);
  }

  const availableCourse = getData();

  return (
    <>
      <AppMetaHeader currentPageTitle="Recommended Courses" />
      <PageContainer
        currentPageTitle="Recommended Courses"
        title={"My Courses"}
        transparent={true}
      >
        <Header style={{ height: isSmallScreen ? "19vh" : "" }}>
          {CATEGORIES?.map((item) => (
            <Button
              size="large"
              onClick={() => {
                setCategoryId(item?.id);
              }}
              style={{
                marginRight: "1em",
                color: item?.id === categoryId ? "white" : "",
                backgroundColor: item?.id === categoryId ? "#2E3192" : "",
                marginBottom: isSmallScreen ? "2%" : "",
                marginTop: "0.5%",
              }}
            >
              {item?.title}
            </Button>
          ))}
          <Select
            allowClear
            size="large"
            id="selectCategory"
            onSelect={(value) => {
              setFilterID(Number(value));
            }}
            defaultValue={"Select Courses"}
            fieldNames={{ label: "title", value: "id" }}
            options={FILTER}
            style={{ width: "14em" }}
          />
          <h2 style={{ marginBottom: "0%", marginTop: "0.5%" }}>
            We have found {availableCourse?.length} Courses available for you!
          </h2>
        </Header>

        <Container
          className="video-content-container"
          style={{ height: isSmallScreen ? "65vh" : "" }}
        >
          <Shimmer count={20} loading={rcIsLoading} type="horizantal">
            <Row
              gutter={[15, 15]}
              style={{
                width: "99.8%",
                marginBottom: "1%",
              }}
            >
              {availableCourse?.length === 0 ? (
                <EmptyResult
                  title={"No Data Found!"}
                  showDes={false}
                  status={"404"}
                />
              ) : (
                availableCourse?.map((elem) => {
                  return (
                    <Col key={elem?.id} xs={24} sm={12} md={8} lg={6} xl={6}>
                      <a
                        href={`https://khanglobalstudies.com/courses/${elem.slug}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CustomCard
                          title={
                            <div
                              style={{
                                marginBottom: "2%",
                                minHeight: "3rem",
                                whiteSpace: "break-spaces",
                              }}
                              className="card-content2"
                            >
                              {elem.title}
                            </div>
                          }
                          type="cover"
                          cover={{
                            url: (elem?.image?.medium as string) || "./kgs.jpg",
                            height: "100%",
                            width: "100%",
                          }}
                        />
                      </a>
                    </Col>
                  );
                })
              )}
            </Row>
          </Shimmer>
        </Container>
      </PageContainer>
    </>
  );
};

const Header = styled.div`
  width: 100%;
  height: 12vh;
`;
const Container = styled.div`
  width: 100%;
  background: transparent;
  height: 71vh;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export default RecommendCourseListing;
