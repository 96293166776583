import React, { useEffect, useState } from "react";
import { Card, Col, FloatButton, Row, Space } from "antd";
import AskDoubtCard from "./AskDoubtCard";
import { useStorage } from "../../hooks";
import styled from "styled-components";
import CallingIcon from "../icons/CallingIcon";
import CallingIcon2 from "../icons/CallingIcon2";
import { QuestionCircleOutlined } from "@ant-design/icons";

const RecentActivity = () => {
  const [events, setEvents] = useStorage<any>("EVENTS", []);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setEvents(events?.reverse()?.slice(0, 5));
  }, []);

  const icon = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 2C8.73478 2 8.48043 2.10536 8.29289 2.29289C8.10536 2.48043 8 2.73478 8 3C8 3.26522 8.10536 3.51957 8.29289 3.70711C8.48043 3.89464 8.73478 4 9 4H11C11.2652 4 11.5196 3.89464 11.7071 3.70711C11.8946 3.51957 12 3.26522 12 3C12 2.73478 11.8946 2.48043 11.7071 2.29289C11.5196 2.10536 11.2652 2 11 2H9Z"
        fill="#8F00FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 5C4 4.46957 4.21071 3.96086 4.58579 3.58579C4.96086 3.21071 5.46957 3 6 3C6 3.79565 6.31607 4.55871 6.87868 5.12132C7.44129 5.68393 8.20435 6 9 6H11C11.7956 6 12.5587 5.68393 13.1213 5.12132C13.6839 4.55871 14 3.79565 14 3C14.5304 3 15.0391 3.21071 15.4142 3.58579C15.7893 3.96086 16 4.46957 16 5V16C16 16.5304 15.7893 17.0391 15.4142 17.4142C15.0391 17.7893 14.5304 18 14 18H6C5.46957 18 4.96086 17.7893 4.58579 17.4142C4.21071 17.0391 4 16.5304 4 16V5ZM7 9C6.73478 9 6.48043 9.10536 6.29289 9.29289C6.10536 9.48043 6 9.73478 6 10C6 10.2652 6.10536 10.5196 6.29289 10.7071C6.48043 10.8946 6.73478 11 7 11H7.01C7.27522 11 7.52957 10.8946 7.71711 10.7071C7.90464 10.5196 8.01 10.2652 8.01 10C8.01 9.73478 7.90464 9.48043 7.71711 9.29289C7.52957 9.10536 7.27522 9 7.01 9H7ZM10 9C9.73478 9 9.48043 9.10536 9.29289 9.29289C9.10536 9.48043 9 9.73478 9 10C9 10.2652 9.10536 10.5196 9.29289 10.7071C9.48043 10.8946 9.73478 11 10 11H13C13.2652 11 13.5196 10.8946 13.7071 10.7071C13.8946 10.5196 14 10.2652 14 10C14 9.73478 13.8946 9.48043 13.7071 9.29289C13.5196 9.10536 13.2652 9 13 9H10ZM7 13C6.73478 13 6.48043 13.1054 6.29289 13.2929C6.10536 13.4804 6 13.7348 6 14C6 14.2652 6.10536 14.5196 6.29289 14.7071C6.48043 14.8946 6.73478 15 7 15H7.01C7.27522 15 7.52957 14.8946 7.71711 14.7071C7.90464 14.5196 8.01 14.2652 8.01 14C8.01 13.7348 7.90464 13.4804 7.71711 13.2929C7.52957 13.1054 7.27522 13 7.01 13H7ZM10 13C9.73478 13 9.48043 13.1054 9.29289 13.2929C9.10536 13.4804 9 13.7348 9 14C9 14.2652 9.10536 14.5196 9.29289 14.7071C9.48043 14.8946 9.73478 15 10 15H13C13.2652 15 13.5196 14.8946 13.7071 14.7071C13.8946 14.5196 14 14.2652 14 14C14 13.7348 13.8946 13.4804 13.7071 13.2929C13.5196 13.1054 13.2652 13 13 13H10Z"
        fill="#8F00FF"
      />
    </svg>
  );

  const handleClick = () => {
    setVisible(!visible);
  };

  const handleCardClick = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <RecentActivityContainer>
        <Heading>Recent Activity</Heading>
        <RecentActivityDrawer className="hideScrollbar">
          {events
            ? events.map((item) => (
                <div
                  className="space-align-container"
                  style={{ marginBottom: "10%" }}
                >
                  <div className="space-align-block">
                    <Space align="center">
                      <Row
                        style={{
                          backgroundColor: "#EAEEFD",
                          width: "36px",
                          height: "36px",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "8px",
                        }}
                      >
                        {icon}
                      </Row>
                      <Row>
                        <span className="mock-block">
                          <Title className="card-content">{item?.title}</Title>
                          <SubTitle style={{ marginBottom: "0" }}>
                            {item?.time}
                          </SubTitle>
                        </span>
                      </Row>
                    </Space>
                  </div>
                </div>
              ))
            : "No Recent Activity"}
        </RecentActivityDrawer>
      </RecentActivityContainer>

      <FloatButton.Group
        trigger="click"
        type="primary"
        style={{ right: 24, bottom: 20 }}
        icon={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.7938 14.625C12.1444 14.625 10.5149 14.2655 8.90521 13.5464C7.29549 12.8273 5.8309 11.808 4.51146 10.4885C3.19201 9.1691 2.17274 7.70451 1.45365 6.09479C0.734549 4.48507 0.375 2.85556 0.375 1.20625C0.375 0.96875 0.454167 0.770833 0.6125 0.6125C0.770833 0.454167 0.96875 0.375 1.20625 0.375H4.4125C4.59722 0.375 4.76215 0.437674 4.90729 0.563021C5.05243 0.688368 5.13819 0.836806 5.16458 1.00833L5.67917 3.77917C5.70556 3.99028 5.69896 4.1684 5.65938 4.31354C5.61979 4.45868 5.54722 4.58403 5.44167 4.68958L3.52188 6.62917C3.78576 7.11736 4.09913 7.58906 4.46198 8.04427C4.82483 8.49948 5.22396 8.9382 5.65938 9.36042C6.0684 9.76945 6.49722 10.1488 6.94583 10.4984C7.39444 10.8481 7.86944 11.1681 8.37083 11.4583L10.2312 9.59792C10.35 9.47917 10.505 9.3901 10.6964 9.33073C10.8877 9.27135 11.0757 9.25486 11.2604 9.28125L13.9917 9.83542C14.1764 9.88819 14.3281 9.98385 14.4469 10.1224C14.5656 10.2609 14.625 10.416 14.625 10.5875V13.7938C14.625 14.0313 14.5458 14.2292 14.3875 14.3875C14.2292 14.5458 14.0313 14.625 13.7938 14.625ZM2.76979 5.125L4.07604 3.81875L3.73958 1.95833H1.97813C2.0441 2.49931 2.13646 3.03368 2.25521 3.56146C2.37396 4.08924 2.54549 4.61042 2.76979 5.125ZM9.85521 12.2104C10.3698 12.4347 10.8943 12.6128 11.4286 12.7448C11.963 12.8767 12.5007 12.9625 13.0417 13.0021V11.2604L11.1813 10.8844L9.85521 12.2104Z"
                fill="white"
              />
            </svg>
          </div>
        }
      >
        <Card
          style={{
            width: "260px",
            right: 220,
            // bottom: -55,
            boxShadow: "-28px 22px 45px 0px #1B1D4224",
            border: "1px solid #E2DEF9",
            borderRadius: "1.3em",
          }}
        >
          <Row align={"middle"}>
            <Col span={14}>
              <p style={{ margin: "0", fontWeight: "700" }}>
                Get Free academic Counseling & Course Details
              </p>
              <p
                style={{
                  background: "#FF8057",
                  color: "white",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  padding: "3%",
                  borderRadius: "1.2em",
                  fontWeight: "700",
                  fontSize: "0.9em",
                  margin: "0%",
                  marginTop: "10%",
                }}
              >
                <CallingIcon /> +91 8757354880
              </p>
            </Col>
            <Col span={10}>
              <CallingIcon2 />
            </Col>
          </Row>
        </Card>
      </FloatButton.Group>

      {/* <FloatButton.Group
        trigger="click"
        type="primary"
        style={{ right: 84, bottom: 20 }}
        icon={<QuestionCircleOutlined />}
      >
        <Card
          style={{
            width: "260px",
            right: 160,
            // bottom: -55,
            boxShadow: "-28px 22px 45px 0px #1B1D4224",
            border: "1px solid #E2DEF9",
            borderRadius: "1.8em",
          }}
          // size="small"
          bodyStyle={{ padding: "0%" }}
        >
          <AskDoubtCard />
        </Card>
      </FloatButton.Group> */}

      <div onClick={() => setVisible(true)}>
        <FloatButton.Group
          trigger="click"
          type="primary"
          style={{ right: 84, bottom: 20 }}
          icon={<QuestionCircleOutlined />}
          onClick={handleClick}
        >
          {visible && (
            <Card
              style={{
                width: "260px",
                right: 160,
                boxShadow: "-28px 22px 45px 0px #1B1D4224",
                border: "1px solid #E2DEF9",
                borderRadius: "1.8em",
              }}
              bodyStyle={{ padding: "0%" }}
              onClick={handleCardClick}
            >
              <AskDoubtCard />
            </Card>
          )}
        </FloatButton.Group>
      </div>
    </>
  );
};

const RecentActivityContainer = styled.div`
  background-color: #f9f8f9;
  height: 100%;
  padding: 20px;
  border-left: 2px solid white;
`;

const RecentActivityDrawer = styled.div`
  height: 50vh;
  overflow: auto;
`;

const Heading = styled.p`
  font-family: Urbanist;
  font-size: 1rem;
  font-weight: 500;
  line-height: 19px;
  // padding-top: 22px;
`;

const Title = styled.p`
  font-size: 1em;
  color: #272835;
  font-weight: 600;
  line-height: 1.8;
  margin-bottom: 1.5%;
`;

const SubTitle = styled.p`
  font-size: 0.7em;
  color: #9e9e9e;
  font-weight: 300;
`;
export default RecentActivity;
