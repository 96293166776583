import React from 'react';

const Time = ({ controls, playing, goLive,goLiveHandler }) => {
  const [time, setTime] = React.useState({ tTime: null, cTime: null });

  let interval:NodeJS.Timer|null = null;

  React.useEffect(() => {
    if (controls) {
      interval = setInterval(() => {
        setTime({
          tTime: controls.getDuration(),
          cTime: controls.getCurrentTime(),
        });
      }, 1000);
    }
  }, []);

  React.useEffect(() => {
    if (playing) {
      interval = setInterval(() => {
        setTime({
          tTime: controls.getDuration(),
          cTime: controls.getCurrentTime(),
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }
  }, [playing]);
  return (
    <div className='time'>
      {controls?.isLive ? (
        goLive ? (
          <div className='go-to-live' onClick={()=>{controls?.goLive();goLiveHandler()}}>Go To Live</div>
        ) : (
          <div className='live-indicator'>LIVE</div>
        )
      ) : (
        ` ${time.cTime || controls.getCurrentTime()}/${
          time.tTime || controls.getDuration()
        }`
      )}
    </div>
  );
};

export default Time;
