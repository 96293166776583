import { Image } from "antd";
import React from "react";
import styled from "styled-components";

const LogoContainer = () => {
  return (
    <Conatiner>
      <div style={{ width: "5em" }}>
        <Image
          src="https://kgs.nyc3.cdn.digitaloceanspaces.com/uploads/z5TV2y7lbudA4wI0C2sKlsBkcMwHv27uFWP0a5j4.png"
          width={"5em"}
          height={"5em"}
          preview={false}
        />
      </div>
      <SubContainer>
        <Title>KGS</Title>
      </SubContainer>
    </Conatiner>
  );
};

const Conatiner = styled.div`
  display: flex;
  width: 10em;
  height: 5em;
  align-items: center;
  margin-bottom: 5%;
`;

const SubContainer = styled.div`
  width: 5em;
  height: 2.5em;
  padding-left: 0.5em;
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 2em;
  color: #2e3192;
`;
export default LogoContainer;
