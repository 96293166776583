import React, {ReactNode, createContext, useContext, useMemo, useState} from 'react';
export const X_KEY = '$x/';

type Dict<T> = NodeJS.Dict<T>;


const StorageContext = createContext<[Dict<unknown>, (key: string, data: unknown) => void ]>([{}, () => {}]);


function keying(name: string) {return X_KEY + name}

function fetchData(name: string) {
  const data = localStorage.getItem(keying(name));
  if (!data) {
    return data;
  }
  return JSON.parse(data);
}

function updateStorage(key: string, value: unknown) {
  localStorage.setItem(keying(key), JSON.stringify(value));
}

interface StorageProps {
  children: ReactNode;
}
export function StorageProvider({children}: StorageProps) {
  const [data, setData] = useState<Dict<unknown>>({});
  function updateState(key: string, value: unknown) {
    setData(x => {
      const nx = { ...x, [key]: value };
      updateStorage(key, value);
      return nx;
    })
  }
  return <StorageContext.Provider value={[data, updateState]}>{children}</StorageContext.Provider>
}

export function useStorage<T>(name: string, def?: T) {
  const [data, setData] = useContext(StorageContext);
  function setState(value: unknown) {
    setData(name, value);
  }
  return [useMemo<T>((): T => {
    let val = data[name];
    if (!(name in data)) {
      val = fetchData(name);
      setData(name, val);
    }
    return val ?? def as any;
  }, [name]), setState] as const;
}
