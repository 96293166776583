import { useMediaQuery } from "react-responsive";

export default function useSize(){
    const isS = useMediaQuery({
        query: "(max-width: 426px)",
      });
    
      const isM = useMediaQuery({
        query: "(max-width: 768px)",
      });
    
      const isL = useMediaQuery({
        query: "(max-width: 1025px)",
      });
    
      const isXl = useMediaQuery({
        query: "(max-width: 1441px)",
      });
    return {isS,isM,isL,isXl}
}