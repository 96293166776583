import { Avatar, Card, Col, List, Row, Tabs, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import DataNotFound from "../../utils/dataNotFound";
import { formatDate, formatDateTime } from "../../helpers/help";
import parse from "html-react-parser";
import api from "../../config/api";
import { IAnnouncement, ICourse, ILesson } from "../../interfaces/course";
import { TQuizRes } from "../../pages/CoursePlayer";
import styled from "styled-components";
import Shimmer from "../Shimmer";
import Meta from "antd/es/card/Meta";
import QuizIcon from "../icons/QuizIcon";
import DPTQuiz from "../../pages/quiz/DPTQuiz";
import CourseAW from "./CourseAW";
import { useSearch } from "@tanstack/react-router";
import PdfIconBlue from "../icons/PdfIconBlue";
import PDFIcon from "../icons/PDFIcon";
import DownloadIcon from "../icons/DownloadIcon";
import moment from "moment";
import { LockOutlined } from "@ant-design/icons";

interface IProps {
  loading: boolean;
  announcements: IAnnouncement[];
  courseDetail: ICourse;
  lessons: ILesson[];
  quizzesData?: TQuizRes;
  lessonId: number;
  timeTable: any; //needs to be changed
  lecturePlan: any; //needs to be changed
}

export default function CourseContent({
  courseDetail,
  announcements,
  quizzesData,
  lessonId,
  loading,
  timeTable,
  lecturePlan,
  dpps,
  subjective,
  lessons,
  videos,
}: IProps) {
  const { quizzes, wmts } = quizzesData;

  const [isChecked, setIsChecked] = useState(false);
  const [currnetDPTId, setCurrentDPTId] = useState();
  const [isAttemptAnswerWriting, setIsAttemptAnswerWriting] = useState(false);
  const [currentAWId, setCurrentAWId] = useState();

  const currentWmtLessonId = wmts?.filter((q) => q.lesson_id === lessonId);

  const timeTableData = Object.values(timeTable);

  const { vvid } = useSearch({
    from: "/courses/$courseId/play",
  });

  const supportingPDF = videos
    .map((q) => {
      if (Number(q.id) === vvid) {
        return q?.pdfs;
      }
    })
    .filter((pdf) => pdf !== undefined);

  return (
    <>
      <div className="content-container">
        <div className="container">
          <div className="">
            <Tabs defaultActiveKey="1" size="small">
              {announcements && (
                <Tabs.TabPane tab="Announcements" key="1">
                  <Shimmer loading={loading}>
                    {announcements.length > 0
                      ? announcements?.map((item) => (
                          <Card
                            style={{
                              marginBottom: "1%",
                              display: "flex",
                              border: "1px solid #C5D5FF",
                            }}
                          >
                            <CardInnerContainer>
                              {item?.image &&
                                (item.image?.includes("http") ? (
                                  <CardInnerImage
                                    referrerPolicy="no-referrer"
                                    src={item?.image}
                                    alt="img"
                                  />
                                ) : (
                                  <CardInnerImage
                                    referrerPolicy="no-referrer"
                                    src={`https://khanglobalstudies.com/images/anns/${item?.image}`}
                                    alt="img"
                                  />
                                ))}
                              <p
                                style={{ width: "57vw" }}
                                className="paragraph-image"
                              >
                                {parse(`${item?.content}`)}
                              </p>

                              <p
                                style={{
                                  position: "absolute",
                                  bottom: "0px",
                                  right: "1rem",
                                }}
                              >
                                {formatDate(item?.published_at)}
                              </p>
                            </CardInnerContainer>
                          </Card>
                        ))
                      : "No Announcements"}
                  </Shimmer>
                </Tabs.TabPane>
              )}

              {timeTableData && timeTableData?.length > 0 && (
                <Tabs.TabPane tab="Time Table" key="2">
                  {timeTable && (
                    <Card
                      style={{
                        border: "0.1em solid #C5D5FF",
                      }}
                    >
                      <img
                        src={timeTable.large}
                        width={"100%"}
                        alt="time-table"
                      />
                    </Card>
                  )}
                </Tabs.TabPane>
              )}

              {quizzes && quizzes?.length && (
                <Tabs.TabPane tab="Test Series" key="3">
                  {quizzes && !quizzes?.length ? (
                    <DataNotFound title="Test Series Not Available" />
                  ) : (
                    <></>
                  )}
                  <Card>
                    {quizzes.map((item) => {
                      const currentTime = moment();
                      const publishedTime = moment(item.published_at);
                      const isTimePassed = currentTime.isAfter(publishedTime);
                      return isTimePassed ? (
                        <a
                          rel="noreferrer"
                          key={item?.id}
                          href={`/quizzes/upsc-test-series/${item.id}/start`}
                          target="_blank"
                        >
                          <Card style={{ marginBottom: "2%" }} size="small">
                            <Meta
                              avatar={
                                <Avatar
                                  src={
                                    <div
                                      style={{
                                        marginTop: "16%",
                                      }}
                                    >
                                      <QuizIcon />
                                    </div>
                                  }
                                  size={"large"}
                                  shape="circle"
                                  style={{
                                    backgroundColor: "#ECEDFF",
                                    marginRight: "1em",
                                  }}
                                />
                              }
                              title={item?.title}
                              description={
                                <>
                                  <Tag
                                    style={{
                                      backgroundColor: "#EAFCF2",
                                      color: "#25D076",
                                      border: "none",
                                    }}
                                  >
                                    {item?.lang === "all"
                                      ? "English+Hindi"
                                      : item?.lang === "his"
                                      ? "Hindi"
                                      : item?.lang === "en"
                                      ? "English"
                                      : ""}
                                  </Tag>
                                  <p
                                    style={{
                                      float: "right",
                                      marginBottom: "0%",
                                    }}
                                  >
                                    {formatDateTime(item?.published_at)}
                                  </p>
                                </>
                              }
                            />
                          </Card>
                        </a>
                      ) : (
                        <Card
                          style={{ marginBottom: "2%", background: "	#F0F0F0" }}
                          size="small"
                        >
                          <Meta
                            avatar={
                              <Avatar
                                src={
                                  <div
                                    style={{
                                      marginTop: "16%",
                                    }}
                                  >
                                    <QuizIcon />
                                  </div>
                                }
                                size={"large"}
                                shape="circle"
                                style={{
                                  backgroundColor: "#ECEDFF",
                                  marginRight: "1em",
                                }}
                              />
                            }
                            title={
                              // item?.title
                              <Row align={"middle"} justify={"space-between"}>
                                {item?.title}

                                <LockOutlined style={{ fontSize: "1.5em" }} />
                              </Row>
                            }
                            description={
                              <>
                                <Tag
                                  style={{
                                    backgroundColor: "#EAFCF2",
                                    color: "#25D076",
                                    border: "none",
                                  }}
                                >
                                  {item?.lang === "all"
                                    ? "English+Hindi"
                                    : item?.lang === "his"
                                    ? "Hindi"
                                    : item?.lang === "en"
                                    ? "English"
                                    : ""}
                                </Tag>
                                <p
                                  style={{
                                    float: "right",
                                    marginBottom: "0%",
                                  }}
                                >
                                  {formatDateTime(item?.published_at)}
                                </p>
                              </>
                            }
                          />
                        </Card>
                      );
                    })}
                  </Card>
                </Tabs.TabPane>
              )}

              {dpps && dpps?.length && (
                <Tabs.TabPane tab="DPT" key="4">
                  {dpps && !dpps?.length ? (
                    <DataNotFound title="Quiz Not Available" />
                  ) : (
                    <></>
                  )}
                  {isChecked ? (
                    ""
                  ) : (
                    <Card>
                      {dpps.map((item) => (
                        <Card
                          style={{ marginBottom: "2%", cursor: "pointer" }}
                          size="small"
                          onClick={() => {
                            setIsChecked(true);
                            setCurrentDPTId(item?.id);
                          }}
                        >
                          <Meta
                            avatar={
                              <Avatar
                                src={
                                  <div style={{ marginTop: "16%" }}>
                                    <QuizIcon />
                                  </div>
                                }
                                size={"large"}
                                shape="circle"
                                style={{
                                  backgroundColor: "#ECEDFF",
                                  marginRight: "1em",
                                }}
                              />
                            }
                            title={item?.name}
                          />
                        </Card>
                      ))}
                    </Card>
                  )}

                  {isChecked ? (
                    <Card>
                      <DPTQuiz id={currnetDPTId} checked={setIsChecked} />
                    </Card>
                  ) : (
                    ""
                  )}
                </Tabs.TabPane>
              )}

              {currentWmtLessonId && currentWmtLessonId?.length > 0 && (
                <Tabs.TabPane tab="WMT" key="5">
                  {currentWmtLessonId && !currentWmtLessonId?.length ? (
                    <DataNotFound title="Quiz Not Available" />
                  ) : (
                    <></>
                  )}
                  <Card>
                    {wmts.map((item) => (
                      <a
                        rel="noreferrer"
                        key={item?.id}
                        href={`/quizzes/wmt/${item?.quiz?.id}/start`}
                        target="_blank"
                      >
                        <Card style={{ marginBottom: "2%" }} size="small">
                          <Meta
                            avatar={
                              <Avatar
                                src={
                                  <div style={{ marginTop: "16%" }}>
                                    <QuizIcon />
                                  </div>
                                }
                                size={"large"}
                                shape="circle"
                                style={{
                                  backgroundColor: "#ECEDFF",
                                  marginRight: "1em",
                                }}
                              />
                            }
                            title={item?.quiz?.title}
                          />
                        </Card>
                      </a>
                    ))}
                  </Card>
                </Tabs.TabPane>
              )}

              {courseDetail?.overview && (
                <Tabs.TabPane tab="Course Description" key="6">
                  <Card
                    style={{
                      border: "0.1em solid #C5D5FF",
                    }}
                  >
                    {parse(
                      `${
                        courseDetail?.overview ||
                        "Shortly we will add description."
                      }`
                    )}
                  </Card>
                </Tabs.TabPane>
              )}

              {lecturePlan && lecturePlan?.length > 0 && (
                <Tabs.TabPane tab="About Exam" key="7">
                  {lecturePlan && (
                    <List
                      style={{
                        backgroundColor: "white",
                        border: "0.1em solid #C5D5FF",
                      }}
                      bordered
                      dataSource={lecturePlan}
                      renderItem={(item: any) => (
                        <List.Item>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={item?.url}
                            download={true}
                          >
                            <p
                              style={{
                                color: "black",
                                fontSize: "1em",
                                fontWeight: "600",
                                marginBottom: "0%",
                              }}
                            >
                              {item.title}
                            </p>
                          </a>
                        </List.Item>
                      )}
                    />
                  )}
                </Tabs.TabPane>
              )}

              {subjective && subjective?.length > 0 && (
                <Tabs.TabPane tab="Answer Writing" key="8">
                  {isAttemptAnswerWriting ? (
                    ""
                  ) : (
                    <Card>
                      {subjective.map((item) => (
                        <Card
                          style={{ marginBottom: "2%" }}
                          size="small"
                          onClick={() => {
                            setIsAttemptAnswerWriting(true);
                            setCurrentAWId(item?.id);
                          }}
                        >
                          <Meta
                            avatar={
                              <Avatar
                                src={
                                  <div
                                    style={{
                                      marginTop: "16%",
                                    }}
                                  >
                                    <QuizIcon />
                                  </div>
                                }
                                size={"large"}
                                shape="circle"
                                style={{
                                  backgroundColor: "#ECEDFF",
                                  marginRight: "1em",
                                }}
                              />
                            }
                            title={item?.name}
                            description={
                              <>
                                <p
                                  style={{ float: "right", marginBottom: "0%" }}
                                >
                                  {formatDateTime(item?.published_at)}
                                </p>
                              </>
                            }
                          />
                        </Card>
                      ))}
                    </Card>
                  )}

                  {isAttemptAnswerWriting ? (
                    <Card>
                      <CourseAW
                        checked={setIsAttemptAnswerWriting}
                        id={currentAWId}
                      />
                    </Card>
                  ) : (
                    ""
                  )}
                </Tabs.TabPane>
              )}

              {supportingPDF[0] !== null && (
                <Tabs.TabPane tab="Supporting PDF's" key="9">
                  <Card>
                    {supportingPDF.map((item, index) =>
                      item === null ? (
                        "Supporting PDF is not available"
                      ) : (
                        <div key={index}>
                          {item.map((subItem, subIndex) => (
                            <a
                              rel="noreferrer"
                              key={subItem?.id}
                              href={subItem?.url}
                              target="_blank"
                            >
                              <Card style={{ border: "0.1em solid #C5C5C5" }}>
                                <Meta
                                  avatar={
                                    <Avatar
                                      shape="square"
                                      size="large"
                                      src={<PDFIcon />}
                                    />
                                  }
                                  title={<SubTitle>{subItem?.title}</SubTitle>}
                                  description={
                                    <div
                                      style={{
                                        float: "right",
                                        textAlign: "center",
                                        color: "#373852",
                                        marginBottom: "0%",
                                        marginTop: "-4.5%",
                                      }}
                                    >
                                      <DownloadIcon />
                                      <p style={{ marginBottom: "0%" }}>
                                        Download
                                      </p>
                                    </div>
                                  }
                                />
                              </Card>
                            </a>
                          ))}
                        </div>
                      )
                    )}
                  </Card>
                </Tabs.TabPane>
              )}
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}

const CardInnerContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: flex-start;
`;
const CardInnerImage = styled.img`
  object-fit: contain;
  margin-top: 5px;
  height: 100%;
  width: 50px;
`;

const SubTitle = styled.p`
  margin-left: 1%;
`;
