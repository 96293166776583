export const getLocalStorage = (key) => {
    if (window !== "undefined") {
        const token = window.localStorage.getItem(key);
        if (token) return token;
    }
};

export const removeLocalStorage = (key) => {
    if (window !== "undefined") {
        window.localStorage.removeItem(key);
    }
};
export const setLocalStorage = (key, value) => {
    if (window !== "undefined") {
        window.localStorage.setItem(key, value);
    }
};

export const getUserInfo = (key) => {
    if (window !== "undefined") {
        const userInfo = window.localStorage.getItem(key);
        if (userInfo) return JSON.parse(userInfo);
    }
};
export const setUserInfo = (key, value) => {
    if (window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(value));
    }
};
