import { Button, Modal, Row, Col } from "antd";
import React from "react";
import useQuizStore from "../../store/quizStore";
import { Card } from "antd/lib";
import { useNavigate, useParams } from "@tanstack/react-router";
import api from "../../config/api";
import showNotification from "../../utils/notify";
import { getUserInfo } from "../../helpers/LocalStorageHelper";
import { useAuthStore } from "../../store/auth";
import useMount from "../../hooks/useMount";
import { useTimerStore } from "../../store/timer";

interface IProps {
  isVisible: boolean;
  setVisible: (value: boolean) => void;
  navigateTo: "submit" | "quit";
}

const ReportModal = ({
  isVisible,
  setVisible,
  navigateTo,
  isTimeEnd,
}: IProps) => {
  const { getReport, status, getScore, quiz } = useQuizStore((state) => state);

  const { total, answered, notAnswered, notVisited, bookmarked } = getReport();
  const navigator = useNavigate({ from: "/" });
  const { qId, quizzesType } = useParams({ from: "/" });
  const { timer } = useTimerStore();

  const { user } = useAuthStore();
  const userInfo = getUserInfo("userInfo");

  useMount(() => {
    if (isTimeEnd) {
      handleSubmitQuiz();
    }
  }, [isTimeEnd]);

  const handleSubmitQuiz = async () => {
    if (navigateTo === "quit") {
      return navigator({ to: "/home" });
    }

    const endTimeQuiz = timer;

    const ReduceTime =
      endTimeQuiz?.hours * 3600 +
      endTimeQuiz?.minutes * 60 +
      endTimeQuiz?.seconds;

    const totalTimeSec =
      quiz?.meta?.is_timer && quiz?.meta?.timer?.minutes * 60;

    if (user) {
      const { totalScore, correct, totalMarks, inCorrect } = getScore();
      const quizData = {
        ...user,
        total,
        correct,
        attempt: answered,
        pqw: 1,
        meta: Object.keys(status)?.map((questionId: string) => {
          return { q: questionId, a: status[questionId].attempt };
        }),
        score: totalScore,
        outof: totalMarks,
        incorrect: inCorrect,
        time_duration: totalTimeSec - ReduceTime,
      };
      try {
        const { data } = await api.post(`/quizzes/${qId}/leaders`, quizData);
        showNotification("success", "Quiz Submitted Successfully");
      } catch (error) {}
    }
    window.location.replace(`/quizzes/${quizzesType}/${qId}/result`);
  };

  const styles: any = {
    cards: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
    cards_fonts: {
      fontSize: "2.5em",
      textAlign: "center",
      lineHeight: "0",
      marginTop: "20px",
    },
    cards_fonts_title: {
      width: "max-content",
      fontWeight: "bold",
    },
    allQuestions: {
      backgroundColor: "#EBECFF",
      border: "2px solid #2E3192",
      borderRadius: "10px",
      color: "#020887",
    },
    answered: {
      backgroundColor: "#DAFFDA",
      border: "2px solid #127D00",
      borderRadius: "10px",
      color: "#024210",
    },
    notAnswered: {
      backgroundColor: "#FFE6DC",
      border: "2px solid #D34001",
      borderRadius: "10px",
      color: "#792400",
    },
    reviewed: {
      backgroundColor: "#FFFBDA",
      border: "2px solid #9C9600",
      borderRadius: "10px",
      color: "#792400",
    },
    notVisited: {
      backgroundColor: "#EBEBEB",
      border: "2px solid #727272",
      borderRadius: "10px",
      color: "#474747",
    },
  };

  return (
    <Modal
      className="modal"
      title="Your Test Report"
      open={isVisible}
      width={800}
      footer
      closeIcon={null}
      onCancel={() => setVisible(false)}
      closable
    >
      <Col span={24} className="modal-box" style={{ width: "100%" }}>
        {navigateTo === "quit" && (
          <Row>Are you sure you want to quit the test</Row>
        )}
        <Row
          gutter={[5, 5]}
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "20px",
          }}
        >
          <Col md={4} sm={12} xs={24}>
            <Card bordered style={{ ...styles.cards, ...styles.allQuestions }}>
              <p style={styles.cards_fonts_title}>All Questions</p>
              <p style={styles.cards_fonts}>{total}</p>
            </Card>
          </Col>
          <Col md={4} sm={12} xs={24}>
            <Card bordered style={{ ...styles.cards, ...styles.answered }}>
              <p style={styles.cards_fonts_title}>Answered</p>
              <p style={styles.cards_fonts}>{answered}</p>
            </Card>
          </Col>
          <Col md={4} sm={12} xs={24}>
            <Card bordered style={{ ...styles.cards, ...styles.notAnswered }}>
              <p style={styles.cards_fonts_title}> Not Answered</p>
              <p style={styles.cards_fonts}>{notAnswered}</p>
            </Card>
          </Col>
          <Col md={4} sm={12} xs={24}>
            <Card bordered style={{ ...styles.cards, ...styles.reviewed }}>
              <p style={styles.cards_fonts_title}>Reviewed </p>
              <p style={styles.cards_fonts}>{bookmarked}</p>
            </Card>
          </Col>
          <Col md={4} sm={12} xs={24}>
            <Card bordered style={{ ...styles.cards, ...styles.notVisited }}>
              <p style={styles.cards_fonts_title}>Not Visited</p>
              <p style={styles.cards_fonts}>{notVisited}</p>
            </Card>
          </Col>
        </Row>
        <Col span={8} style={{ marginTop: "10px" }} />
        <Col span={24} style={{ display: "flex", justifyContent: "end" }}>
          <Button
            size="middle"
            onClick={() => handleSubmitQuiz()}
            className="quiz-start-btn-self btn"
          >
            {navigateTo === "submit" ? "Submit" : "Quit"}
          </Button>
        </Col>
      </Col>
    </Modal>
  );
};

export default ReportModal;
