import { Button } from "antd";
import React from "react";

const BubleSelect = ({ data, setSelected, selected }) => {
  return (
    <div>
      {data?.map((a) => (
        <Button
          onClick={() => {
            if (selected?.includes(a)) {
              setSelected(selected?.filter((item) => item !== a));
            } else {
              setSelected([...selected, a]);
            }
          }}
          style={{
            cursor: "pointer",
            margin: "0.5em",
            color: selected?.find((b) => b == a) ? "white" : "black",
            backgroundColor: selected?.find((b) => b == a)
              ? "#2E3192"
              : "#F3F4F6",
          }}
          key={a}
        >
          {a}
        </Button>
      ))}
    </div>
  );
};

export default BubleSelect;
