import { notification } from "antd";

const showNotification = (type, message, duration = 3) => {
    notification[type]({
        message: message,
        duration: duration,
    });
};

export default showNotification;
