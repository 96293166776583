export default function StudyMaterials() {
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4 2.40896V1.52512C18.4 1.13188 18.2109 0.761012 17.8945 0.533423C17.5781 0.306031 17.1695 0.247254 16.8035 0.377475L16.5388 0.47138C16.4591 0.368322 16.3687 0.27313 16.257 0.199163C15.9168 -0.0264467 15.4914 -0.0624652 15.1191 0.102784L10.9688 1.9425C10.3531 2.2156 9.64727 2.2156 9.03086 1.9425L4.88086 0.102784C4.50859 -0.0626631 4.0832 -0.0268425 3.74297 0.199163C3.63135 0.27313 3.54097 0.368322 3.46121 0.47138L3.19648 0.377475C2.8293 0.24666 2.42227 0.305636 2.10547 0.533423C1.78906 0.761012 1.6 1.13188 1.6 1.52512V2.40896C0.717578 2.40896 0 3.13625 0 4.03018V15.3788C0 16.2729 0.717578 17 1.6 17H10H18.4C19.2824 17 20 16.2729 20 15.3788V4.03018C20 3.13625 19.2824 2.40896 18.4 2.40896ZM17.0676 1.14257C17.1926 1.09844 17.3246 1.11783 17.4316 1.19462C17.5387 1.2716 17.6 1.39213 17.6 1.52512V13.4698L16.8 13.7535V1.23749L17.0676 1.14257ZM11.2895 2.68503L15.4395 0.845319C15.5656 0.789114 15.7039 0.801384 15.8191 0.877577C15.934 0.95377 16 1.07726 16 1.21659V13.4924L10.4 15.9749V2.92952C10.7043 2.8911 11.0044 2.81154 11.2895 2.68503ZM4 1.21659C4 1.07726 4.06602 0.95377 4.18086 0.877577C4.29609 0.801384 4.43398 0.789708 4.56055 0.845319L8.71016 2.68503C8.99553 2.81144 9.29575 2.89098 9.6 2.92942V15.9749L4 13.4924V1.21659ZM2.4 1.52512C2.4 1.39213 2.46133 1.2716 2.56836 1.19462C2.67539 1.11763 2.80781 1.09844 2.93242 1.14257L3.2 1.23749V13.7535L2.4 13.4698V1.52512ZM0.8 15.3788V4.03018C0.8 3.58312 1.15898 3.21957 1.6 3.21957V13.7575C1.6 13.9297 1.70742 14.0833 1.86797 14.1403L7.64587 16.1894H1.6C1.15898 16.1894 0.8 15.8256 0.8 15.3788ZM19.2 15.3788C19.2 15.8256 18.841 16.1894 18.4 16.1894H12.3541L18.132 14.1403C18.2926 14.0833 18.4 13.9297 18.4 13.7575V3.21957C18.841 3.21957 19.2 3.58312 19.2 4.03018V15.3788Z"
        fill="white"
      />
    </svg>
  );
}
