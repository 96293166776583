import React, { useState } from "react";
import HlsQuality from "./hlsQuality";
import QualityControl from "./qaulityControls";
import RateControl from "./rateControl";
import SettingModal from "./settingModal";
import Controls, { HlsControls } from "../../utils/controls";
import { IQuality, IRATE } from "../../interfaces/player";

export enum SettingMode {
  CLOSE,
  OPTION,
  QUALITY,
  SPEED,
}

interface IProps{
  controls:Controls | HlsControls
  rate:IRATE
  quality:IQuality
  onRateChange:(rate:IRATE)=>void
  setQuality:(quality:IQuality)=>void
  isDual?:boolean,
  type : "yt"|'hls',
}

export default function PlayerSettings({
  controls,
  rate,
  quality,
  onRateChange,
  setQuality,
  isDual = true,
  type = "yt",
}:IProps) {
  const [setting, setSetting] = useState<SettingMode>(SettingMode.CLOSE);

  const renderModal = () => {
    switch (setting) {
      case SettingMode.OPTION:
        return (
          <SettingModal
            setSetting={(setting) => setSetting(setting)}
            quality={quality}
            rate={rate}
          />
        );
      case SettingMode.QUALITY:
        if (type === "hls")
          return (
            <HlsQuality
              quality={quality}
              controls={controls}
              setQuality={(q) => {
                setSetting(SettingMode.OPTION);
                setQuality(q);
              }}
            />
          );
        return (
          <>
            {isDual && (
              <QualityControl
                onChange={(q) => {
                  setSetting(SettingMode.OPTION);
                  setQuality(q);
                }}
                resolution={"sd"}
                variable
              />
            )}
          </>
        );
      case SettingMode.SPEED:
        return (
          <RateControl
            rate={rate}
            setRate={(rate) => {
              setSetting(SettingMode.OPTION);
              onRateChange(rate);
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="settings-container">{renderModal()}</div>
      <i
        className="bx bx-cog"
        style={{ color: "#ffffff", fontSize: "0.9em" }}
        onClick={() =>
          setSetting((setting) => {
            if (setting === SettingMode.CLOSE) return SettingMode.OPTION;
            return SettingMode.CLOSE;
          })
        }
      ></i>
    </>
  );
}