import { Radio } from "antd";
import React from "react";
import PaymentGateway from "./PaymentGateway";
import { RZ_CHANNEL } from "./PyamentConfig";
import styled from "styled-components";

const PaymentList = ({ setPaymentChannel, paymentChannel }) => {
  const onChange = (e) => {
    setPaymentChannel(e.target.value);
  };
  return (
    <>
      <Title>Choose Your Payment Option</Title>
      <Radio.Group onChange={onChange} value={paymentChannel}>
        <PaymentGateway
          value={RZ_CHANNEL}
          title="Razorpay"
          img={"/payment/rz.png"}
        />

        {/* <PaymentGateway
          value="ccavenue_hdfc"
          title="Ccavenue Powered By HDFC"
          img={"/payment/hdfc.png"}
        /> */}
      </Radio.Group>
    </>
  );
};

const Title = styled.p`
  font-size: 1.3em;
  font-weight: 700;
  margin-bottom: 2%;
`;
export default PaymentList;
