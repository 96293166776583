export default function DownloadIcon() {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0275 14.8438C10.3559 14.8443 10.6812 14.7839 10.9847 14.6662C11.2881 14.5485 11.5638 14.3757 11.7958 14.1578L15.0616 11.0938L13.8833 9.99219L10.8558 12.8313L10.8341 0H9.16747L9.18914 12.8188L6.17247 9.99063L4.99414 11.0938L8.25997 14.1555C8.49171 14.3737 8.76715 14.5468 9.07047 14.6649C9.3738 14.7831 9.69902 14.8438 10.0275 14.8438Z"
        fill="#2E3192"
      />
      <path
        d="M18.3333 12.5V16.4062C18.3333 16.6135 18.2455 16.8122 18.0893 16.9587C17.933 17.1052 17.721 17.1875 17.5 17.1875H2.5C2.27899 17.1875 2.06702 17.1052 1.91074 16.9587C1.75446 16.8122 1.66667 16.6135 1.66667 16.4062V12.5H0V16.4062C0 17.0279 0.263392 17.624 0.732233 18.0635C1.20107 18.5031 1.83696 18.75 2.5 18.75H17.5C18.163 18.75 18.7989 18.5031 19.2678 18.0635C19.7366 17.624 20 17.0279 20 16.4062V12.5H18.3333Z"
        fill="#2E3192"
      />
    </svg>
  );
}
