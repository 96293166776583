import { Avatar, Button, Card } from "antd";
import Meta from "antd/es/card/Meta";
import React, { useState } from "react";
import PdfViewer from "../../utils/pdfViewer";
import CommentReply from "./CommentReply";
import RenderComments from "./RenderComments";
import CustomModal from "../../component/common/CustomModal";

const UserResponse = ({ contentData }) => {
  const [isModelOpen, setModelOpen] = useState(false);
  const [content, setContent] = useState();

  const openModal = (content) => {
    setModelOpen(true);
    setContent(content);
  };

  return (
    <>
      {contentData?.replies?.data?.map((item) => (
        <Card style={{ marginBottom: "2%" }}>
          <Meta
            avatar={
              <Avatar
                src={
                  <div
                    style={{
                      backgroundColor: "#FFEAEA",
                      textAlign: "center",
                      color: "black",
                      fontWeight: "600",
                    }}
                  >
                    {item?.user?.name?.charAt(0)?.toUpperCase()}
                  </div>
                }
                size={45}
              />
            }
            title={item?.user?.name}
            description={
              <>
                <p>{item?.answer}</p>
                {item?.attachment && (
                  <Button
                    onClick={() =>
                      openModal(
                        item?.attachment?.includes(".pdf") ? (
                          <PdfViewer
                            pdfUrl={item?.attachment}
                            height={"80vh"}
                          />
                        ) : (
                          <img src={item?.attachment} />
                        )
                      )
                    }
                    style={{
                      marginRight: "1%",
                    }}
                  >
                    View Attachment
                  </Button>
                )}

                <CommentReply userName={item?.user?.name} id={item?.id} />

                <RenderComments comments={item?.comments} answerId={item?.id} />
              </>
            }
          />
        </Card>
      ))}
      <CustomModal
        height="80vh"
        ConfirmationText="Close"
        onDone={() => setModelOpen(false)}
        open={isModelOpen}
        width={"80%"}
        style={"modal-wrapper-answer"}
        hideModal={() => setModelOpen(false)}
        message={content}
      />
    </>
  );
};

export default UserResponse;
