import { Col, Row, Card } from "antd";
import React from "react";
import { SubTitle } from "./LiveClasses";
import Shimmer from "../Shimmer";
import { recommendStore } from "../../store/recommendedCourseStore";

interface ITestSeries {
  id: number;
  thumb: string;
  title: string;
  total_quizzes: number;
  total_questions: number;
}

const RecommendedTestSeries = () => {
  const { testSeries, rcIsLoading } = recommendStore();

  return (
    <>
      <h3 style={{ marginBottom: "0px" }}>Recommended Test Series</h3>
      <Card
        style={{ height: "50vh", overflow: "auto" }}
        className="hideScrollbar"
        size="small"
      >
        <Shimmer count={5} loading={rcIsLoading}>
          {testSeries?.length
            ? testSeries?.slice(0, 10)?.map((item) => (
                <>
                  <a
                    href={`https://khanglobalstudies.com/test-series/${item?.slug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Row style={{ marginBottom: "8%" }} key={item?.id}>
                      <Col lg={10} xl={10} md={9} sm={8} xs={8}>
                        <img
                          alt="thumb"
                          src={item?.thumb ? item?.thumb : "/kgs.jpg"}
                          width={"100%"}
                          style={{
                            borderRadius: "0.6em",
                            border: "0.1em solid #D5D5D5",
                          }}
                        />
                      </Col>
                      <Col lg={13} xl={13} md={14} sm={15} xs={15} offset={1}>
                        <h4
                          className="card-content"
                          style={{
                            marginBottom: "0",
                            color: "black",
                            fontSize: "0.9em",
                            fontWeight: "600",
                          }}
                        >
                          {item?.title}
                        </h4>
                        <SubTitle>
                          {item.total_quizzes} Test | {item?.total_questions}{" "}
                          Questions
                        </SubTitle>
                      </Col>
                    </Row>
                  </a>
                </>
              ))
            : "Currently No Test Series Related to the Selected Category"}
        </Shimmer>
      </Card>
    </>
  );
};

export default RecommendedTestSeries;
