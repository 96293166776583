import React, { useState } from "react";
import Modal from "antd/es/modal/Modal";
import { Button, Card, Checkbox, message } from "antd";
import BubleSelect from "../component/BubleSelect";
import { TFeedBack } from "../pages/CoursePlayer";
import api from "../config/api";
import { useStorage } from "../hooks";
import { Url } from "url";
import { getKey } from ".";
import { IUser } from "../interfaces";
import ButtonGroup from "antd/es/button/button-group";
import { useAuthStore } from "../store/auth";

const FiveStar = [
  "Impressive Content",
  "Excellent App.",
  "Best Teacher",
  "Energetic Teacher",
];
const ThreeStar = [
  "Wrong concept discuss.",
  "Low Energy",
  "Smartboard notes irrelevant",
  "Steps Missed in explanation",
];
const TwoStar = [
  "Loading/ Blackscreen Issue. ",
  "Wrong Click.",
  "Bad Audio / Video Quality.",
  "I don’t like teacher",
  "Wrong Class Timing",
];

interface IProps {
  feedBack: TFeedBack | null;
  setFeedBack: (feedBack: TFeedBack | null) => void;
  isFeedBackSubmitted: boolean;
  course_name: string;
}

export default function FeedBackModal({
  feedBack,
  setFeedBack,
  isFeedBackSubmitted = false,
  course_name,
}: IProps) {
  const [, setLoading] = React.useState(false);
  const [rating, setRating] = React.useState(1);
  const [comment, setComment] = React.useState("");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [, setReviewStatus] = React.useState(false);
  // const [user] = useStorage<IUser>("AUTH_USER");
  const [feedBackSubmit, setFeedSubmit] = useStorage<string[]>("FEED_BACK", []);

  const { user } = useAuthStore();

  const [isChecked, setIsChecked] = useState(false);

  const handleSubmit = async () => {
    if (!feedBack) return;
    let payload: any = {
      comment: comment,
      rating,
      entity_type: feedBack.type,
      entity_id: feedBack.id,
      entity_name: feedBack.name,
      kgs_user_id: user?.id,
      name: user?.name,
      email: user?.email,
      phone: user?.phone,
      course_name: course_name,
    };

    if (selected.length) {
      payload["message"] = selected;
    }
    try {
      const { data } = await api.post(
        "https://squad.kgs.live/api/feedbacks",
        payload
      );
      if (data && data.status === true) {
        message.success("Your Feedback sent successfully!");
        setFeedSubmit([...feedBackSubmit, getKey(feedBack)]);
        setFeedBack(null);
        setReviewStatus(true);
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
    }
  };

  if (feedBack && feedBackSubmit.includes(getKey(feedBack)))
    return (
      <Modal
        open={Boolean(feedBack)}
        onCancel={() => setFeedBack(null)}
        footer={[
          <Button color="primary" onClick={() => setFeedBack(null)}>
            Close
          </Button>,
        ]}
        width={"60%"}
        centered
      >
        <div className="text-center my-4">
          <svg
            width="260"
            height="177"
            viewBox="0 0 260 177"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M56.5 1.5L80.5 33.5"
              stroke="#0F0F0F"
              stroke-width="2.4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M81.5 10.5L105.5 42.5"
              stroke="#167F71"
              stroke-width="2.4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M195.615 98.7696C195.615 140.914 161.452 175.077 119.308 175.077C77.1637 175.077 43 140.914 43 98.7696C43 56.6256 77.1637 22.4619 119.308 22.4619C161.452 22.4619 195.615 56.6256 195.615 98.7696Z"
              fill="#E3FFED"
            />
            <rect
              x="74"
              y="50"
              width="93"
              height="93"
              rx="46.5"
              fill="#26BFA6"
            />
            <path
              d="M102.417 96.4997L115.334 109.416L141.167 83.583"
              stroke="white"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M221 30.5L215.71 33.2812L216.72 27.3906L212.44 23.2188L218.355 22.3594L221 17L223.645 22.3594L229.56 23.2188L225.28 27.3906L226.29 33.2812L221 30.5Z"
              fill="#FCC202"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M253 88L260 102H246L253 88Z"
              fill="#167F71"
            />
            <circle cx="210" cy="74" r="6" fill="#472D2D" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9 119.5L3.70993 122.281L4.72025 116.391L0.440491 112.219L6.35497 111.359L9 106L11.645 111.359L17.5595 112.219L13.2798 116.391L14.2901 122.281L9 119.5Z"
              fill="#FF001E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M39 163L46 177H32L39 163Z"
              fill="#332DA1"
            />
            <circle cx="37" cy="132" r="4" fill="#167F71" />
            <circle cx="39" cy="44" r="6" fill="#FF6B00" />
          </svg>
        </div>
        <h2 className="text-center">Thank You</h2>
        <h3 className="text-center">
          Your Feedback has been submitted already.
        </h3>
      </Modal>
    );

  const onChange = async (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <Modal
      // title="Basic Modal"
      onCancel={() => setFeedBack(null)}
      onOk={() => handleSubmit()}
      open={Boolean(feedBack)}
      width={"60%"}
      centered
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <Card size="small">
        <BubleSelect
          setSelected={setSelected}
          selected={selected}
          data={startData[rating]}
        />
      </Card>

      <div style={{ marginTop: "1em" }}>
        <p style={{ marginBottom: "0%", fontWeight: "600" }}>Your Rating</p>
        <StarRating
          rating={rating}
          setRating={setRating}
          selected={selected}
          setSelected={setSelected}
        />
      </div>

      <div style={{ marginTop: "1em" }}>
        <p style={{ marginBottom: "0%", fontWeight: "600" }}>
          Additional Feedback
        </p>
        <textarea
          style={{
            width: "100%",
            borderRadius: "1em",
            padding: "1em",
          }}
          rows={5}
          onChange={(e) => setComment(e?.target?.value)}
          placeholder="If you have any additional feedback, please type it in here..."
        />
      </div>

      <Checkbox onChange={onChange}>
        I have read and accept the Privacy Policy.
      </Checkbox>
      <br />

      <Button
        disabled={isChecked === false}
        type="primary"
        onClick={() => handleSubmit()}
      >
        Submit Feedback
      </Button>
    </Modal>
  );
}

const startData = [TwoStar, TwoStar, TwoStar, ThreeStar, FiveStar, FiveStar];

const StarRating = ({ rating, setRating, selected, setSelected }) => {
  const stars = [0, 0, 0, 0, 0];
  return (
    <div className="p-2">
      {stars.map((s, i) => {
        return rating >= i + 1 ? (
          <i
            className="bx bxs-star"
            onClick={() => {
              setRating(i <= 1 ? 1 : i);
              setSelected([]);
            }}
            style={{ color: "#f8ec41", fontSize: "3em", cursor: "pointer" }}
          ></i>
        ) : (
          <i
            onClick={() => {
              setRating(i + 1);
              setSelected([]);
            }}
            className="bx bx-star"
            style={{ fontSize: "3em", color: "#97978F", cursor: "pointer" }}
          ></i>
        );
      })}
    </div>
  );
};
