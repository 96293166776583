import React from "react";
import AppMetaHeader from "../../component/AppMetaHeader";
import PageContainer from "../../component/Layout/PageContainer";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { useApi, useMount } from "../../hooks";
import styled from "styled-components";
import { Button, Table } from "antd";
import moment from "moment";
import { usePublicStore } from "../../store/usePublicStore";
import Loader from "../../component/loader";
import { PracticeData } from "./practiceData";
const PracticeQuizListing = () => {
  const navigator = useNavigate({ from: "/" });
  const { active } = usePublicStore();
  const { id } = useSearch({
    from: "/practice/$slug",
  });

  const { data, load, isLoading } = useApi(`quizzes?type=${id}`);

  useMount(() => {
    load();
  }, []);

  const dailyQuiz = active?.config?.daily_quiz;
  const currentQuiz = (dailyQuiz || PracticeData).find(
    (item) => item.type === id
  );

  const columns = [
    {
      title: <Title>Test Series</Title>,
      dataIndex: "title",
      key: "title",
      render: (_, { title, published_at }) => {
        return (
          <SubTitle>
            <h3
              style={{
                fontWeight: "600",
                marginBottom: "0%",
              }}
            >
              {title}
            </h3>
            <p style={{ color: "#757575" }}>
              {`Available on ${moment(published_at).format("Do MMM YYYY")}`}
            </p>
          </SubTitle>
        );
      },
      width: 200,
    },
    {
      title: <Title>Actions</Title>,
      dataIndex: "start",
      key: "start",
      render: (_, { id, published_at }) => {
        const published_date = new Date(published_at);
        const current_date = new Date();
        const quiz_available_today = current_date >= published_date;

        return (
          <SubTitle>
            <Button
              style={{ backgroundColor: " #2E3192", color: "white" }}
              disabled={!quiz_available_today}
              onClick={() =>
                navigator({ to: `/quizzes/${currentQuiz?.slug}/${id}/start` })
              }
            >
              Start now
            </Button>
          </SubTitle>
        );
      },
      width: 100,
    },
  ];

  return (
    <>
      <AppMetaHeader currentPageTitle="Practice" />
      <PageContainer
        currentPageTitle={currentQuiz?.name}
        title="Practice"
        url="/practice"
      >
        {isLoading ? (
          <Loader />
        ) : (
          <div
            style={{
              width: "100%",
              overflow: "auto",
              backgroundColor: "white",
            }}
          >
            <Table
              dataSource={data?.data}
              columns={columns}
              rowKey={(_, _index) => _index!}
              scroll={{ y: 500, x: 800 }}
            />
          </div>
        )}
      </PageContainer>
    </>
  );
};

const Title = styled.p`
  font-size: 1.2em;
  margin-bottom: 0%;
  padding: 1em 1em 1em 1em;
  font-weight: 600;
`;

const SubTitle = styled.p`
  font-size: 1em;
  margin-bottom: 0%;
  padding: 1em 1em 1em 1em;
  font-weight: 600;
`;

export default PracticeQuizListing;
