import { List } from "antd";
import React from "react";
import { useApi, useMount } from "../hooks";
import { IOrder } from "../interfaces";
import styled from "styled-components";
import { formatDateOnly } from "../helpers/help";

type TProps = { orderId: string; onClose: boolean };

const OrdersCard = ({ orderId, onClose }: TProps) => {
  const {
    data: allOrderDetails,
    load,
    isLoading,
  } = useApi<IOrder>(`/orders/${orderId}`);

  useMount(() => {
    load();
  }, [orderId]);

  const data = [
    {
      title: "Order Amount - ",
      response: `${allOrderDetails?.amount}`,
    },
    {
      title: "Order Status - ",
      response: `${
        allOrderDetails?.status === 0
          ? "In-Progress"
          : allOrderDetails?.status === 1
          ? "Paid"
          : allOrderDetails?.status === 2
          ? "Failed"
          : allOrderDetails?.status === 3
          ? "Attempt"
          : ""
      }`,
    },
    {
      title: "Payment ID - ",
      response: `${
        allOrderDetails?.gatway_id === null ? "N/A" : allOrderDetails?.gatway_id
      }`,
    },
    {
      title: "Payment Response ID - ",
      response: `${
        allOrderDetails?.payment_response_id === null
          ? "N/A"
          : allOrderDetails?.payment_response_id
      }`,
    },
    {
      title: "Payment At - ",
      response: `${
        allOrderDetails?.payment_at === null
          ? "N/A"
          : formatDateOnly(allOrderDetails?.payment_at)
      }`,
    },
    {
      title: "Order Medium - ",
      response: `${allOrderDetails?.medium == 0 ? "WEB" : "APP"}`,
    },
    {
      title: "Course Name - ",
      response: `${allOrderDetails?.course?.title}`,
    },
  ];

  return (
    <>
      <p
        style={{
          float: "right",
          cursor: "pointer",
          marginTop: "-3%",
          marginRight: "-2%",
        }}
        onClick={onClose}
      >
        X
      </p>
      <div className="orderDrawer">
        <Heading>Order Id: {allOrderDetails?.id}</Heading>
        <List
          className="orderDrawerList"
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item, index) => (
            <List.Item
              style={{ paddingLeft: "10%" }}
              extra={<div className="orderDrawerListItem">{item.response}</div>}
            >
              <List.Item.Meta title={item.title} />
            </List.Item>
          )}
        />
        <LinkContainer>
          <Link
            href={`https://admin2.khanglobalstudies.com/api/orders/${orderId}/invoice`}
            target="_blank"
          >
            Download Invoice
          </Link>
        </LinkContainer>
      </div>
    </>
  );
};

export default OrdersCard;

const Heading = styled.h3`
  margin-bottom: 3%;
  background-color: #edf4ff;
  padding: 3%;
`;

const LinkContainer = styled.div`
  text-align: center;
  margin-top: 30px;
`;

const Link = styled.a`
  background-color: #2e3192;
  color: white;
  padding: 10px;
  border-radius: 8px;
`;
