import React, { useState } from "react";
import { Table, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import useMount from "../../../hooks/useMount";
import styled from "styled-components";
import { useRouter } from "@tanstack/react-router";

interface DataType {
  key: string;
  rank: number;
  student: String;
  total_questions: number;
  attempt: number;
  score: number;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Rank",
    render: (_, __, index) => index + 1,
  },
  {
    title: "Student",
    dataIndex: "name",
  },
  {
    title: "Total Questions",
    dataIndex: "total",
  },
  {
    title: "Attempt",
    dataIndex: "attempt",
  },
  {
    title: "Score",
    dataIndex: "score",
  },
];

const TopLeader = ({ data }) => {
  const router = useRouter();
  const [leaders, setLeader] = useState([]);

  useMount(() => {
    const user = data?.leaders?.map((l) => {
      if (data?.user_quiz && l?.user_id === data.user_quiz?.user?.id) {
        l.status = "active";
      }
      return l;
    });
    if (data?.user_quiz && !user?.find((u) => u?.status == "active")) {
      user.push({ name: data?.user_quiz?.user?.name, ...data?.user_quiz });
    }
    setLeader(user);
  }, [data]);

  return (
    <Container>
      <Title>
        <span
          style={{ marginRight: "1%", cursor: "pointer" }}
          onClick={() => router.history.back()}
        >
          <Tooltip title="Exit" arrow={false} placement="top">
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.41 10.4064L2.83 5.99999L7.41 1.59359L6 0.23999L0 5.99999L6 11.76L7.41 10.4064Z"
                fill="#202224"
              />
            </svg>
          </Tooltip>
        </span>
        Top 10 Leader
      </Title>
      <SubContainer>
        <Table columns={columns} dataSource={leaders || []} bordered />
      </SubContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 0.5em;
`;

const SubContainer = styled.div`
  overflow-y: scroll;
  height: 62vh;
  padding: 2%;
`;

const Title = styled.p`
  font-weight: 600;
  background: #eaf0ff;
  padding: 1% 1% 1% 2%;
  border-radius: 0.3em 0.3em 0em 0em;
  margin-bottom: 0%;
  font-size: 1.2em;
`;

export default TopLeader;
