export default function BackIcon() {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.9448 12L0 6L5.9448 0L7 1.065L2.1104 6L7 10.935L5.9448 12Z"
        fill="#2E3192"
      />
    </svg>
  );
}
