export default function QuizIcon() {
  return (
    <>
      <path
        d="M10.6946 18.5425C10.6937 18.7917 10.6225 19.0366 10.4876 19.2539C10.3528 19.4712 10.1588 19.6537 9.92404 19.784C9.6893 19.9144 9.42162 19.9883 9.14644 19.9987C8.87125 20.0091 8.59768 19.9558 8.35171 19.8436L1.88867 16.9199C1.62221 16.7984 1.39813 16.6125 1.24127 16.3829C1.08441 16.1532 1.0009 15.8887 1 15.6188V1L9.8059 4.97631C10.0748 5.09891 10.3005 5.28705 10.4575 5.51953C10.6146 5.75201 10.6967 6.01956 10.6946 6.292V18.5425Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 1H15.5418C15.9704 1 16.3813 1.15402 16.6844 1.42817C16.9874 1.70233 17.1576 2.07416 17.1576 2.46188V15.6188C17.1576 16.0065 16.9874 16.3783 16.6844 16.6525C16.3813 16.9266 15.9704 17.0807 15.5418 17.0807H10.6946"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  );
}
