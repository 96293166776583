import React, { useState } from "react";
import AppMetaHeader from "../../component/AppMetaHeader";
import { Tabs } from "antd";
import BasicDetails from "./BasicDetails";
import AddressDetails from "./AddressDetails";
import EducationDetails from "./EducationDetails";
import PageContainer from "../../component/Layout/PageContainer";

const  Profile = () => {
  const [activeKey, setActiveKey] = useState("1");
  const [isClicked, setIsClicked]=useState(false);

  const handleTabChange = (key) => {
    if(key){
      setActiveKey(key);
      setIsClicked(true)
    }
  };
  

  const items = [
    {
      key: "1",
      label: <span className={activeKey==="1"? (isClicked? "tabs_1":"tabs_1"):""}>Basic Details</span>,
      children: <BasicDetails />,
    },
    {
      key: "2",
      label: <span className={activeKey==="2"? (isClicked? "tabs_1":"tabs_2"):""}>Address</span>,
      children: <AddressDetails />,
    },
    {
      key: "3",
      label: <span className={activeKey==="3"? (isClicked? "tabs_1":"tabs_2"):""}>Eductaional Details</span>,
      children: <EducationDetails />,
    },
  ];
  

  const getCurrentPageTitle = () => {
    const activeItem = items.find((item) => item.key === activeKey);
    return `Profile > ${activeItem ? activeItem.label.props.children : ""}`;
  };

  return (
    <>
    <AppMetaHeader currentPageTitle="My Profile" />
      <PageContainer
        currentPageTitle={getCurrentPageTitle()}
        transparent={true}
      >
        <Tabs
          items={items}
          defaultActiveKey="1"
          activeKey={activeKey}
          onChange={handleTabChange}
          size="small"
        />
      </PageContainer>
    </>
  );
};

export default Profile;
