import { Button, Card, Col, Drawer, Row, Tag } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useApi, useMount } from "../../hooks";
import { useAuthStore } from "../../store/auth";
import api from "../../config/api";
import showNotification from "../../utils/notify";
import { Pie } from "react-chartjs-2";
import IncorrectIcon from "../../component/icons/result/IncorrectIcon";
import AccuracyIcon from "../../component/icons/result/AccuracyIcon";
import ScoreIcon from "../../component/icons/result/ScoreIcon";
import CorrectIcon from "../../component/icons/result/CorrectIcon";
import ScoreInfo from "../../component/quiz/result/scoreInfo";
import ScoreCard from "./ScoreCard";
import AccuracyIcon2 from "../../component/icons/AccuracyIcon2";
import ScoreIcon2 from "../../component/icons/ScoreIcon2";
import { useMediaQuery } from "react-responsive";

const QuizRank = ({ id, disabled }) => {
  const [open, setOpen] = useState(false);
  const [resource, setResource] = useState();
  const [isRankLoading, setIsRankLoading] = useState(false);
  const [userRank, setUserRank] = useState(null);
  const { user } = useAuthStore();

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const fetchUserRank = async (id) => {
    setIsRankLoading(true);
    try {
      const { data } = await api.get(
        `user/${id}/quiz-rank?with_submission=true&phone=${user?.phone}`
        // &phone=${user?.phone}
      );
      if (data && data.submission) {
        setUserRank(data);
        setOpen(true);
      }
      if (!data.submission) {
        showNotification("error", "Sorry, we couldn't find your record.", 3);
      }
      setIsRankLoading(false);
    } catch (error) {
      setIsRankLoading(false);
    }
  };

  const showDrawer = (item) => {
    setOpen(true);
    setResource(item);
  };

  const onClose = () => {
    setOpen(false);
  };

  const accuracy =
    (userRank?.submission?.correct * 100) / userRank?.submission?.attempt;

  const inCorrect =
    userRank?.submission?.incorrect === null
      ? 0
      : userRank?.submission?.incorrect;

  const notAttempt =
    userRank?.submission?.total - (userRank?.submission?.correct + inCorrect);

  const data = {
    labels: ["Correct", "Incorrect", "Not Attempt"],
    datasets: [
      {
        data: [
          userRank?.submission?.correct,
          userRank?.submission?.incorrect,
          notAttempt,
        ],
        backgroundColor: ["#7FE47E", "#FF718B", "#FFEB3A"],
        borderWidth: 0,
      },
    ],
  };

  const resultData = [
    {
      title: "Correct",
      icon: <CorrectIcon />,
      value: userRank?.submission?.correct,
    },
    {
      title: "Incorrect",
      icon: <IncorrectIcon />,
      value:
        userRank?.submission?.incorrect === null
          ? 0
          : userRank?.submission?.incorrect,
    },
    {
      title: "Score",
      icon: <ScoreIcon2 />,
      value: userRank?.submission?.score,
    },
    {
      title: "Accuracy",
      icon: <AccuracyIcon2 />,
      value: accuracy.toFixed(2) + "%",
    },
  ];

  return (
    <>
      <TagButton
        loading={isRankLoading}
        onClick={() => {
          fetchUserRank(id);
        }}
        disabled={disabled}
      >
        Get Your Rank
      </TagButton>
      <Drawer
        title={"Your Score"}
        onClose={onClose}
        open={open}
        width={isMobile ? "100%" : "60%"}
      >
        {userRank && (
          <>
            <Card
              style={{
                width: "98%",
                boxShadow: "0px 2px 6px 0px #0D0A2C14",
                margin: "auto",
                marginTop: "3%",
              }}
              size="default"
              title={
                <Title>
                  {`Your Rank is ${userRank?.rank} out of ${userRank?.total}
                  Students`}
                </Title>
              }
            >
              <div>
                <Row align={"middle"}>
                  <Col lg={16} xl={16} md={24} sm={24} xs={24}>
                    <div style={{ padding: "2em" }}>
                      <Pie
                        data={data}
                        options={{
                          plugins: {
                            legend: {
                              position: "right",
                              labels: {
                                padding: 10,
                                usePointStyle: true,
                                font: {
                                  size: 15,
                                },
                              },
                            },
                          },
                          maintainAspectRatio: false,
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={8} xl={8} md={24} sm={24} xs={24}>
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Marks>
                        {userRank?.submission?.score}/
                        {userRank?.submission?.outof ||
                          userRank?.submission?.total}
                      </Marks>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>

            <div
              style={{
                width: "98%",
                margin: "auto",
                marginTop: "3%",
              }}
            >
              <Row gutter={24}>
                {resultData.map((item) => (
                  <Col lg={8} xl={6} md={24} sm={24} xs={24}>
                    <ScoreCard
                      icon={item.icon}
                      score={item.value}
                      title={item.title}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </>
        )}
      </Drawer>
    </>
  );
};

const TagButton = styled(Button)`
  background-color: #e2f1ff;
  color: #111827;
  border: none;
  // font-size: 1em;
  // padding: 0.5em 2em 0.5em 2em;
  // border-radius: 0.7em;
  // cursor: pointer;
`;

const Marks = styled.p`
  color: #2e3192;
  font-weight: 900;
  font-size: 2em;
`;

const Title = styled.p`
  color: #9291a5;
  font-weight: 400;
  font-size: 1.2em;
  margin-bottom: 0%;
  padding: 1em;
`;

export default QuizRank;
