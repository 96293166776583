export default function LectureCountIcon() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.34803 5.21753C8.34803 5.43364 8.17283 5.60885 7.95672 5.60885H4.56533C4.34921 5.60885 4.17401 5.43364 4.17401 5.21753C4.17401 5.00142 4.34921 4.82622 4.56533 4.82622H7.95672C8.17283 4.82622 8.34803 5.00142 8.34803 5.21753Z"
        fill="black"
      />
      <path
        d="M7.30453 7.30454C7.30453 7.52065 7.12932 7.69585 6.91321 7.69585H4.56533C4.34921 7.69585 4.17401 7.52065 4.17401 7.30454C4.17401 7.08843 4.34921 6.91322 4.56533 6.91322H6.91321C7.12932 6.91322 7.30453 7.08843 7.30453 7.30454Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.21749 0.521759C4.64118 0.521759 4.17399 0.988952 4.17399 1.56526H3.13048C2.55417 1.56526 2.08698 2.03246 2.08698 2.60877V10.4351C2.08698 11.0114 2.55417 11.4786 3.13048 11.4786H9.39151C9.96784 11.4786 10.435 11.0114 10.435 10.4351V2.60877C10.435 2.03246 9.96784 1.56526 9.39151 1.56526H8.34801C8.34801 0.988952 7.88083 0.521759 7.3045 0.521759H5.21749ZM7.56538 1.56526C7.56538 1.42119 7.44856 1.30439 7.3045 1.30439H5.21749C5.07341 1.30439 4.95661 1.42119 4.95661 1.56526V2.34789C4.95661 2.49197 5.07341 2.60877 5.21749 2.60877H7.3045C7.44856 2.60877 7.56538 2.49197 7.56538 2.34789V1.56526ZM8.34801 2.34789C8.34801 2.92421 7.88083 3.3914 7.3045 3.3914H5.21749C4.64118 3.3914 4.17399 2.92421 4.17399 2.34789H3.13048C2.9864 2.34789 2.8696 2.46469 2.8696 2.60877V10.4351C2.8696 10.5791 2.9864 10.6959 3.13048 10.6959H9.39151C9.53557 10.6959 9.65239 10.5791 9.65239 10.4351V2.60877C9.65239 2.46469 9.53557 2.34789 9.39151 2.34789H8.34801Z"
        fill="black"
      />
    </svg>
  );
}
