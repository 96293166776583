import { create } from "zustand";
import { persist } from "zustand/middleware";
import { IUser } from "../interfaces";
import api from "../config/api";


interface IAuth{
  token:string,user:IUser
}
interface IAuthStore {
  user:IUser|null
  isLoading:boolean
  token:string|null
  setIsLoading:(loading:boolean)=>void
  setUser:(user:IUser)=>void
  setToken:(token:string)=>void
  logout:()=>void
  login:(auth:IAuth)=>void
}

export const useAuthStore = create(
  persist<IAuthStore>(
    (set, get) => ({
      user: null,
      token:null,
      isLoading:false,
      setIsLoading:(loading)=>{
        set({isLoading:loading})
      },
      login:(auth)=>{
        set({...auth});
      },
      setUser: (user) => {
        set({ user });
      },
      setToken: async (token) => {
        set({ token });
        try{
          set({isLoading:true})
          const {data} = await api.get('user',{headers:{Authorization:`Bearer ${token}`}})
          set({isLoading:false})
          set({user:data})
        }catch(e:any){
          set({isLoading:false})
        }
      },
      logout:()=>{
        set({token:null,user:null})
      }
    }),
    {
      name: "AUTH_USER",
    }
  )
);
