import React from "react";
import { Col, Card } from "antd";
type Iprops = {
  title: string;
  score: number|string;
  icon: any;
};
const ScoreInfo = ({ title, score, icon }: Iprops) => {
  return (
    <Col xs={24} sm={24} md={8} lg={4} xl={4} style={{marginBottom:"20px"}}>
      <Card style={{width:"100%"}} >
        <p style={{ color: "#6E6E6E" }}>{title || "NA"}</p>
        <p
          style={{
            fontSize: "20px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly", 
            alignItems: "center", 
          }}
        >
          <div
            style={{
             
              display: "flex",
              alignItems: "center",
            }}
          >
            {icon}
          </div>
          <div>{score}</div>
        </p>
      </Card>
    </Col>
  );
};

export default ScoreInfo;
