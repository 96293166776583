import React from "react";
import {
  Card,
  Col,
  List,
  Row,
  Space,
  Skeleton,
  SkeletonProps,
  Table,
} from "antd";
import { ColumnsType } from "antd/lib/table";

const Vertical = ({ active }) => (
  <List.Item>
    <Skeleton.Image active={active} />
    <Skeleton
      style={{ marginLeft: 10 }}
      active={active}
      paragraph={{ rows: 1 }}
    />
  </List.Item>
);

export default function Shimmer({
  type = "home",
  count = 4,
  active = true,
  loading = true,
  children,
}) {
  const data = Object.keys(Array.from({ length: count }));

  if (!loading) return children;

  if (type === "home")
    return (
      <List
        dataSource={data}
        renderItem={(item) => <Vertical active={active} />}
      />
    );
  if (type === "test-series") {
    return (
      <Row gutter={[50, 30]}>
        {data.map((item) => (
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card>
              <Skeleton
                title={false}
                loading={loading}
                active
                avatar={{
                  shape: "square",
                  size: "large",
                  style: { marginRight: 10 },
                }}
              />
            </Card>
          </Col>
        ))}
      </Row>
    );
  }

  if (type === "doubts") {
    return (
      <Row gutter={[20, 20]}>
        {data.map((item) => (
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Space style={{ width: "100%" }} direction="vertical">
              <Card>
                <Skeleton
                  round
                  active={active}
                  title={false}
                  paragraph={{ rows: 4 }}
                />
                <Space>
                  <Skeleton.Input active={active} size={"small"} />
                  <Skeleton.Input active={active} size={"small"} />
                </Space>
              </Card>
            </Space>
          </Col>
        ))}
      </Row>
    );
  }

  if (type === "banner") {
    return (
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Space style={{ width: "100%" }} direction="vertical">
            <Skeleton.Image className="bannerShimmer" active={active} />
          </Space>
        </Col>
      </Row>
    );
  }

  if (type === "search") {
    return (
      <Row gutter={[20, 20]}>
        {data.map((item) => (
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Space style={{ width: "100%" }} direction="vertical">
              <Card size="small">
                <Space style={{ width: "100%" }}>
                  <Skeleton.Avatar size={"large"} shape={"square"} />
                  <Skeleton.Input size={"large"} shape={"square"} />
                </Space>
              </Card>
            </Space>
          </Col>
        ))}
      </Row>
    );
  }
  if (type === "downloads") {
    return (
      <Row gutter={[15, 15]}>
        {data.map((item) => (
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Space style={{ width: "100%" }} direction="vertical">
              <Card>
                <Skeleton
                  title={false}
                  loading={loading}
                  active
                  avatar={{
                    shape: "circle",
                    style: { marginRight: 10 },
                    size: 50,
                  }}
                />
              </Card>
            </Space>
          </Col>
        ))}
      </Row>
    );
  }

  return (
    <Row gutter={[20, 20]}>
      {data.map((item) => (
        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
          <Space style={{ width: "100%" }} direction="vertical">
            <Card style={{ gap: "20vh", width: "100%" }}>
              <Skeleton.Image className="fullWidthImage" active={active} />
              <Skeleton
                round
                active={active}
                title={false}
                style={{ width: "70%" }}
                paragraph={{ rows: 2, style: { marginTop: 10 } }}
              />
            </Card>
          </Space>
        </Col>
      ))}
    </Row>
  );
}

export type SkeletonTableColumnsType = {
  key: string;
};

type SkeletonTableProps = SkeletonProps & {
  rowCount?: number;
};

export function SkeletonTable({
  loading = false,
  active = false,
  rowCount = 5,
  // columns,
  children,
  className,
}: SkeletonTableProps): JSX.Element {
  const columns = children?.props?.columns as SkeletonTableColumnsType[];
  return loading ? (
    <Table
      rowKey="key"
      pagination={false}
      dataSource={[...Array(rowCount)].map((_, index) => ({
        key: `key${index}`,
      }))}
      columns={columns.map((column) => {
        return {
          ...column,
          render: function renderPlaceholder() {
            return (
              <Skeleton
                key={column.key}
                title={{ style: { margin: 10 } }}
                active={active}
                paragraph={false}
                className={className}
              />
            );
          },
        };
      })}
    />
  ) : (
    <>{children}</>
  );
}
