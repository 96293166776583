import { Button, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState, useRef, useEffect } from "react";
import api from "../../config/api";
import showNotification from "../../utils/notify";
import { useSearch } from "@tanstack/react-router";
import PageContainer from "../../component/Layout/PageContainer";
import parse from "html-react-parser";
import { useNavigate } from "@tanstack/react-router";
import styled from "styled-components";

const AttemptAnsWriting = () => {
  const textAreaRef = useRef();
  const [wordCount, setWordCount] = useState(0);
  const [ansWritten, setAnsWritten] = useState("");
  const [ansFile, setAnsFile] = useState<File>();
  const [subjective, setSubjective] = useState();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const userDetails = JSON.parse(window.localStorage.getItem("userInfo") || "");

  const { id } = useSearch({
    from: "/answer-writing/attempt-question",
  });

  const { qid } = useSearch({
    from: `/answer-writing/attempt-question`,
  });

  const navigate = useNavigate({ from: "/" });

  useEffect(() => {
    const fetchAnswerWriting = async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`subjectives/${qid}`);
        setSubjective(data?.questions);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchAnswerWriting();
  }, [qid]);

  const [formData, setFormData] = useState({
    ansWritten: "",
  });

  function onChangeFormData(e) {
    e.preventDefault();
    setWordCount(e.target.value.split(" ").length);
    setFormData((pre) => ({
      ...pre,
      [e.target.id]: setAnsWritten(e.target.value),
    }));
  }

  function onChange(e) {
    e.preventDefault();

    if (e.target.files) {
      if (e.target.files[0].size > 1048576) {
        showNotification("error", "The file uploaded is more than 1 MB", 3);
      } else {
        setAnsFile(e.target.files[0]);
        showNotification("success", "The file is ready to be upload", 3);
      }
    }
  }

  const onSubmit = async () => {
    const fd = new FormData();

    if (ansFile && ansWritten) {
      showNotification(
        "error",
        "Please choose either a file attachment OR enter a written answer, not both.",
        3
      );
    } else if (ansFile || ansWritten) {
      fd.append(ansFile ? "attachment" : "answer", ansFile || ansWritten);
      fd.append("name", userDetails.name);
      fd.append("phone", userDetails.phone);

      try {
        const res = await api.post(`subjectives-questions/${id}`, fd, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        showNotification(
          "success",
          "You have submitted your answer successfully.",
          3
        );
      } catch (error) {
        showNotification(
          "error",
          "Error submitting your answer. Please try again.",
          3
        );
      }
    } else {
      showNotification(
        "error",
        "Please provide either a file attachment or a written answer.",
        3
      );
    }
    setTimeout(() => {
      navigate({ to: "/submission" });
    }, 2000);
  };

  return (
    <>
      <PageContainer currentPageTitle="Attempt Answer">
        {/* <Card style={{ width: "70%", margin: "auto", marginTop: "2%" }}> */}
        <Button type="primary" onClick={showModal}>
          Open Modal
        </Button>

        {/* <Modal
       className="Attempt-Modal"
       width={1000}
       cancelButtonProps={{ style: { display: 'none' } }}
       okButtonProps={{ style: { display: 'none' } }}
       title="Attempt Answer" open={isModalOpen} 
      //  onOk={handleOk}
        onCancel={handleCancel}
        >
        <Question>Question:</Question>
        <P>
        {subjective
          ?.filter((i) => i.id === id)
          ?.map((item) => (
            <b>{parse(item?.q)}</b>
          ))}
          </P>  
        <div>
          <form>
            <Question>Write Your Answer Here:</Question>
            <TextArea
              maxLength={2000}
              rows={6}
              ref={textAreaRef}
              id="answer"
              onChange={onChangeFormData}
              placeholder="Enter Your Answer"
            />
            <P>
              Your Answer in Words:
              {wordCount > 250 ? "You have to do it in 250 Words" : wordCount}
            </P>
            <h2 className="ansWritingTextCenter">OR</h2>
            <h3>Upload Your Answer Here:</h3>
            <p style={{ color: "red" }}>
              Note: Answer is uploaded in PDF format and PDF is not more than 1
              MB
            </p>
            <input
              type="file"
              onChange={onChange}
              id="attachment"
              style={{ fontSize: "15px" }}
              accept=".pdf"
            />
            <br></br>
            <br></br>

            <Button size="large" type="primary" onClick={onSubmit}>
              Submit Answer
            </Button>
          </form>
        </div>
      
     
     </Modal> */}

        {/* </Card> */}
        {/* <h3>Question:</h3>
        {subjective
          ?.filter((i) => i.id === id)
          ?.map((item) => (
            <b>{parse(item?.q)}</b>
          ))}
        <div>
          <form>
            <h3>Write Your Answer Here:</h3>
            <TextArea
              maxLength={2000}
              rows={6}
              ref={textAreaRef}
              id="answer"
              onChange={onChangeFormData}
              placeholder="Enter Your Answer"
            />
            <p>
              Your Answer in Words:
              {wordCount > 250 ? "You have to do it in 250 Words" : wordCount}
            </p>
            <h2 className="ansWritingTextCenter">OR</h2>
            <h3>Upload Your Answer Here:</h3>
            <p style={{ color: "red" }}>
              Note: Answer is uploaded in PDF format and PDF is not more than 1
              MB
            </p>
            <input
              type="file"
              onChange={onChange}
              id="attachment"
              style={{ fontSize: "15px" }}
              accept=".pdf"
            />
            <br></br>
            <br></br>

            <Button size="large" type="primary" onClick={onSubmit}>
              Submit Answer
            </Button>
          </form>
        </div> */}
      </PageContainer>
    </>
  );
};

const Question = styled.h3`
  color: #2f2e41;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
const P = styled.p`
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export default AttemptAnsWriting;
