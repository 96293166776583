import { Button, Card } from "antd";
import React, { useState } from "react";
import { useApi, useMount } from "../../hooks";
import Loader from "../loader";
import CustomModal from "../common/CustomModal";
import { BottomContaner, H3 } from "../GlobalCard";
import parse from "html-react-parser";
import { TestContainer } from "../../pages/quiz/AllQuiz";
import AttemptAnswerWriting from "../../pages/submission/AttemptAnswerWriting";
import BackIcon from "../icons/BackIcon";

const CourseAW = ({ checked, id }) => {
  const [isModelOpen, setModelOpen] = useState(false);
  const [content, setContent] = useState();
  const { data, load, isLoading } = useApi(`subjectives/${id}`);

  useMount(() => {
    if (!id) return;
    load();
  }, [id]);

  const openModal = (content) => {
    setModelOpen(true);
    setContent(content);
  };

  const Back = "< Back";

  return (
    <>
      <p
        onClick={() => {
          checked(false);
        }}
        style={{
          fontSize: "1.2em",
          color: "#2E3192",
          cursor: "pointer",
          fontWeight: "500",
        }}
      >
        <BackIcon /> Back
      </p>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {data?.questions?.map((item: any, index) => (
            <>
              <Card hoverable style={{ marginBottom: "1%" }}>
                <BottomContaner>English</BottomContaner>
                <BottomContaner>Hindi</BottomContaner>
                <br />
                <div style={{ paddingTop: "1rem" }}>
                  <H3>Question {index + 1}</H3>
                </div>
                <TestContainer>{parse(item?.q)}</TestContainer>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <BottomContaner
                    style={{
                      borderRadius: "4px",
                      background: "#E2F1FF",
                      color: "#113D66",
                      border: "none",
                    }}
                    onClick={() => {
                      openModal(
                        <AttemptAnswerWriting
                          id={item?.id}
                          que={parse(item?.q)}
                        />
                      );
                    }}
                  >
                    Attempt Answer Writing
                  </BottomContaner>

                  {item?.a && (
                    <BottomContaner
                      onClick={() => openModal(parse(item?.a))}
                      style={{
                        borderRadius: "4px",
                        background: "#E2F1FF",
                        color: "#113D66",
                        border: "none",
                      }}
                    >
                      View Answer
                    </BottomContaner>
                  )}
                </div>

                <CustomModal
                  height="80vh"
                  ConfirmationText="Close"
                  onDone={() => setModelOpen(false)}
                  open={isModelOpen}
                  width={"80%"}
                  style={"modal-wrapper-answer"}
                  hideModal={() => setModelOpen(false)}
                  message={content}
                />
              </Card>
            </>
          ))}
        </>
      )}
    </>
  );
};

export default CourseAW;
