import React, { useEffect, useState } from "react";
import { useSearch } from "@tanstack/react-router";
import api from "../config/api";
import { useQuery } from "@tanstack/react-query";
import { Avatar, Card, Col, Image, Row, Tabs } from "antd";
import { useApi, useMount, useStorage } from "../hooks";
import CustomPlayer from "../component/player/CustomPlayer";
import { useAuthStore } from "../store/auth";
import { useMediaQuery } from "react-responsive";
import { kgsLogo } from "../utils/constants";
import BackButton from "../component/BackButton";
import Meta from "antd/es/card/Meta";
import PDFIcon from "../component/icons/PDFIcon";
import PdfViewer from "../utils/pdfViewer";
import DownloadIcon from "../component/icons/DownloadIcon";

const OpenLiveClass = () => {
  const [events, setEvents] = useStorage<any>("EVENTS", []);
  const [activeKey, setActiveKey] = React.useState("1");
  const [isClicked, setIsClicked] = React.useState(false);

  const { user } = useAuthStore();

  const [isPDF, setIsPDF] = useState(false);
  const handleTabChange = (key: any) => {
    setActiveKey(key);
    setIsClicked(true);
  };
  const isMobile = useMediaQuery({
    query: "(max-width: 769px)",
  });

  const UpcomingClasses = async () => {
    try {
      const { data } = await api.get(`videos/${vvid}`);
      return data;
    } catch (error) {}
  };

  const { data: allCourses, load, isLoading } = useApi(`all-courses`);

  useMount(() => {
    load();
  }, []);

  const { data } = useQuery({
    queryKey: ["allClasses"],
    queryFn: UpcomingClasses,
  });
  const currentLiveClass = data?.video;
  const courseId = currentLiveClass?.course_id;

  const courseName = allCourses?.find((item) => item?.id === courseId)?.title;

  const { vvid } = useSearch({
    from: "/live/class",
  });

  useEffect(() => {
    if (!currentLiveClass) return;
    setEvents([
      ...events,
      {
        title: `${currentLiveClass?.name}`,
        url: `/live/class?vvid=${vvid}`,
        time: new Date().toLocaleString(),
      },
    ]);
  }, [currentLiveClass]);

  let items = [
    {
      key: "1",
      label: (
        <span
          className={
            activeKey === "1" ? (!isClicked ? "tabs_1" : "tabs_1") : ""
          }
        >
          Supporting PDF
        </span>
      ),
      children: (
        <Card size="small">
          {currentLiveClass?.pdfs === null
            ? "No Supporting PDF "
            : currentLiveClass?.pdfs?.map((item) => (
                <a
                  rel="noreferrer"
                  key={item?.title}
                  href={item?.url}
                  target="_blank"
                >
                  <Card
                    style={{
                      border: "0.1em solid #C5C5C5",
                      marginBottom: "1%",
                    }}
                  >
                    <Meta
                      avatar={
                        <Avatar shape="square" size="large" src={<PDFIcon />} />
                      }
                      title={<>{item?.title}</>}
                      description={
                        <div
                          style={{
                            float: "right",
                            textAlign: "center",
                            color: "#373852",
                            marginBottom: "0%",
                            marginTop: "-2.5%",
                          }}
                        >
                          <DownloadIcon />
                          <p style={{ marginBottom: "0%" }}>Download</p>
                        </div>
                      }
                    />
                  </Card>
                </a>
              ))}
        </Card>
      ),
    },
  ];

  return (
    <>
      <div style={{ height: "100vh" }}>
        <Row
          align={"middle"}
          gutter={24}
          style={{ padding: "0.5em", height: "10vh" }}
        >
          <Col
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            style={{
              textAlign: "end",
            }}
          >
            <BackButton />
          </Col>
          <Col xl={23} lg={23} md={23} sm={23} xs={22}>
            <h3
              style={{ marginBottom: "0%" }}
              className={isMobile ? "card-content" : ""}
            >
              {currentLiveClass?.name}
            </h3>
            <h2
              style={{ marginBottom: "0%" }}
              className={isMobile ? "card-content" : ""}
            >
              {courseName}
            </h2>
          </Col>
        </Row>
        <Row style={{ width: "100%", height: "90vh" }}>
          <Col
            xl={18}
            lg={18}
            md={17}
            sm={24}
            xs={24}
            style={{
              height: isMobile ? "50%" : "100%",
              overflowY: isMobile ? "hidden" : "scroll",
            }}
            className="courseVideoScrollbar"
          >
            <CustomPlayer
              isLive={true}
              selectedVideo={currentLiveClass}
              onEnd={() => {}}
            />
            {currentLiveClass?.pdfs === null ? (
              ""
            ) : (
              <div style={{ width: "98%", margin: "auto" }}>
                <Tabs
                  // tabBarStyle={{
                  //   width: "100%",
                  //   paddingBottom: "0.5%",
                  //   position: "fixed",
                  //   zIndex: "1",
                  //   backgroundColor: "#EDF4FF",
                  // }}
                  items={items}
                  defaultActiveKey="1"
                  activeKey={activeKey}
                  onChange={handleTabChange}
                  size="small"
                />
              </div>
            )}
          </Col>
          <Col
            xl={6}
            lg={6}
            md={7}
            sm={24}
            xs={24}
            style={{
              height: isMobile ? "50%" : "100%",
            }}
          >
            <RenderChatList vvid={vvid} />
          </Col>
        </Row>
      </div>
    </>
  );
};
const RenderChatList = ({ vvid }: { vvid: string | null }) => {
  const { token } = useAuthStore();
  return (
    <div className="renderChat">
      <iframe
        title="live chat"
        src={`https://chat.kgs.live/?room=${vvid}&token=${token}`}
      ></iframe>
    </div>
  );
};
export default OpenLiveClass;
