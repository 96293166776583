export default function PhoneIcon() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1437_4922)">
        <path
          d="M11.8888 17.6429C12.7246 18.182 13.7205 18.417 14.7091 18.3083C15.6978 18.1995 16.6188 17.7537 17.3174 17.0457L17.9281 16.4486C18.1958 16.1747 18.3458 15.8069 18.3458 15.4239C18.3458 15.0409 18.1958 14.6732 17.9281 14.3993L15.336 11.8343C15.0644 11.5674 14.6989 11.4179 14.3181 11.4179C13.9373 11.4179 13.5718 11.5674 13.3002 11.8343V11.8343C13.0264 12.102 12.6586 12.252 12.2756 12.252C11.8926 12.252 11.5248 12.102 11.251 11.8343L7.17952 7.76287C7.0437 7.62902 6.93585 7.46951 6.86223 7.2936C6.78861 7.1177 6.7507 6.92891 6.7507 6.73823C6.7507 6.54754 6.78861 6.35875 6.86223 6.18285C6.93585 6.00694 7.0437 5.84743 7.17952 5.71358V5.71358C7.44641 5.44201 7.59596 5.07649 7.59596 4.69573C7.59596 4.31496 7.44641 3.94944 7.17952 3.67787L4.60095 1.0993C4.32709 0.831554 3.95931 0.681641 3.57631 0.681641C3.19331 0.681641 2.82553 0.831554 2.55167 1.0993L1.95452 1.71001C1.24657 2.40859 0.800729 3.32963 0.69198 4.31826C0.58323 5.30689 0.818201 6.30282 1.35738 7.13858C4.16673 11.2789 7.7413 14.8442 11.8888 17.6429V17.6429Z"
          stroke="#000001"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1437_4922">
          <rect width="19" height="19" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
