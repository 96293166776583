import React from "react";
import { Bar } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import { Chart } from "chart.js/auto";
import { Card, Row, Col } from "antd";
import moment from "moment";
import parse from "html-react-parser";
const DailyWatchRate = ({ week }) => {
  Chart.register(CategoryScale);
  const colorCode = "#2E3192";

  const staticData = [
    {
      date: new Date(),
      total: 3,
    },
  ];

  const date = (week?.length > 0 ? week : staticData)?.map((item) =>
    moment(item?.date).format("Do MMM")
  );

  const total = (week?.length > 0 ? week : staticData)?.map(
    (item) => item?.total
  );

  const staticTotal = 3;
  const state = {
    data: {
      labels: date, //["Sep1", "Sep2", "Sep3", "Sep4", "Sep5", "Sep6", "Sep7"],
      datasets: [
        {
          fill: true,
          label: null,
          backgroundColor: [
            "#2E3192",
            "#B2B4FE",
            "#B2B4FE",
            "#2E3192",
            "#B2B4FE",
            "#B2B4FE",
          ],
          borderSkipped: "start",
          borderRadius: "45",
          barThickness: "15",
          data: total, ///[70, 40, 55, 80, 55, 95, 65],
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
            barPercentage: 10,
          },
          beginAtZero: false,
          ticks: {
            color: colorCode,
          },
        },
        y: {
          grid: {
            display: false,
          },
          beginAtZero: true,
          ticks: {
            color: colorCode,
          },
        },
      },
    },
  };

  return (
    <Card style={{ width: "100%", height: "100%" }} size="small">
      <Row>
        <Col
          span={24}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Test Series </h3>
          <b>...</b>
        </Col>

        <Bar
          type={"bar"}
          height={190}
          data={state.data}
          options={state.options}
        />
      </Row>
    </Card>
  );
};

export default DailyWatchRate;
