import { Link } from "@tanstack/react-router";
import { Button, Col, Form, Input, Row, Typography } from "antd";
import React, { BaseSyntheticEvent, useRef, useState } from "react";
import api from "../config/api";
import HomeScreenSlider from "./HomeScreenSlider";
import showNotification from "../utils/notify";
import LogoContainer from "./LogoContainer";

const ForgetPassword = () => {
  const [loading, setLoading] = React.useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [message, setMessage] = useState();
  const [userValue, setUserValue] = useState();
  const [resendOTP, setResendOTP] = useState(false);
  const [otpMessage, setOtpMessage] = useState(false);
  const [isResetScreen, setIsResetScreen] = useState(false);
  const [otp, setOTP] = useState(["", "", "", ""]);
  const inputsRef = useRef([]);

  const onReset = async (changeValues: any) => {
    delete changeValues?.confirm;

    try {
      setLoading(true);
      const { data } = await api.post(`/reset-password`, changeValues);

      if (data?.status === true) {
        showNotification("success", data?.message, 3);
        window.location.href = "/";
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const getOtpTimer = (timer) => {
    const interval = setInterval(() => {
      timer = timer - 1;
      const ele = document.getElementById("timer");
      if (ele) {
        ele.innerText = timer;
      }
      if (timer === 0) {
        // setOtpValidityMessage(false);
        setResendOTP(true);
        setOtpMessage(false);
        clearInterval(interval);
      }
    }, 1000);
  };

  const handleResendOTP = async (otpValues) => {
    setUserValue(otpValues);
    const otpPayload = { phone: otpValues?.phone, type: "forgot" };
    try {
      setLoading(true);
      const { data } = await api.post(`/generate-otp`, otpPayload);
      getOtpTimer(60);
      setLoading(false);

      if (data?.status === true) {
        setOtpMessage(true);
        setIsVerify(true);
        setMessage(data?.message);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleOTPChange = (index, value) => {
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);

    // Auto-focus next input box
    if (value !== "" && index < otp.length - 1) {
      inputsRef.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      const newOtp = [...otp];
      newOtp[index - 1] = "";
      setOTP(newOtp);
      inputsRef.current[index - 1].focus();
    } else if (
      e.key === "Delete" &&
      index < otp.length - 1 &&
      otp[index] === ""
    ) {
      const newOtp = [...otp];
      newOtp[index + 1] = "";
      setOTP(newOtp);
      inputsRef.current[index + 1].focus();
    } else if (e.key === "ArrowLeft" && index > 0) {
      inputsRef.current[index - 1].focus();
    } else if (e.key === "ArrowRight" && index < otp.length - 1) {
      inputsRef.current[index + 1].focus();
    }
  };

  const onVerify = async (verifiedValues) => {
    const verifyPayload = {
      phone: verifiedValues?.phone,
      otp: otp.join(""),
      type: "FORGOT_OTP",
    };

    if (otp.join("").length < 4) {
      showNotification("error", "Please fill all the fields", 3);
    } else {
      try {
        setLoading(true);
        const { data } = await api.post(`/verify-phone`, verifyPayload);
        if (data?.status === true) {
          setIsVerify(false);
          setIsResetScreen(true);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  return (
    <div className="user-form-wrapper">
      <Row align="middle" justify="center" style={{ height: "100%" }}>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={0}
          xs={0}
          style={{ backgroundColor: "#EEF6FF" }}
          className=" wh-100"
        >
          <HomeScreenSlider />
        </Col>

        {isVerify ? (
          <>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
              className="bg-white wh-100"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="responsive-card-width">
                <LogoContainer />
                <Typography.Title level={4}>
                  Phone Verification
                </Typography.Title>
                <Typography.Title level={5}>
                  {message}. Enter 4 digit OTP that you have received.
                </Typography.Title>
                <Form
                  name="verify_form"
                  initialValues={{
                    remember: true,
                  }}
                  size="large"
                  onFinish={onVerify}
                  layout="vertical"
                  style={{ height: "100%" }}
                >
                  <Form.Item
                    hidden
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Phone!",
                      },
                      {
                        pattern: /^[0-9]{10}$/,
                        message: "Enter Valid Phone Number",
                      },
                    ]}
                    label="Phone Number"
                    initialValue={userValue?.phone}
                  >
                    <Input
                      disabled={true}
                      maxLength={10}
                      onInput={(e: BaseSyntheticEvent) =>
                        (e.target.value = e.target.value.slice(0, 10))
                      }
                      placeholder="Phone"
                      type="number"
                    />
                  </Form.Item>

                  <Form.Item
                    name="otp"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter OTP in required field!",
                      },
                    ]}
                  >
                    <div style={{ textAlign: "center" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {otp.map((digit, index) => (
                          <Input
                            key={index}
                            ref={(el) => (inputsRef.current[index] = el)}
                            style={{
                              width: "3em",
                              height: "3em",
                              marginRight: "1.5em",
                              textAlign: "center",
                            }}
                            maxLength={1}
                            value={digit}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const numericValue = inputValue.replace(
                                /\D/g,
                                ""
                              );
                              handleOTPChange(index, numericValue);
                            }}
                            onKeyDown={(e) => handleKeyDown(index, e)}
                          />
                        ))}
                      </div>
                    </div>
                  </Form.Item>

                  {otpMessage && (
                    <p
                      style={{
                        color: "red",
                        textAlign: "center",
                        fontSize: "1.2em",
                      }}
                    >
                      <span id="timer"></span>
                    </p>
                  )}

                  <Form.Item>
                    <Button
                      loading={loading}
                      disabled={loading}
                      type="primary"
                      htmlType="submit"
                      className="user-form-button"
                      size="large"
                    >
                      Verify
                    </Button>
                  </Form.Item>
                </Form>

                {resendOTP && (
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "1.2em",
                      color: "#7B8389",
                    }}
                  >
                    If you didn’t receive OTP!{" "}
                    <span
                      style={{
                        color: "#F2451C",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleResendOTP(userValue);
                        setResendOTP(false);
                      }}
                    >
                      Resend
                    </span>
                  </p>
                )}
              </div>
            </Col>
          </>
        ) : isResetScreen ? (
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={24}
            xs={24}
            className="bg-white wh-100"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="responsive-card-width">
              <LogoContainer />
              <Typography.Title level={4}>Reset Your Password</Typography.Title>
              <Form
                name="login_form"
                initialValues={{
                  remember: true,
                }}
                size="large"
                onFinish={onReset}
                layout="vertical"
                style={{ height: "100%" }}
              >
                <Form.Item
                  hidden
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Phone!",
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Enter Valid Phone Number",
                    },
                  ]}
                  label="Phone Number"
                  initialValue={userValue?.phone}
                >
                  <Input
                    disabled={true}
                    maxLength={10}
                    onInput={(e: BaseSyntheticEvent) =>
                      (e.target.value = e.target.value.slice(0, 10))
                    }
                    placeholder="Phone"
                    type="number"
                  />
                </Form.Item>

                <Form.Item
                  hidden
                  name="otp"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter OTP!",
                    },
                    {
                      pattern: /^[0-9]{4}$/,
                      message: "Enter Valid OTP",
                    },
                  ]}
                  initialValue={otp?.join("")}
                  label="Enter OTP"
                >
                  <Input
                    disabled={true}
                    maxLength={6}
                    onInput={(e: BaseSyntheticEvent) =>
                      (e.target.value = e.target.value.slice(0, 4))
                    }
                    placeholder="Enter OTP"
                    type="number"
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="New Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    {
                      // pattern:
                      //   /^(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                      // message:
                      //   "Password must be 8 characters including one uppercase,lowercase,special character and alphanumeric characters.",
                      pattern: /^.{6,}$/,
                      message: "Password must have 6 characters.",
                    },
                  ]}
                >
                  <Input.Password placeholder="Please enter your new password" />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label="Confirm New Password"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The new password that you entered does not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Please enter your new password again" />
                </Form.Item>

                <Form.Item>
                  <Button
                    loading={loading}
                    disabled={loading}
                    type="primary"
                    htmlType="submit"
                    className="user-form-button"
                    size="large"
                  >
                    Reset Password
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        ) : (
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={24}
            xs={24}
            className="bg-white wh-100"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="responsive-card-width">
              <LogoContainer />
              <Typography.Title level={4}>Forget Password!</Typography.Title>
              <Typography.Paragraph>
                Please Enter your Phone number for the verification process.
              </Typography.Paragraph>
              <Form
                name="login_form"
                initialValues={{
                  remember: true,
                }}
                size="large"
                onFinish={handleResendOTP}
                layout="vertical"
                style={{ height: "100%" }}
              >
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Phone!",
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Enter Valid Phone Number",
                    },
                  ]}
                  label="Phone Number"
                >
                  <Input
                    size="large"
                    maxLength={10}
                    onInput={(e: BaseSyntheticEvent) =>
                      (e.target.value = e.target.value.slice(0, 10))
                    }
                    placeholder="Phone"
                    type="number"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    loading={loading}
                    disabled={loading}
                    type="primary"
                    htmlType="submit"
                    className="user-form-button"
                    size="large"
                  >
                    Send OTP
                  </Button>
                </Form.Item>
              </Form>
              <div className="text-center">
                Don't have an Account? <Link to="/register">Sign Up</Link>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default ForgetPassword;
