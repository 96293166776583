import React from "react";
import { IQuality, IRATE } from "../../interfaces/player";
import { SettingMode } from "./playerSettings";

interface IProps {
  multiple?: Boolean;
  quality: IQuality;
  rate: IRATE;
  setSetting:(setting: SettingMode) => void;
}

export default function SettingModal({
  multiple = true,
  quality,
  rate,
  setSetting
}: IProps) {
  return (
    <ul>
      {multiple && (
        <li onClick={() => setSetting(SettingMode.QUALITY)}>
          <i
            className="bx bx-abacus"
            style={{
              color: "#ffffff",
              marginRight: "0.5em",
              fontSize: "1.2em",
            }}
          ></i>
          <div>Video Quality</div>
          <div style={{ marginLeft: "1em", fontSize: "0.9em" }}>{quality}</div>
          <div style={{ marginLeft: "0.5em" }}>{`>`}</div>
        </li>
      )}
      <li
        onClick={() => {
            setSetting(SettingMode.SPEED);
        }}
      >
        <i
          className="bx bxs-stopwatch"
          style={{
            color: "#ffffff",
            marginRight: "0.3em",
            fontSize: "1.2em",
          }}
        ></i>
        <div>Playback Rate</div>
        <div style={{ marginLeft: "1em", fontSize: "0.9em" }}>{`${rate.name}`}</div>
        <div style={{ marginLeft: "0.5em" }}>{`>`}</div>
      </li>
    </ul>
  );
}
