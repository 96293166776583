import React from "react";
import { useApi, useMount } from "../../hooks";
import { Avatar, Button, Card } from "antd";
import Meta from "antd/es/card/Meta";
import QuizIcon from "../../component/icons/QuizIcon";
import Loader from "../../component/loader";
import BackIcon from "../../component/icons/BackIcon";

const DPTQuiz = ({ id, checked }) => {
  const {
    data: allDPT,
    load: loadAllDPT,
    isLoading: loadingAllDPT,
  } = useApi<any>(`/dpps/${id}`);

  useMount(() => {
    loadAllDPT();
  }, [id]);

  return loadingAllDPT ? (
    <Loader />
  ) : (
    <>
      <p
        onClick={() => {
          checked(false);
        }}
        style={{
          fontSize: "1.2em",
          color: "#2E3192",
          cursor: "pointer",
          fontWeight: "500",
        }}
      >
        <BackIcon /> Back
      </p>
      {allDPT?.quizzes?.map((item) => (
        <a
          rel="noreferrer"
          key={item?.id}
          href={`/quizzes/dpt/${item.id}/start`}
          target="_blank"
        >
          <Card style={{ marginBottom: "2%" }} size="small">
            <Meta
              avatar={
                <Avatar
                  src={
                    <div style={{ marginTop: "16%" }}>
                      <QuizIcon />
                    </div>
                  }
                  size={"large"}
                  shape="circle"
                  style={{
                    backgroundColor: "#ECEDFF",
                    marginRight: "1em",
                  }}
                />
              }
              title={item?.title}
            />
          </Card>
        </a>
      ))}
    </>
  );
};

export default DPTQuiz;
