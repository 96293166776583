import React, { useEffect, useState } from "react";
import api from "../../config/api";
import { Col, Drawer, Row } from "antd";
import PaginationComp from "../../utils/PaginationComp";
import PdfViewer from "../../utils/pdfViewer";
import DownloadCard from "./DownloadCard";
import OtherIcon from "../../component/icons/OtherIcon";
import Shimmer from "../../component/Shimmer";
import AppMetaHeader from "../../component/AppMetaHeader";
import PageContainer from "../../component/Layout/PageContainer";

const Other = () => {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState();
  const [perPage, setPerPage] = useState();
  const [loading, setLoading] = useState(false);
  const [otherData, setOtherData] = useState();
  const [open, setOpen] = useState(false);
  const [resource, setResource] = useState();

  useEffect(() => {
    const fetchOthersData = async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`/resources/4?page=${page}`);
        setTotal(data?.total);
        setPerPage(data?.per_page);
        setOtherData(data?.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchOthersData();
  }, [page]);

  const showDrawer = (item) => {
    setOpen(true);
    setResource(item);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppMetaHeader currentPageTitle="Others" />

      <PageContainer
        currentPageTitle="Others"
        title="Study Materials"
        url="/study-materials"
      >
        <Shimmer type="downloads" loading={loading} count={4}>
          <Row gutter={[15, 15]} style={{ marginBottom: "2%" }}>
            {otherData?.map((item) => (
              <Col
                xxl={6}
                xl={6}
                lg={8}
                md={12}
                sm={12}
                xs={24}
                key={item?.name}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    showDrawer(item);
                  }}
                >
                  <DownloadCard title={item?.name} avatar={<OtherIcon />} />
                </div>
              </Col>
            ))}
          </Row>

          <PaginationComp
            page={page}
            total={total}
            perPage={perPage}
            onChange={(page) => setPage(page)}
          />
        </Shimmer>

        <Drawer
          title={resource?.name}
          onClose={onClose}
          open={open}
          width="70%"
        >
          <PdfViewer pdfUrl={resource?.url} height={"700px"} />
        </Drawer>
      </PageContainer>
    </>
  );
};

export default Other;
