import { RootRoute, Route, Router } from "@tanstack/react-router";
import Home from "./pages/Home";
import Profile from "./pages/profile/Profile";
import React from "react";
import App from "./App";
import TestSeriesListing from "./pages/quiz/TestSeriesListing";
import CourseListing from "./pages/myCourses/CourseListing";
import LiveClasses from "./pages/LiveClasses";
import StartQuiz from "./pages/quiz/StartQuiz";
import QuizzesType from "./pages/quiz/QuizzesType";
import MySubmission from "./pages/submission/MySubmission";
import MyWriting from "./pages/submission/MyWriting";
import CoursePlayer from "./pages/CoursePlayer";
import AnsWritingListing from "./pages/submission/AnsWritingType";
import AnsWritingQue from "./pages/submission/AnsWritingQue";
import AttemptAnsWriting from "./pages/submission/AttemptAnsWriting";
import OpenLiveClass from "./pages/OpenLiveClass";
import Orders from "./pages/Orders";
import AskDoubtListing from "./pages/doubts/AskDoubtListing";
import RecommendCourseListing from "./pages/RecommendCourseListing";
import TestScreen from "./TestScreen";
import QuizResult from "./pages/quiz/QuizResult";
import AttemptQuestion from "./pages/quiz/attemptQuestion";
import Notification from "./pages/Notification";
import ChangePassword from "./pages/changePassword";
import DownloadListing from "./pages/downloads/DownloadListing";
import SubjectListing from "./pages/downloads/SubjectListing";
import ContentListing from "./pages/downloads/ContentListing";
import PYQListing from "./pages/downloads/pyqs/PYQListing";
import Practice from "./pages/practice/Practice";
import PracticeQuizListing from "./pages/practice/PracticeQuizListing";
import NcertBooks from "./pages/downloads/NcertBooks";
import PYQ from "./pages/downloads/PYQ";
import CAListing from "./pages/downloads/CAListing";
import MindMap from "./pages/downloads/MindMap";
import Infographics from "./pages/downloads/Infographics";
import Other from "./pages/downloads/Other";
import Response from "./pages/submission/Response";
import RecommendedTestSeriesListing from "./pages/RecommendedTestSeriesListing";
import Checkout from "./pages/Checkout";
import PaymentStatus from "./pages/PaymentStatus";
const rootRoute = new RootRoute({
  component: () => <App />,
});

const indexRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/",
  component: () => <Home />,
});

const dashboardRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/home",
  component: () => <Home />,
});

const testRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/test",
  component: () => <TestScreen />,
});

const quizRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "quizzes",
  component: () => <TestSeriesListing />,
});

const mySubmission = new Route({
  getParentRoute: () => rootRoute,
  path: "submission",
  component: () => <MySubmission />,
});

const answerWriting = new Route({
  getParentRoute: () => rootRoute,
  path: "submission/$id",
  component: () => <MyWriting />,
});

const quizSlugRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "quizzes/$quizzesType",
  component: () => <QuizzesType />,
});

const quizStartRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "quizzes/$quizzesType/$qId/start",
  component: () => <StartQuiz />,
});

const attemptRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "$quizzesType/$qId/quiz",
  component: () => <AttemptQuestion />,
});

const leaderRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/quizzes/$quizzesType/$qId/result",
  component: () => <QuizResult />,
});

const profileRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/profile",
  component: () => <Profile />,
});

const courseRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/courses",
  component: () => <CourseListing />,
});

const coursePlayerRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/courses/$courseId/play",
  component: () => <CoursePlayer />,
});

const liveRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/live",
  component: () => <LiveClasses />,
});

const liveClassRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/live/class",
  component: () => <OpenLiveClass />,
});

const answerWritingRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/answer-writing/$slug",
  component: () => <AnsWritingListing />,
});

const answerWritingQueRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/answer-writing/question",
  component: () => <AnsWritingQue />,
});

const attemptAnsWriting = new Route({
  getParentRoute: () => rootRoute,
  path: "/answer-writing/attempt-question",
  component: () => <AttemptAnsWriting />,
});
const ordersRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/orders",
  component: () => <Orders />,
});

const askDoubtRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/doubts",
  component: () => <AskDoubtListing />,
});

const recommendCourseRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/recommended-courses",
  component: () => <RecommendCourseListing />,
});

const notificationRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/notification",
  component: () => <Notification />,
});

const ChangePasswordRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/change-password",
  component: () => <ChangePassword />,
});

const downloadRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/study-materials",
  component: () => <DownloadListing />,
});

const subjectListingRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/study-materials/ncert-book-pdf",
  component: () => <SubjectListing />,
});

const contentListingRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/study-materials/ncert-book-pdfs",
  component: () => <ContentListing />,
});

const pyqListingRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/study-materials/pyqs",
  component: () => <PYQListing />,
});

const practiceRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/practice",
  component: () => <Practice />,
});

const practiceListingRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/practice/$slug",
  component: () => <PracticeQuizListing />,
});

const ncertbookRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/study-material/ncert-books",
  component: () => <NcertBooks />,
});
const pyqRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/study-material/pyq",
  component: () => <PYQ />,
});
const currentAffairsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/study-material/current-affairs-mcq-pdf",
  component: () => <CAListing />,
});
const mindmapRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/study-material/mind-maps",
  component: () => <MindMap />,
});
const infographicsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/study-material/infographics",
  component: () => <Infographics />,
});
const otherRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/study-material/others",
  component: () => <Other />,
});
const responseRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/answer-writing/responses",
  component: () => <Response />,
});

const recommendedTestSeriesRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/test-series",
  component: () => <RecommendedTestSeriesListing />,
});

const checkoutRoutes = new Route({
  getParentRoute: () => rootRoute,
  path: "/checkout",
  component: () => <Checkout />,
});

const paymentStatusRoutes = new Route({
  getParentRoute: () => rootRoute,
  path: "/payment-status",
  component: () => <PaymentStatus />,
});

const routeTree = rootRoute.addChildren([
  testRoute,
  indexRoute,
  dashboardRoute,
  courseRoute.addChildren([coursePlayerRoute]),
  liveRoute,
  profileRoute,
  leaderRoute,
  attemptRoute,
  answerWritingRoute,
  answerWritingQueRoute,
  attemptAnsWriting,
  liveClassRoute,
  ordersRoute,
  askDoubtRoute,
  recommendCourseRoute,
  notificationRoute,
  downloadRoute,
  ChangePasswordRoute,
  subjectListingRoute,
  contentListingRoute,
  pyqListingRoute,
  practiceRoute,
  practiceListingRoute,
  ncertbookRoute,
  pyqRoute,
  currentAffairsRoute,
  mindmapRoute,
  infographicsRoute,
  otherRoute,
  responseRoute,
  recommendedTestSeriesRoute,
  checkoutRoutes,
  paymentStatusRoutes,
  mySubmission.addChildren([answerWriting]),
  quizRoute.addChildren([quizSlugRoute.addChildren([quizStartRoute])]),
]);

const router = new Router({
  routeTree,
  defaultPreload: "intent",
});

export default router;
