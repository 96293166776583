import { Button, Card, Form, Input, Select, Row, Col } from "antd";
import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import api from "../../config/api";
import { BATCHES, EDUCATION, GENDER } from "../../utils/constants/data";
import { IUser } from "../../interfaces/user.type";
import styled from "styled-components";
import showNotification from "../../utils/notify";

const BasicDetails = () => {
  const [loading, setLoading] = React.useState(false);
  const [isEmailDisabled, setIsEmailDisabled] = useState(false);
  const [form] = Form.useForm();
  const [isEmail, setIsEmail] = useState(false);

  const fetchUser = async () => {
    try {
      const { data } = await api.get<IUser>(`user`);
      if (data) {
        const { profile, name, email, phone } = data;
        const { gender, interested_in, education } = profile || {};
        if (email) setIsEmail(true);

        let gen: string;
        if (gender === "fe") gen = "female";
        else if (gender === "ma" || gender === "M") gen = "male";
        else gen = "others";
        form.setFieldsValue({
          name,
          gender: gen,
          interested_in: interested_in,
          education,
          email,
          phone,
        });
        setIsEmailDisabled(!!email);
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const onFinish = async (values: any) => {
    isEmail && delete values?.email;
    try {
      setLoading(true);
      await api.post(`/user`, values);
      showNotification("success", "Profile Updated Successfully");
      setLoading(false);
      setTimeout(() => {
        window.location.href = "/profile";
      }, 1000);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
        <Card>
          <H3>Basic Details</H3>
          <P>Submit your Basic Details in below fields</P>
          {/* <UploadImage /> */}
          <Form
            layout="vertical"
            name="update-profile"
            initialValues={{
              remember: true,
            }}
            form={form}
            onFinish={onFinish}
            style={{ width: "90%" }}
          >
            <Form.Item
              name="name"
              label={"Name"}
              rules={[
                {
                  required: true,
                  message: "Please input your Name!",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder="Name"
                type="text"
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="gender"
              label="Select Gender"
              rules={[
                {
                  required: true,
                  message: "Please input your Gender!",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Please select your gender"
                defaultValue={[]}
                options={GENDER}
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="interested_in"
              label="Interested"
              rules={[
                {
                  required: true,
                  message: "Please input your Interested!",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Please select your interest"
                defaultValue={[]}
                options={BATCHES}
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="education"
              label="Highest Education"
              rules={[
                {
                  required: true,
                  message: "Please input your Education!",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Please select your Education"
                defaultValue={[]}
                options={EDUCATION}
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="email"
              label={"Email Address"}
              rules={[
                {
                  required: true,
                  message: "Please input your Email!",
                },
              ]}
            >
              <Input
                disabled={isEmailDisabled}
                prefix={<UserOutlined />}
                placeholder="Email"
                type="text"
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="phone"
              label={"Phone Number"}
              rules={[
                {
                  required: false,
                  message: "Please input your Phone!",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder="Phone"
                disabled={true}
                onInput={(e: BaseSyntheticEvent) =>
                  (e.target.value = e.target.value.slice(0, 10))
                }
                type="number"
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <Button
                loading={loading}
                disabled={loading}
                type="primary"
                htmlType="submit"
                className="user-form-button"
                size="large"
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export const H3 = styled.h3`
  color: #2f2e41;
  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
export const P = styled.p`
  color: #2f2e41;
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export default BasicDetails;
