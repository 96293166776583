import api from "../config/api";

const option = {
  autoplay: false,
  controls: true,
  fill: true,
  responsive: true,
  fluid: true,
  playbackRates: [0.75, 1, 1.25, 1.5, 1.75],
  liveTracker: { trackingThreshold: 50, liveTolerance: 50 },
  controlBar: {
    liveDisplay: true,
    playbackRateMenuButton: true,
    currentTimeDisplay: true,
    pictureInPictureToggle: false,
  },
  sources: [],
};

class UniPlayer {
  is_live;
  video_format;
  player_type;
  video_url;
  options;
  is_live;
  message = null;
  thumb;
  hd_video_url;
  lessons;

  constructor(video) {
     const video_format = UniPlayer.getFormat(video.video_url)
    // all these are initial  options needed to  be set
    this.is_live = video.is_live;
    this.video_format = video_format;
    this.player_type = video.type || "";
    this.video_url = video.video_url;
    this.thumb = video.thumb;
    this.hd_video_url = video?.hd_video_url;

    const opt = video.type === "yt" ? option: {
            ...option,
            sources: [
              {
                src: video.video_url,
                type: video_format,
              },
            ],
          };
    // option.poster = thumb;
    this.options = opt;
    return this;
  }

  set Message(msg) {
    this.message = msg;
  }

  get Message() {
    return this.message;
  }

  get Video() {
    return this.lessons;
  }

  static async fetchLessons(vvid, is_Live, slug) {
    try {
      let player;
      const { data } = await api.get(`/user/courses/${slug}/lessons`);
      this.lessons = data.lessons;
      let selectedVideo;
      if (!is_Live) {
        for (const lesson in data.lessons) {
          const video = data.lessons[lesson].videos.find((v) => v.id === vvid);
          if (video) {
            selectedVideo = video;
            continue;
          }
        }
      } else {
        selectedVideo = data?.lessons[0]?.videos[0];
      }

      if (!selectedVideo) {
        player = new UniPlayer(is_Live, "", "", "", option);
        player.Message =
          data?.lessons?.length === 0
            ? "This Course will be live soon on mentioned date"
            : "Video is missing!";
        return {
          course: { ...data.course, notes: data.notes },
          lessons: data.lessons,
          player,
        };
      }
      const { type, video_url, is_live, thumb } = selectedVideo;
      const video_format = this.getFormat(video_url);
      player = new UniPlayer(
        is_live,
        video_url,
        video_format,
        type,
        option,
        thumb,
        selectedVideo?.hd_video_url
      );
      return {
        course: { ...data.course, notes: data.notes },
        lessons: data.lessons,
        player,
      };
    } catch (error) {}
  }

  static async fetchLiveClass(vvid) {
    try {
      let player;
      const { data } = await api.get(`/videos/${vvid}`);
      const { course, video } = data;
      const video_format = this.getFormat(video?.video_url);

      player = new UniPlayer(
        true,
        video.video_url,
        video_format,
        video.type,
        option,
        video.thumb,
        video?.hd_video_url
      );
      return { course, player };
    } catch (error) {}
  }

  static async fetchVideos(is_live, vvid, slug) {
    // if (is_live) {
    //   const props = await this.fetchLiveClass(vvid);
    //   return props;
    // } else {
      const props = await this.fetchLessons(vvid, is_live, slug);
      return props;
    // }
  }

  // if it is not live we have to fetch lessons and make video selector , if it is live only one video will get selected

  static getFormat(video_url) {
    switch (true) {
      case video_url?.includes(".mp4"):
        return "video/mp4";
      case video_url?.includes("m3u8"):
        return "application/x-mpegURL";
      case video_url?.includes("webm"):
        return "video/webm";
      case video_url?.includes("pdf"):
        return "pdf";
      default:
        return "";
    }
  }

  get playerType() {
    return this.player_type;
  }

  get playerOptions() {
    return this.options;
  }

  get videoUrl() {
    return this.video_url;
  }

  // error message need to be set
  set displayMessage(msg) {
    this.message = msg;
  }

  set changeVideo(video) {
    if (!video) {
      this.Message = "Video is Missing!";
      this.videoUrl = "";
      return;
    }
    this.player_type = video.type;
    this.video_url = video.video_url;
    const video_format = UniPlayer.getFormat(video.video_url);
    if (video.type === "url") {
      const option = {
        ...this.options,
        sources: [
          {
            src: video.video_url,
            type: video_format,
          },
        ],
      };
      this.options = option;
    }
  }

  set videoFormat(format) {
    this.video_format(format);
  }
  get format() {
    return this.video_format;
  }
  set videoUrl(url) {
    this.video_url = url;
  }
  get thumbImage() {
    return this.thumb;
  }
  set playerType(player_type) {
    this.player_type = player_type;
  }
  set isLive(is_live) {
    this.is_live = is_live;
  }
}

export default UniPlayer;
