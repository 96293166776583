import { Col, Drawer, Row } from "antd";
import React, { useEffect, useState } from "react";
import CustomCard, { TitleContainer } from "../../component/GlobalCard";
import PaginationComp from "../../utils/PaginationComp";
import PdfViewer from "../../utils/pdfViewer";
import api from "../../config/api";
import EmptyResult from "../../component/Empty";
import AppMetaHeader from "../../component/AppMetaHeader";
import PageContainer from "../../component/Layout/PageContainer";

const MindMap = () => {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState();
  const [perPage, setPerPage] = useState();
  const [loading, setLoading] = useState(false);
  const [mindMapData, setMindMapData] = useState();
  const [open, setOpen] = useState(false);
  const [resource, setResource] = useState();

  useEffect(() => {
    const fetchMindMapData = async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`/resources/2?page=${page}`);
        setTotal(data?.total);
        setPerPage(data?.per_page);
        setMindMapData(data?.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchMindMapData();
  }, [page]);

  const showDrawer = (item) => {
    setOpen(true);
    setResource(item);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppMetaHeader currentPageTitle="Mind Maps" />

      <PageContainer
        currentPageTitle="Mind Maps"
        title="Study Materials"
        url="/study-materials"
      >
        <div>
          <Row gutter={[15, 15]}>
            {mindMapData?.length > 0 ? (
              mindMapData?.map((item) => (
                <Col xxl={4} xl={6} lg={8} md={12} sm={12} xs={24}>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      showDrawer(item);
                    }}
                  >
                    <CustomCard
                      title={<TitleContainer>{item?.name}</TitleContainer>}
                      type="avatar"
                      className={"card-active"}
                    />
                  </div>
                </Col>
              ))
            ) : (
              <EmptyResult
                title={"There is no Mind Maps PDF!"}
                showDes={false}
                status={"404"}
              />
            )}
          </Row>

          {mindMapData?.length > 0 ? (
            <PaginationComp
              page={page}
              total={total}
              perPage={perPage}
              onChange={(page) => setPage(page)}
            />
          ) : (
            ""
          )}
        </div>

        <Drawer
          title={resource?.name}
          onClose={onClose}
          open={open}
          width="70%"
        >
          <PdfViewer pdfUrl={resource?.url} height={"700px"} />
        </Drawer>
      </PageContainer>
    </>
  );
};

export default MindMap;
