import React from "react";
export default function RightCollapse() {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 0C5.15916 0 0 5.15916 0 11.5C0 17.8408 5.15916 23 11.5 23C17.8408 23 23 17.8408 23 11.5C23 5.15916 17.8411 0 11.5 0ZM11.5 22.1863C5.6074 22.1863 0.813689 17.3923 0.813689 11.5C0.813689 5.60714 5.6074 0.813689 11.5 0.813689C17.3926 0.813689 22.1863 5.60714 22.1863 11.5C22.1863 17.3923 17.3926 22.1863 11.5 22.1863ZM12.2104 6.56957C12.0515 6.41087 11.7939 6.41087 11.6349 6.56957C11.476 6.72852 11.476 6.98638 11.6349 7.14508L15.5833 11.0932H6.12567C5.90078 11.0932 5.71882 11.2751 5.71882 11.5C5.71882 11.7244 5.90078 11.9068 6.12567 11.9068H15.5833L11.6349 15.8549C11.476 16.0136 11.476 16.2715 11.6349 16.4304C11.7939 16.5891 12.0515 16.5891 12.2104 16.4304L16.6786 11.962C16.8015 11.8386 16.8695 11.6743 16.8695 11.5C16.8695 11.3249 16.8015 11.1611 16.6786 11.0377L12.2104 6.56957Z"
        fill="white"
      />
    </svg>
  );
}
