import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { IPaginate,  IQuiz, IReport, IScore, IStatus } from "../interfaces/quiz";

interface IQuizStore {
  quiz?: IQuiz;
  status: Record<number, IStatus>;
  getReport: () => IReport;
  getScore:()=>IScore
  paginate: (pageId) => IPaginate;
  updateQuestion: (questionId: number, index: number) => void;
  updateVisited: (id) => void;
  updateBookmark: (id) => void;
  setQuiz: (quiz: IQuiz) => void;
}
type TSetStore = (
  partial:
    | IQuizStore
    | Partial<IQuizStore>
    | ((state: IQuizStore) => IQuizStore | Partial<IQuizStore>),
  replace?: boolean | undefined
) => void;


const quizStore = (set: TSetStore, get: () => IQuizStore) => {
  return {
    quiz: undefined,
    status: {},
    getReport: () => {
      const {status,quiz} = get();
      const total = quiz?.questions.length!;

      const { visited, answered, bookmarked, correct } = Object.values(status).reduce(
        (acc, { visited, attempt, bookmark,answer }) => ({
          visited: acc.visited + Number(visited),
          answered: acc.answered + (attempt !== -1 ? 1 : 0),
          bookmarked: acc.bookmarked + Number(bookmark),
          correct:acc.correct + Number(answer-1===attempt)
        }),
        { visited: 0, answered: 0, bookmarked: 0, correct:0 }
      );

      return {
        total,
        notVisited: total - visited,
        visited,
        answered,
        bookmarked,
        notAnswered: total - answered,
        correct,
        inCorrect: answered - correct
      };
    },
    getScore: () => {
      const {quiz,getReport} = get()
      const meta = quiz?.meta;
      const correctMark = +(meta?.correct_mark || 1);
      const incorrectMark = +(meta?.incorrect_mark || 0);
      const {total, correct,inCorrect, notVisited,visited} = getReport()
      const totalMarks = (total) * correctMark;
      const totalScore = +(
        correct * correctMark -
        inCorrect * incorrectMark
      ).toFixed(2);

      return {
        totalScore,
        correct,
        inCorrect,
        totalMarks: + totalMarks.toFixed(2),
        visited,
        notVisited
      };
    },
    setQuiz: async (quiz: IQuiz) => {
      const status: Record<number, IStatus> = {};
      quiz.questions.forEach((item,index) => {
        status[item.id] = {
          visited: false,
          bookmark: false,
          attempt: -1,
          position:index + 1,
          answer:item.answer
        };
      });
      set({
        quiz,
        status,
      });
    },
    updateQuestion: (questionId: number, index: number) => {
      const status = get().status;
      status[questionId].attempt = index;
      set({
        status,
      });
    },
    updateBookmark: (id: number) => {
      const status = get().status;
      status[id].bookmark = !status[id].bookmark;
      set({ status });
    },
    updateVisited: (id: number) => {
      const status = get().status;
      status[id].visited = true;
      set({ status });
    },
    paginate: (page = 1) => {
      const questions = get().quiz?.questions!;
      const qpp = Number(get().quiz?.meta.qpp || "10");
      var offset = (page - 1) * qpp,
        paginatedItems = questions?.slice(offset).slice(0, qpp),
        total_pages = Math.ceil(questions.length / qpp);
      return {
        page,
        qpp,
        pre_page: page - 1,
        next_page: total_pages > page ? page + 1 : null,
        total: questions.length,
        totalPages: total_pages,
        questions: paginatedItems,
      };
    },
  };
};

const useQuizStore = create(
  persist<IQuizStore>(quizStore, {
    name: "QUIZ_STORE",
    storage: createJSONStorage(() => sessionStorage),
  })
);

export default useQuizStore;

export const useTimerStore = create(
  persist(
    (set, get) => ({
      timeList: null,
      storeTimer: (time) => {
        set({ timeList: time });
      },
    }),
    {
      name: "timer-storage"
    }
  )
);
