import React, { useEffect, useState } from "react";
import {
  Row,
  Button,
  Card,
  Table,
  Tag,
  Drawer,
  DatePicker,
  Col,
  Skeleton,
} from "antd";
import PageContainer from "../component/Layout/PageContainer";
import Loader from "../component/loader";
import AppMetaHeader from "../component/AppMetaHeader";
import OrdersCard from "./OrdersCard";
import { useApi, useMount, useStorage } from "../hooks";
import { IOrder } from "../interfaces";
import { onlyDateParse } from "../helpers/help";
import styled from "styled-components";
import {
  HeaderContainer,
  QuizHeader,
  TableContainer,
  TableHeader,
} from "../component/GlobalCard";
import { SkeletonTable, SkeletonTableColumnsType } from "../component/Shimmer";
import { ColumnsType } from "antd/lib/table";
import AppSwitch from "../component/AppSwitch";
import { useMediaQuery } from "react-responsive";

const { RangePicker } = DatePicker;

interface IOrderRes {
  data?: IOrder[];
  total: any;
  per_page: any;
}

type Breakpoint = "xxl" | "xl" | "lg" | "md" | "sm" | "xs";

const Orders = () => {
  const { data, load, isLoading } = useApi<IOrderRes>(`/orders`);
  const [page, setPage] = useState(1);
  const [orderId, setOrderId] = useState();
  const [perPage, setPerPage] = useState();
  const [allOrders, setAllOrders] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState(null);
  const [total, setTotal] = useState();

  useEffect(() => {
    document.title = "Orders | Khan Global Studies";
  }, []);

  const courses = allOrders?.filter((item) => item.type === 1);
  const testSeries = allOrders?.filter((item) => item.type === 2);

  const handleDateChange = (dates, dateStrings) => {
    setSelectedDates(dateStrings);
  };

  const showDrawer = (id) => {
    setOpen(true);
    setOrderId(id);
  };

  const onClose = () => {
    setOpen(false);
  };

  useMount(() => {
    load({ page });
  }, [page]);

  useMount(() => {
    setAllOrders(data?.data);
    setTotal(data?.total);
    setPerPage(data?.per_page);
  }, [data]);

  const statusTag = [
    {
      label: "In-Progress",
      name: "inProgress",
    },
    {
      label: "Paid",
      name: "paid",
    },
    {
      label: "Failed",
      name: "failed",
    },
    {
      label: "Attempt",
      name: "attempt",
    },
  ];

  const orderDetails: ColumnsType<IQuizSubmission> = [
    {
      title: <TableTitile>Order Id</TableTitile>,
      dataIndex: "0",
      key: "0",
      render: (_, { id }) => <SubTitle>{id}</SubTitle>,
      width: 100,
    },
    {
      title: <TableTitile>Name</TableTitile>,
      dataIndex: "1",
      key: "1",
      render: (_, { item }) => (
        <SubTitle>{item === null ? "N/A" : item?.title}</SubTitle>
      ),
      width: 300,
    },

    {
      title: <TableTitile>Payment Date</TableTitile>,
      dataIndex: "2",
      key: "2",
      render: (_, { payment_at }) => (
        <SubTitle style={{ width: "max-content" }}>
          {payment_at === null ? "N/A" : onlyDateParse(payment_at, true)}
        </SubTitle>
      ),
      // responsive: ["lg", "md"],
      width: 150,
    },

    {
      title: <TableTitile>Payment Id</TableTitile>,
      dataIndex: "3",
      key: "3",
      render: (_, { gatway_id }) => (
        <SubTitle style={{ width: "max-content" }}>
          {gatway_id === "" ? "N/A " : gatway_id}
        </SubTitle>
      ),
      // responsive: ["lg", "md"],
      width: 200,
    },
    {
      title: <TableTitile>Amount</TableTitile>,
      dataIndex: "4",
      key: "4",
      render: (_, { amount }) => <SubTitle>{amount}/-</SubTitle>,
      responsive: ["lg", "md"],
      width: 100,
    },
    {
      title: <TableTitile>Status</TableTitile>,
      dataIndex: "5",
      key: "5",
      render: (_, { status }) => (
        <Tag
          className={statusTag[status].name}
          style={{ width: "max-content" }}
        >
          {statusTag[status].label}
        </Tag>
      ),
      width: 100,
    },
    {
      title: <TableTitile>View</TableTitile>,
      dataIndex: "6",
      key: "6",
      render: (_, { id }) => (
        <span>
          <Tag
            style={{
              backgroundColor: "#EAF0FF",
              color: "#4E66A4",
              border: "1px solid #CEE1FF",
              cursor: "pointer",
            }}
            onClick={() => showDrawer(id)}
          >
            View
          </Tag>
        </span>
      ),
      width: 100,
    },
  ];

  const filterDates = () => {
    if (selectedDates) {
      const filteredData = allOrders?.filter(
        (item) =>
          onlyDateParse(item.payment_at) >= selectedDates[0] &&
          onlyDateParse(item.payment_at) <= selectedDates[1]
      );
      setAllOrders(filteredData);
    }
  };

  const isSmallScreen = useMediaQuery({
    query: "(max-width: 426px)",
  });

  const isMediumScreen = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const isLargeScreen = useMediaQuery({
    query: "(max-width: 1025px)",
  });

  const isExtraLageScreen = useMediaQuery({
    query: "(max-width: 1441px)",
  });

  return (
    <>
      <AppMetaHeader currentPageTitle="My Orders" />
      <PageContainer currentPageTitle="Orders" transparent={true}>
        <>
          <Row
            style={{
              marginBottom: "1.5%",
              width: "100%",
              paddingTop: "0.3%",
              position: "fixed",
              zIndex: "1",
              backgroundColor: "#EDF4FF",
            }}
          >
            <Col xs={24} sm={16} md={16} lg={12} xl={9}>
              <Title>Select by Date</Title>
              <DateInput
                onChange={handleDateChange}
                style={{
                  background: "white",
                }}
                className="order-dates"
              />
              <NewButton
                type="primary"
                size="small"
                onClick={filterDates}
                style={{
                  borderRadius: "1em",
                  fontSize: "0.8em",
                }}
              >
                Apply
              </NewButton>
            </Col>
            <Col xs={24} sm={8} md={8} lg={12} xl={12}>
              <AppSwitch
                onValueChange={() => setIsChecked(!isChecked)}
                title="Select by Course / TestSeries"
                onLabel="Course"
                offLabel="Test series"
              />
            </Col>
          </Row>

          <Row className="hideScroll">
            {/* <TableContainer>
              <QuizHeader>Orders</QuizHeader>
            </TableContainer> */}
            <Card
              size="small"
              style={{
                width: "100%",
                overflow: "auto",
                marginTop: isSmallScreen
                  ? "9em"
                  : isMediumScreen
                  ? "5em"
                  : isLargeScreen
                  ? "5em"
                  : isExtraLageScreen
                  ? "5em"
                  : "5em",
              }}
            >
              <SkeletonTable loading={isLoading}>
                <Table
                  // loading={isLoading}
                  dataSource={isChecked ? testSeries : courses}
                  columns={orderDetails}
                  scroll={{ x: 500, y: 800 }}
                  className="hideScrollbar"
                  pagination={{
                    total: total,
                    current: page,
                    pageSize: perPage,
                    showSizeChanger: false,
                    onChange: (page) => setPage(page),
                  }}
                />
              </SkeletonTable>
            </Card>
          </Row>
        </>
      </PageContainer>
      <Drawer
        placement={"right"}
        closable={false}
        onClose={onClose}
        open={open}
        width={"30rem"}
      >
        <OrdersCard orderId={orderId!} onClose={onClose} />
      </Drawer>
    </>
  );
};

const DateInput = styled(RangePicker)`
  border-radius: 0.8em;
  border: "0.1em solid #D9D9D9";
  margin-right: 2%;
`;

const NewButton = styled(Button)`
  background-color: #123283;
  box-shadow: none;
  width: 5em;
`;
const Title = styled.p`
  color: #8f92a1;
  margin-bottom: 0em;
  font-size: 0.9em;
`;

const TableTitile = styled.p`
  font-weight: 600;
  margin-bottom: 0%;
  padding: 0.8em;
  font-size: 1.2em;
`;

const SubTitle = styled.p`
  font-weight: 600;
  margin-bottom: 0%;
  padding: 1em;
  font-size: 1em;
`;

export default Orders;
